/* eslint-disable camelcase */
import { getIn } from 'immutable';
import pick from 'lodash.pick';
import { getDefaultLanguage } from '../../utils/utility';
import {
  getWSManagers,
  getWSAdmins,
  getCurrentIssue,
} from '../issue/selectors';
import { YU } from '../../helpers/multilang.helper';
import { createSelector } from 'reselect';

export const ROLES = {
  ADMIN: 'admin',
  USER: 'user',
};

export const getUserPath = (state) => getIn(state, ['user']);
export const getUser = createSelector(getUserPath, (a) => {
  if (!a) {
    return a;
  }

  return a.toJS();
});
export const getUserProp = (prop) => (state) => getIn(state, ['user', prop]);

const userProps = ['id', 'first_name', 'last_name', 'profile_image'];
export const getBasicUserData = (state) =>
  pick(getIn(state, ['user']).toJS(), userProps);

const languagePath = (state) => getIn(state, ['user', 'language']);

export const getUserLanguage = createSelector(languagePath, (language) => {
  const mapYuLanguages = (lang) => (lang === YU ? 'hr' : lang);

  if (!language) {
    return mapYuLanguages(getDefaultLanguage());
  }

  return mapYuLanguages(language);
});

export const getUserData = (props = []) =>
  createSelector(getUserPath, (userMap) => {
    if (!userMap) {
      return {};
    }

    return pick(userMap.toJS(), props);
  });

export const isAdmin = createSelector(
  getUser,
  (user) => user.role === ROLES.ADMIN
);

export const isRegularUser = createSelector(
  getUser,
  (user) => user.role === ROLES.USER
);

const containsUser = (users, userId) => !!users.find(({ id }) => id === userId);

export const isWSManager = (state) => {
  const userId = getUserProp('id')(state);
  const users = getWSManagers(state);

  return containsUser(users, userId);
};

export const isWSAdmin = (state) => {
  const userId = getUserProp('id')(state);
  const users = getWSAdmins(state);

  return containsUser(users, userId);
};

export const isAssignee = (state) => {
  const issue = getCurrentIssue(state);

  if (!issue) {
    return false;
  }

  const userId = getUserProp('id')(state);

  return issue.assignee_id === userId;
};

const getGalleryUserData = getUserData([
  'id',
  'profile_image',
  'first_name',
  'last_name',
]);

export const getGalleryUser = createSelector(getGalleryUserData, (user) => ({
  id: user.id,
  profileImageUrl: user.profile_image,
  name: `${user.first_name} ${user.last_name}`,
}));

export const getFilterVisibilitySetting = createSelector(
  getUser,
  (user) => !!user?.settings?.only_my_issues
);
export const getUserGlobalPermissions = createSelector(
  getUser,
  (user) => user.permissions.global
);

export const getCalendarPemission = createSelector(getUser, (user) =>
  (user?.permissions?.global ?? []).includes('can_view_calendar')
);

export const getClientId = createSelector(getUser, (user) => user.client_id);

export const getUserSortDirection = createSelector(
  getUser,
  (user) => user?.settings?.sort_direction ?? 'desc'
);
