import { useCallback, useEffect, useRef } from 'react';

import { assetsApi } from '../service';
import {
  RTK_HOOK_CONFIG,
  INFINITE_SCROLL_INITIAL_STATE,
} from '../../../../config/constants';
import useInfiniteScrollData from '../../Equipment/hooks/useInfiniteScrollData'; //TO DO - lift

const useAssetsInfinite = ({ skipQuery, customParams } = {}) => {
  const getResetAssetsState = useCallback(
    () => ({
      ...INFINITE_SCROLL_INITIAL_STATE,
      query: {
        ...INFINITE_SCROLL_INITIAL_STATE.query,
        ...(customParams || {}),
      },
      id: 'assetsInfinite',
    }),
    [customParams]
  );

  const {
    scrollState,
    handleLoadMore,
    handleSearch,
    handleStateUpdate,
    mergeStateItems,
    deleteManuallyFromListItems,
    handleCustomQuery,
    handleCustomQueryWithReset,
  } = useInfiniteScrollData(getResetAssetsState(), getResetAssetsState);

  const forceItemsOverride = useRef(false);

  const { useGetAssetsQuery } = assetsApi;
  const { data: fetchedData, isFetching } = useGetAssetsQuery(
    { ...scrollState.query },
    {
      ...RTK_HOOK_CONFIG,
      skip: skipQuery || false,
    }
  );

  const resetQueryWithForceItemsOverride = useCallback(
    (customQueryParams) => {
      forceItemsOverride.current = true;
      handleCustomQueryWithReset(customQueryParams ? customQueryParams : {});
    },
    [handleCustomQueryWithReset]
  );

  useEffect(() => {
    if (fetchedData) {
      handleStateUpdate(fetchedData, forceItemsOverride.current);
    }
    forceItemsOverride.current = false;
  }, [fetchedData, handleStateUpdate]);

  return {
    scrollState,
    handleLoadMore,
    handleSearch,
    isFetching,
    handleStateUpdate,
    mergeStateItems,
    deleteManuallyFromListItems,
    handleCustomQuery,
    resetQueryWithForceItemsOverride,
  };
};

export default useAssetsInfinite;
