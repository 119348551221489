import { isEqual } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { getConfigPersistedFilters } from '../../../../../redux/config/selectors';
import useCalendar from '../../hooks/useCalendar';
import { getActiveView, getIsAdvancedFilterOpen } from '../../selectors';
import { updateAdvancedFiltersMenuOpen } from '../../slice';
import { getHiddenFilters } from './utils';
import { CALENDAR_VIEW_TYPES, PERSIST_KEY } from '../../constants';

import { getFilterView } from './utils';
import useYearly from '../Yearly/hooks/useYearly';
import { useMemo } from 'react';

const FiltersWrapper = () => {
  const dispatch = useDispatch();
  const isAdvancedFilterOpen = useSelector(getIsAdvancedFilterOpen);
  const handleToggle = () =>
    dispatch(updateAdvancedFiltersMenuOpen(1 - Number(isAdvancedFilterOpen)));

  const activeView = useSelector(getActiveView);

  const useCalendarProps = useCalendar({
    calendarView: activeView,
    persistAdvancedFilters: activeView !== CALENDAR_VIEW_TYPES.yearly,
    customRTKConfig: {
      skip: true,
    },
  });

  const { filterProps } = useYearly({
    preventReset: true,
    skip: true,
    persistAdvancedFilters: activeView === CALENDAR_VIEW_TYPES.yearly,
  });

  const persistedFilters = useSelector(
    (state) => getConfigPersistedFilters(state, PERSIST_KEY),
    isEqual
  );

  // schedule has different filter
  const Filters = useMemo(() => getFilterView(activeView), [activeView]);

  return (
    <Filters
      isOpen={
        activeView === CALENDAR_VIEW_TYPES.yearly
          ? isAdvancedFilterOpen === 1
          : isAdvancedFilterOpen
      }
      parentHookProps={
        activeView === CALENDAR_VIEW_TYPES.yearly
          ? filterProps
          : useCalendarProps
      }
      handleToggle={handleToggle}
      persistedFilters={persistedFilters}
      disableHideFilterItems
      hiddenFilters={getHiddenFilters(activeView)}
      persistKey={PERSIST_KEY}
    />
  );
};

export default FiltersWrapper;
