/**
 * A place to store all core selectors
 *
 * All basic caching selectors are in this file
 */

/* eslint-disable no-console */
/* eslint-disable no-prototype-builtins */
import { getIn } from 'immutable';
import { getCollectionKey } from '../redux.helpers'; // 5 minutes
import { createSelector } from 'reselect';
import identity from 'lodash.identity';
import { propertyComparator, CACHE_LIFETIME_MS } from './selectorUtils';
import { getFilter } from '../filter/selectors';

const byEqualIds = (id) => (x) => Number(x.id) === Number(id);

export const entitiesPath = (state) => state.entities;

export const entitiesPathSelector = createSelector(entitiesPath, identity);

export const getEntitiySelector = (entityType) =>
  createSelector(entitiesPathSelector, (entities) => {
    if (!entities) {
      return null;
    }

    const entity = entities.get(entityType);

    if (entity.hasOwnProperty('size') && entity.hasOwnProperty('toJS')) {
      return entity.toJS();
    }

    return entity;
  });

export const getEntityPath = (entityType) => (state) =>
  state.entities.get(entityType);

export const getPathForAll = (entityType) => ['entities', entityType, 'all'];

export const getAllEntities = createSelector(
  [(state) => state, (_, props) => props],
  (state, { entityType }) => {
    try {
      const entities = getIn(state, getPathForAll(entityType.reduxProp)).toJS();

      if (!entities) {
        throw new Error('No entities!');
      }

      return entities;
    } catch (error) {
      console.warn(`No entities found for type: ${entityType}`);

      return [];
    }
  }
);

export const findEntitiesWithIds = (entities, ids) => {
  if (ids === null || !Array.isArray(ids)) {
    return null;
  }

  if (!Array.isArray(entities)) {
    return null;
  }

  const filtered = [];

  for (const id of ids) {
    const entity = entities.find(byEqualIds(id));

    if (entity) {
      // Do not add an entity if it's already there
      if (!filtered.find(byEqualIds(entity.id))) {
        filtered.push(entity);
      }

      if (Array.isArray(entity.children)) {
        for (const child of entity.children) {
          // Do not add child if it's already included
          if (ids.includes(child.id) && !filtered.find(byEqualIds(child.id))) {
            filtered.push(child);
          }
        }
      }
    }
  }

  return filtered;
};

const findEntityWithId = (entities, id) => {
  if (id === null || typeof id === 'undefined') {
    console.warn('Id is not supplied!');

    return null;
  }

  if (!Array.isArray(entities)) {
    console.warn('Entities is not an array!');

    return null;
  }

  // For loop to exit earlier
  for (const entity of entities) {
    if (entity.id === id) {
      return entity;
    }

    if (Array.isArray(entity.children)) {
      for (const child of entity.children) {
        if (child.id === id) {
          return child;
        }
      }
    }
  }

  return null;
};

export const getEntityFromCacheById = createSelector(
  [getAllEntities, (_, props) => props.id],
  findEntityWithId
);

export const getAllSortedEntities = createSelector(
  [getAllEntities, (_, props) => props],
  (allEntities, { sortField, sortOrder, useServerSort }) => {
    if (sortField && !useServerSort) {
      return allEntities.sort(propertyComparator(sortField, sortOrder));
    }

    return allEntities;
  }
);

/**
 * Gets ids from ALL collection
 *
 * @param {Object} state Redux state
 * @param {*} entityType
 * @param {Array} ids
 * @param {string} sortField
 * @param {string} sortOrder
 *
 * @returns {Object} Object with data property which holds array of data
 */
export const getEntitiesByIdsFromAll = createSelector(
  [getAllSortedEntities, (_, props) => props],
  (entities, { ids, sortField, sortOrder, useServerSort }) => {
    if (sortField && !useServerSort) {
      return {
        data: findEntitiesWithIds(entities, ids).sort(
          propertyComparator(sortField, sortOrder)
        ),
      };
    }

    return {
      data: findEntitiesWithIds(entities, ids),
    };
  }
);

export const getKey = (entityType, parameters) => {
  return getCollectionKey(entityType.name, parameters);
};

/**
 * Gets data from a collection
 *
 * @param {Object} state Redux state
 * @param {Object} entityType entityType
 * @param {*} parameters
 * @param {*} sortField
 * @param {*} sortOrder
 *
 * @return {Object}
 *    Returns empty object or object with properties
 *    {data: [], filter: {}}
 */
export const getCollection = (
  state,
  entityType,
  parameters,
  sortField,
  sortOrder,
  useServerSort
) => {
  const key = getKey(entityType, parameters);
  const collection = getIn(state, [
    'entities',
    entityType.reduxProp,
    'collections',
    key,
  ]);

  if (collection) {
    const collectionUpdateDate = new Date(collection.lastUpdated);
    const now = new Date();

    if (Math.abs(now - collectionUpdateDate) > CACHE_LIFETIME_MS) {
      return {};
    }

    const result = getEntitiesByIdsFromAll(state, {
      entityType,
      ids: collection.ids,
      sortField,
      sortOrder,
      useServerSort,
    });
    result.filter = collection.filter;

    return result;
  }

  return {};
};

/**
 * Gets data from a collection
 *
 * @param {Object} state Redux state
 * @param {Object} entityType entityType
 * @param {String} filterName
 *
 * @return {Object}
 *    Returns empty object or object with properties
 *    {data: [], filter: {}}
 */
export const getCollectionByFilter = (state, entityType, filterName) => {
  const filterProps = getFilter(filterName)(state);
  const key = getKey(entityType, filterProps);
  const collection = getIn(state, [
    'entities',
    entityType.reduxProp,
    'collections',
    key,
  ]);

  if (collection) {
    const collectionUpdateDate = new Date(collection.lastUpdated);
    const now = new Date();

    if (Math.abs(now - collectionUpdateDate) > CACHE_LIFETIME_MS) {
      return {};
    }

    const result = getEntitiesByIdsFromAll(state, {
      entityType,
      ids: collection.ids,
      ...filterProps,
    });

    result.filter = collection.filter;

    return result;
  }

  return {};
};

export const isCollectionValid =
  ({ entityType, filterName }) =>
  (state) => {
    const filterProps = getFilter(filterName)(state);
    const key = getKey(entityType, filterProps);
    const collection = getIn(state, [
      'entities',
      entityType.reduxProp,
      'collections',
      key,
    ]);

    if (!collection) {
      return false;
    }

    const collectionUpdateDate = new Date(collection.lastUpdated);
    const now = new Date();

    return Math.abs(now - collectionUpdateDate) < CACHE_LIFETIME_MS;
  };

export const getCollectionCurried =
  ({ entityType, filterName }) =>
  (state) =>
    getCollectionByFilter(state, entityType, filterName);

export const getCollectionData = (props) =>
  createSelector(getCollectionCurried(props), (collection) => {
    if (collection && collection.data) {
      return collection.data;
    }

    return [];
  });

export const getCurrentFilter = (state, reduxProp) =>
  getIn(state, ['entities', reduxProp, 'collections', 'currentFilter']);

export const getCurrentKey = (state, reduxProp) =>
  getIn(state, ['entities', reduxProp, 'collections', 'currentKey']);

export const getEntityDataArray = (entitySelector) =>
  createSelector(entitySelector, (entityData) => {
    if (entityData && entityData.size) {
      return entityData.toJS();
    }

    if (entityData && entityData.length) {
      return entityData;
    }

    return entityData;
  });

export const getFilterParameterFromCache = (state, entity, parameter) => {
  if (!entity) {
    return '';
  }

  const filterParam = getCurrentFilter(state, entity.reduxProp);

  if (filterParam) {
    return filterParam[parameter];
  }
};
