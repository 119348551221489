import styled from 'styled-components/macro';
import { WowDisplayFlex } from '../../../../../components_new/WowBasicElements';

import { lightererGray, lighterGray } from '../../../../../config/colors';

export const ContentWrapper = styled(WowDisplayFlex)`
  flex-direction: column;
  align-items: flex-start;

  overflow-y: auto;
  flex-grow: 1;

  ${({ overflow }) => (overflow ? `overflow: ${overflow}` : null)};

  ::-webkit-scrollbar {
    height: 10px;
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${lighterGray};
    border-radius: 100px;
    height: 50px;

    border-left: 4px white solid;
    background-clip: padding-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${lightererGray};
  }

  > * {
    flex-shrink: 0;
  }

  .dropdown-menu {
    width: 99%;
    margin-left: 1px;
  }
`;
