import React from 'react';
import { Wrapper, Text, InfoWrapper, RemoveIcon } from './styles';

const Pill = ({ item, onRemove, canRemove }) => {
  const imageAlt = `profile-image-alt-${item.label}`;

  return (
    <Wrapper>
      <InfoWrapper>
        {item.profile_image ? (
          <img
            src={item.profile_image}
            alt={imageAlt}
            data-testid="pillImage"
          />
        ) : null}
        <Text data-test="pillText">{item.label}</Text>
      </InfoWrapper>
      {canRemove ? (
        <RemoveIcon
          className="icon-close"
          onClick={onRemove}
          data-testid="removePill"
        />
      ) : null}
    </Wrapper>
  );
};

export default Pill;
