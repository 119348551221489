import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ClickableWrapper, IconWrapper, PillsWrapper, Wrapper } from './styles';
import { WowIcon } from '../../../WowBasicElements';
import { ICON_CONFIG } from './constants';
import Pill from './Pill';
import { hardWhite } from '../../../../config/colors';

const Selected = (props) => {
  const {
    labelKey,
    setReferenceElement,
    handleMenuToggle,
    items,
    onChange,
    queryKey,
    clearAll,
  } = props;

  const handleRemove = (item) => {
    const _queryKey = item.type ?? queryKey;
    onChange(item, _queryKey);
  };

  const handleClearAll = () => clearAll(queryKey);

  return (
    <Wrapper backgroundColor={items?.length ? hardWhite : null}>
      <ClickableWrapper onClick={handleMenuToggle} ref={setReferenceElement}>
        <FormattedMessage id={labelKey} />
      </ClickableWrapper>
      <IconWrapper>
        {items.length ? (
          <WowIcon {...ICON_CONFIG.close} onClick={handleClearAll} />
        ) : (
          <WowIcon {...ICON_CONFIG.add} onClick={handleMenuToggle} />
        )}
      </IconWrapper>
      <PillsWrapper>
        {items.map((item) => (
          <Pill key={item.id} item={item} handleRemove={handleRemove} />
        ))}
      </PillsWrapper>
    </Wrapper>
  );
};

Selected.propTypes = {
  labelKey: PropTypes.string.isRequired,
  setReferenceElement: PropTypes.func.isRequired,
  handleMenuToggle: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  queryKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  clearAll: PropTypes.func.isRequired,
};

export default Selected;
