import styled from 'styled-components/macro';
import { darkerGray, borderGray, hardWhite } from '../../config/colors';
import { themeColour } from '../../helpers/theme';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 36px;
  position: relative;
  border: 1px solid ${borderGray};
  border-radius: 6px;
  flex-wrap: wrap;
  align-items: center;

  .dropdown-search-wrapper {
    margin-left: 0px;
    width: 100%;

    .dropdown-menu-wrapper {
      > div:first-child {
        top: 10px !important;
      }
    }
  }
`;
export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  border-radius: 6px;
  background-color: ${({ hasSelectedItems }) =>
    hasSelectedItems ? hardWhite : 'white'};
  ${({ type }) => type === 'modal' && 'flex-direction: row-reverse;'};
  i {
    color: ${themeColour};
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    font-size: 12px;
  }
`;
export const SelectedItems = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;

  & > div {
    margin-right: 5px;
  }
`;

export const SelectHeader = styled.div`
  display: flex;
  max-width: 100%;
  min-width: 30px;
  border: none;
  outline: none;
  flex: 2;
  padding: 5px 5px 5px 10px;
  border-radius: 6px;
  cursor: pointer;

  font-size: 14px;
  color: ${darkerGray};
  font-family: 'nunitosans', 'Roboto', sans-serif;
`;
