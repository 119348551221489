import { useState } from 'react';
import qs from 'query-string';
import { BASE_URL, HTTP_OK } from '../../../../config/constants';
import { getUserLanguage } from '../../../../redux/user/selectors';
import { getToken } from '../../../../redux/utils';
import { downloadFile } from '../../../../services/ApiService';
import { formatDateToStandardFormat } from '../../../../helpers/date.helper';
import { initiateDownload } from '../../../../utils/utility';

//TO DO > check if BE is consistent between features and move this hook for global use!
const useExport = () => {
  const [isProcessingExport, setIsProcessingExport] = useState();

  const exportItems = async ({ fileType, payload } = {}) => {
    setIsProcessingExport(true);
    const token = getToken();
    const language = getUserLanguage();

    const url = qs.stringifyUrl(
      { url: `${BASE_URL}/issue/download/${fileType}`, query: payload },
      {
        skipEmptyString: true,
        skipNull: true,
        arrayFormat: 'comma',
      }
    );

    const response = await downloadFile(url, token);

    if (response?.status === HTTP_OK) {
      const blob = await response.blob();
      const downloadUrl = `Export_${formatDateToStandardFormat(
        new Date(),
        language
      )}_wowflow.${fileType}`;
      initiateDownload(blob, downloadUrl);
      setIsProcessingExport(false);
    } else {
      setIsProcessingExport(false);
    }
  };

  return {
    isProcessingExport,
    exportItems,
  };
};

export default useExport;
