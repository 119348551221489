import { createSlice } from '@reduxjs/toolkit';

export const getInitState = () => ({
  isAdvancedFilterOpen: 0,
  isUnscheduledMenuOpen: false,
  showIncludeSubWs: false,
  filters: {},
  currentActiveIssueDetailsModal: null,
  currentActiveViewMoreModal: null,
  currentActiveWeeklyColumnIssueDetailsModal: null,
  unscheduledIssueCurrentDragId: null,
  activeView: 'yearly',
  mainDraggableFilters: {
    hiddenData: {},
    clickedClearAllButton: {},
    clickedSelectAllUnderSearchSession: {},
    clickedSelectAll: {},
    initialMoveHiddenDailyToWeeklyUsersDone: false,
    initialMoveHiddenDailyToWeeklyWorkspacesDone: false,
  },
  timelineIssueCurrentDragId: null,
  timelineIssueDraggableElementId: null,
  lastDailyDate: null,

  // yearly part of state
  modalInfo: {
    position: null,
    data: null,
  },
  actionItemInfo: {
    position: null,
    data: null,
  },
});

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState: getInitState(),
  reducers: {
    updateAdvancedFiltersMenuOpen: (state, action) => {
      state.isAdvancedFilterOpen = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    updateUnscheduledMenuOpen: (state, action) => {
      state.isUnscheduledMenuOpen = action.payload;
    },
    setCurrentActiveIssueDetailsModal: (state, action) => {
      state.currentActiveIssueDetailsModal = action.payload;
    },
    setCurrentActiveWeeklyColumnIssueDetailsModal: (state, action) => {
      state.currentActiveWeeklyColumnIssueDetailsModal = action.payload;
    },
    setCurrentActiveViewMoreModal: (state, action) => {
      state.currentActiveViewMoreModal = action.payload;
    },
    setUnscheduledIssueCurrentDragId: (state, action) => {
      state.unscheduledIssueCurrentDragId = action.payload;
    },
    setActiveView: (state, action) => {
      state.activeView = action.payload;
    },
    setMainDraggableFiltersHiddenData: (state, action) => {
      state.mainDraggableFilters.hiddenData[action.payload.type] =
        action.payload.data;
    },
    setMainDraggableFiltersClickedClearAllButton: (state, action) => {
      state.mainDraggableFilters.clickedClearAllButton[action.payload.type] =
        action.payload.data;
    },
    setMainDraggableFiltersClickedSelectAllUnderSearchSession: (
      state,
      action
    ) => {
      state.mainDraggableFilters.clickedSelectAllUnderSearchSession[
        action.payload.type
      ] = action.payload.data;
    },
    setMainDraggableFiltersClickedSelectAll: (state, action) => {
      state.mainDraggableFilters.clickedSelectAll[action.payload.type] =
        action.payload.data;
    },
    setTimelineIssueCurrentDragId: (state, action) => {
      state.timelineIssueCurrentDragId = action.payload;
    },
    setTimelineIssueDraggableElementId: (state, action) => {
      state.timelineIssueDraggableElementId = action.payload;
    },
    setInitialMoveHiddenDailyToWeeklyUsersDone: (state, action) => {
      state.mainDraggableFilters.initialMoveHiddenDailyToWeeklyUsersDone =
        action.payload;
    },
    setInitialMoveHiddenDailyToWeeklyWorkspacesDone: (state, action) => {
      state.mainDraggableFilters.initialMoveHiddenDailyToWeeklyWorkspacesDone =
        action.payload;
    },
    setLastDailyDate: (state, action) => {
      state.lastDailyDate = action.payload;
    },
    setModalInfo: (state, action) => {
      state.modalInfo = action.payload;
    },
    setActionItemInfo: (state, action) => {
      state.actionItemInfo = action.payload;
    },
    setDefaultValues: (state) => {
      return (state = { ...getInitState() });
    },
  },
});

export const {
  updateAdvancedFiltersMenuOpen,
  setFilters,
  updateUnscheduledMenuOpen,
  setCurrentActiveWeeklyColumnIssueDetailsModal,
  setCurrentActiveIssueDetailsModal,
  setCurrentActiveViewMoreModal,
  setUnscheduledIssueCurrentDragId,
  setActiveView,
  setMainDraggableFiltersHiddenData,
  setMainDraggableFiltersClickedClearAllButton,
  setMainDraggableFiltersClickedSelectAllUnderSearchSession,
  setMainDraggableFiltersClickedSelectAll,
  setTimelineIssueCurrentDragId,
  setTimelineIssueDraggableElementId,
  setDefaultValues,
  setInitialMoveHiddenDailyToWeeklyUsersDone,
  setInitialMoveHiddenDailyToWeeklyWorkspacesDone,
  setLastDailyDate,
  setModalInfo,
  setActionItemInfo,
} = calendarSlice.actions;

export default calendarSlice.reducer;

export const handleLoadMoreHideData =
  (data, additionalParams = {}) =>
  (dispatch, getState) => {
    const activeView = getState().calendar.activeView;
    const hiddenData =
      getState().calendar?.mainDraggableFilters?.hiddenData?.[activeView] ?? {};
    const calendar_search = getState().calendar?.filters?.calendar_search;
    const mutatedState = JSON.parse(JSON.stringify({ ...hiddenData }));
    const anyShowed = Object.keys(mutatedState).filter(
      (item) => mutatedState[item].hide === false
    );
    if (
      Object.keys(mutatedState).length &&
      (!anyShowed.length ||
        (additionalParams.searchParamSessionStarted && !calendar_search))
    ) {
      data.forEach((item) => {
        if (
          additionalParams.searchParamSessionStarted &&
          !calendar_search &&
          mutatedState[item.id]
        )
          return;
        mutatedState[item.id] = {
          id: item.id,
          hide: true,
          data: item,
        };
      });
      dispatch(
        setMainDraggableFiltersHiddenData({
          type: activeView,
          data: mutatedState,
        })
      );
    }
  };

export const setDefaultHiddenData =
  (data = [], hideValue = false) =>
  (dispatch, getState) => {
    const activeView = getState().calendar.activeView;
    const hiddenData =
      getState().calendar?.mainDraggableFilters?.hiddenData?.[activeView] ?? {};
    const calendar_search = getState().calendar?.filters?.calendar_search;
    const clickedClearAllButton =
      getState().calendar?.mainDraggableFilters?.clickedClearAllButton?.[
        activeView
      ] ?? false;
    const clickedSelectAllUnderSearchSession =
      getState().calendar?.mainDraggableFilters
        ?.clickedSelectAllUnderSearchSession?.[activeView] ?? false;
    const clickedSelectAll =
      getState().calendar?.mainDraggableFilters?.clickedSelectAll?.[
        activeView
      ] ?? false;
    const mutatedState = JSON.parse(JSON.stringify({ ...hiddenData }));
    data.forEach((item) => {
      if (mutatedState[item.id] && clickedSelectAll)
        mutatedState[item.id].hide = false;
      if (mutatedState[item.id] || calendar_search) return;
      mutatedState[item.id] = {
        id: item.id,
        hide:
          clickedSelectAllUnderSearchSession || clickedClearAllButton
            ? true
            : hideValue,
        data: item,
      };
    });
    dispatch(
      setMainDraggableFiltersHiddenData({
        type: activeView,
        data: mutatedState,
      })
    );
  };

export const handleMoveHiddenDailyToWeekly = () => (dispatch, getState) => {
  const activeView = getState().calendar.activeView;
  const dailyUsersHiddenData =
    getState().calendar?.mainDraggableFilters?.hiddenData['dailyUsers'] ?? {};
  const dailyWorkspacesHiddenData =
    getState().calendar?.mainDraggableFilters?.hiddenData['dailyWorkspaces'] ??
    {};
  const initialMoveHiddenDailyToWeeklyUsersDone =
    getState().calendar?.mainDraggableFilters
      ?.initialMoveHiddenDailyToWeeklyUsersDone;
  const initialMoveHiddenDailyToWeeklyWorkspacesDone =
    getState().calendar?.mainDraggableFilters
      ?.initialMoveHiddenDailyToWeeklyWorkspacesDone;
  if (
    activeView === 'weeklyUsers' &&
    !initialMoveHiddenDailyToWeeklyUsersDone
  ) {
    dispatch(setInitialMoveHiddenDailyToWeeklyUsersDone(true));
    dispatch(
      setMainDraggableFiltersHiddenData({
        type: activeView,
        data: dailyUsersHiddenData,
      })
    );
  } else if (
    activeView === 'weeklyWorkspaces' &&
    !initialMoveHiddenDailyToWeeklyWorkspacesDone
  ) {
    dispatch(setInitialMoveHiddenDailyToWeeklyWorkspacesDone(true));
    dispatch(
      setMainDraggableFiltersHiddenData({
        type: activeView,
        data: dailyWorkspacesHiddenData,
      })
    );
  }
};

export const populateWeeklyEventCount =
  (countData = []) =>
  (dispatch, getState) => {
    const activeView = getState().calendar.activeView;
    const hiddenData = JSON.parse(
      JSON.stringify(
        getState().calendar?.mainDraggableFilters?.hiddenData?.[activeView] ??
          {}
      )
    );

    countData.forEach((data) => {
      if (data.id === hiddenData?.[data.id]?.id) {
        if (!hiddenData[data.id].data) {
          hiddenData[data.id].data = { ...data };
        } else {
          hiddenData[data.id].data = {
            ...hiddenData[data.id].data,
            ...data,
          };
        }
      }
    });

    dispatch(
      setMainDraggableFiltersHiddenData({
        type: activeView,
        data: hiddenData,
      })
    );
  };

export const resetWeeklyEventCount = () => (dispatch, getState) => {
  const activeView = getState().calendar.activeView;
  const hiddenData = JSON.parse(
    JSON.stringify(
      getState().calendar?.mainDraggableFilters?.hiddenData?.[activeView] ?? {}
    )
  );

  Object.keys(hiddenData).forEach((key) => {
    if (hiddenData?.[key]?.data) hiddenData[key].data.total_calendar_events = 0;
  });

  dispatch(
    setMainDraggableFiltersHiddenData({
      type: activeView,
      data: hiddenData,
    })
  );
};

export const handleJobPositionHiddenData =
  (filterByJobPosition = []) =>
  (dispatch, getState) => {
    const activeView = getState().calendar.activeView;
    if (activeView !== 'dailyUsers' && activeView !== 'weeklyUsers') return;
    const hiddenData = JSON.parse(
      JSON.stringify(
        getState().calendar?.mainDraggableFilters?.hiddenData?.[activeView] ??
          {}
      )
    );
    if (filterByJobPosition?.length === 0) return;

    Object.keys(hiddenData).forEach((key) => {
      hiddenData[key].hide = true;
      if (
        filterByJobPosition.find(
          (job) => job === hiddenData[key]?.data?.job_position
        )
      ) {
        hiddenData[key].hide = false;
      }
    });

    dispatch(
      setMainDraggableFiltersHiddenData({
        type: activeView,
        data: hiddenData,
      })
    );
  };
