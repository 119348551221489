import styled from 'styled-components/macro';
import { outline, white } from '../../../config/colors';

export const Aside = styled.aside`
  background-color: ${white};
  width: 240px;
  min-width: 240px;
  overflow-y: ${({ disableOverflow }) =>
    disableOverflow ? 'visible' : 'auto'};
  height: calc(100vh - 55px);
  position: sticky;
  top: 0;
  border-left: 1px solid ${outline};
  padding: 20px;
  align-self: flex-start;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  padding-bottom: 61px;
  ${({ zIndex }) => (zIndex ? `z-index: ${zIndex}` : null)};
`;
