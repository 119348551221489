import Users from './components/Users';
import Collaborators from './components/Collaborators';
import { purple500 } from '../../config/colors';

export const VIEW_TYPES = {
  users: 'users',
  collaborators: 'collaborators',
};

export const MENU_ITEMS = [
  {
    id: 1,
    type: VIEW_TYPES.users,
    labelKey: VIEW_TYPES.users,
  },
  {
    id: 2,
    type: VIEW_TYPES.collaborators,
    labelKey: VIEW_TYPES.collaborators,
  },
];

export const VIEWS = {
  [VIEW_TYPES.users]: (props) => <Users {...props} />,
  [VIEW_TYPES.collaborators]: (props) => <Collaborators {...props} />,
};

export const ICON_BACK_CONFIG = {
  className: 'icon icon-arrowleft',
  color: purple500,
  size: 23,
  spaceRight: '5',
  clickable: true,
  display: 'flex',
};

export const ICON_ARROW_RIGHT_CONFIG = {
  size: 14,
  className: 'icon icon-arrowright-02',
  spaceRight: '0',
  color: purple500,
  display: 'flex',
};

export const POPER_CONFIG = {
  placement: 'bottom-end',
  modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
};
