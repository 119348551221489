import { useEffect } from 'react';

import { useGetCategoriesQuery } from '../../../categories/service';

import useInfiniteScrollData from '../../../Equipment/hooks/useInfiniteScrollData';
import { INFINITE_SCROLL_INITIAL_STATE } from '../../../Equipment/constants'; // TODO: Move to global config?
import { RTK_HOOK_CONFIG } from '../../../../../config/constants';

const useCategories = ({ skipQuery, customParams } = {}) => {
  const {
    scrollState,
    parseFetchedData,
    handleLoadMore,
    handleSearch,
    handleStateUpdate,
    mergeStateItems,
  } = useInfiniteScrollData({
    ...INFINITE_SCROLL_INITIAL_STATE,
    query: {
      ...INFINITE_SCROLL_INITIAL_STATE.query,
      ...(customParams || {}),
    },
    id: 'categories',
  });

  const { data: fetchedData, isLoading } = useGetCategoriesQuery(
    { ...scrollState.query },
    {
      ...RTK_HOOK_CONFIG,
      skip: skipQuery || false,
    }
  );

  useEffect(() => {
    handleStateUpdate(fetchedData);
  }, [fetchedData, handleStateUpdate]);

  return {
    scrollState,
    parseFetchedData,
    handleLoadMore,
    handleSearch,
    isLoading,
    mergeStateItems,
  };
};

export default useCategories;
