import { useEffect } from 'react';

import { useGetEquipmentListQuery } from '../service';
import {
  RTK_HOOK_CONFIG,
  INFINITE_SCROLL_INITIAL_STATE,
} from '../../../../config/constants';
import useInfiniteScrollData from './useInfiniteScrollData';

const useEquipmentInfinite = ({ skipQuery } = {}) => {
  const {
    scrollState,
    handleLoadMore,
    handleSearch,
    handleStateUpdate,
    mergeStateItems,
    deleteManuallyFromListItems,
    handleCustomQuery,
  } = useInfiniteScrollData({
    ...INFINITE_SCROLL_INITIAL_STATE,
    id: 'equipment',
  });

  const { data: fetchedData, isFetching } = useGetEquipmentListQuery(
    { ...scrollState.query },
    {
      ...RTK_HOOK_CONFIG,
      skip: skipQuery || false,
    }
  );

  useEffect(() => {
    handleStateUpdate(fetchedData);
  }, [fetchedData, handleStateUpdate]);

  return {
    scrollState,
    handleLoadMore,
    handleSearch,
    isFetching,
    handleStateUpdate,
    mergeStateItems,
    deleteManuallyFromListItems,
    handleCustomQuery,
  };
};

export default useEquipmentInfinite;
