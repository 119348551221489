import { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DragDropContext } from 'react-beautiful-dnd';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

import { darkGray } from '../../../../../config/colors';
import { WEEKDAYS } from '../../../../../config/config';
import useCalendar from '../../hooks/useCalendar';
import useWeeklyDataCounter from '../../hooks/useWeeklyDataCounter';
import { formatDateForQuery } from '../../utils';
import {
  getActiveView,
  getIsAdvancedFilterOpen,
  getIsUnscheduledMenuOpen,
  getMainDraggableFiltersHiddenData,
  getMainDraggableFiltersHiddenWeeklyUsersData,
  getMainDraggableFiltersHiddenWeeklyWorkspacesData,
} from '../../selectors';

import Header from '../Header';
import {
  WowColorizeText,
  WowDisplayFlex,
} from '../../../../../components_new/WowBasicElements';
import { LoadingSpinner } from '../../../../../components/loading-spinner/loading-spinner.component';
import {
  Content,
  Page,
  Wrapper,
} from '../../../../../components_new/WowListPage';
import Space from '../../../../../components_new/Space';
import MainDraggableFilter from '../MainDraggableFilter';
import WeeklyTimeline from '../WeeklyTimeline';
import useNoDate from '../UnscheduledSidebar/hooks/useNoDate';
import useOverdue from '../UnscheduledSidebar/hooks/useOverdue';
import Filters from '../Filters';
import UnscheduledSidebar from '../UnscheduledSidebar';
import WeeklyLimitSelectBox from '../WeeklyLimitSelectBox';

import useWindowSizeDebounced from '../../../../../hooks/useWindowSizeDebounced';
import {
  setCurrentActiveIssueDetailsModal,
  setUnscheduledIssueCurrentDragId,
  setTimelineIssueCurrentDragId,
  setTimelineIssueDraggableElementId,
  handleMoveHiddenDailyToWeekly,
  setMainDraggableFiltersHiddenData,
  populateWeeklyEventCount,
  setCurrentActiveWeeklyColumnIssueDetailsModal,
  resetWeeklyEventCount,
  handleLoadMoreHideData,
  setLastDailyDate,
} from '../../slice';

import { CalendarHeaderDay, CalendarWrapper } from '../../styles';
import { CALENDAR_VIEW_TYPES } from '../../constants';
import {
  CALENDAR_PREVIEW_DATA_LENGTH_THRESHOLD,
  TYPES,
} from '../WeeklyTimeline/constants';
import { NoDataMessage } from './styles';

const Weekly = (props) => {
  const { setActiveView = () => {} } = props;

  const activeView = useSelector(getActiveView);
  const hiddenData = useSelector(getMainDraggableFiltersHiddenData);
  const checkingWithSearchParamStarted = useRef(false);
  const searchParamSessionStarted = useRef(false);
  const clearAllWithoutSearchClicked = useRef(false);
  const weeklyUsersHiddenData = useSelector(
    getMainDraggableFiltersHiddenWeeklyUsersData
  );
  const weeklyWorkspacesHiddenData = useSelector(
    getMainDraggableFiltersHiddenWeeklyWorkspacesData
  );

  const weeklyTimelineType =
    activeView === CALENDAR_VIEW_TYPES.weeklyUsers
      ? TYPES.USERS.key
      : activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces
      ? TYPES.WORKSPACES.key
      : null;

  const intl = useIntl();
  const draggableTimelineIssueRevertData = useRef(null); //Used for DND inside timeline itself. Existing issues, when user cancel their date update, we will revert data with this ones saved here.
  const isUnscheduledMenuOpen = useSelector(getIsUnscheduledMenuOpen);
  const isAdvancedFilterOpen = useSelector(getIsAdvancedFilterOpen);
  const dispatch = useDispatch();

  const weeklyLimitSelectBoxRows = useMemo(() => {
    const data = [];
    const hiddenData =
      activeView === CALENDAR_VIEW_TYPES.weeklyUsers
        ? weeklyUsersHiddenData
        : activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces
        ? weeklyWorkspacesHiddenData
        : {};
    Object.keys(hiddenData).forEach((key) => {
      if (!hiddenData[key].hide) data.push(hiddenData[key]);
    });
    return data;
  }, [weeklyUsersHiddenData, weeklyWorkspacesHiddenData, activeView]);

  const {
    updateStartDateEndDate,
    calendarWeeklyData,
    isFetchingCalendarWeekly,
    loadMore,
    calendar_search,
    page,
    handleUpdateDataItem,
    handleDeleteDataItem,
    handleNewItemSetup,
    activeAdvancedFiltersCount,
    startDate: currentTime,
    isUninitializedCalendarWeekly,
    calendarWeeklyUsersLastPage,
    calendarWeeklyUsersCurrentPage,
    calendarWeeklyWorkspacesLastPage,
    calendarWeeklyUsersData,
    calendarWeeklyWorkspacesData,
    updateCalendarSearchValue,
    queryParams,
    setSkipGetCalendarWeeklyData,
    ...restUseCalendarProps
  } = useCalendar({
    calendarView: activeView,
    weeklyBodyAdditionalParams: {
      ...(activeView === CALENDAR_VIEW_TYPES.weeklyUsers
        ? {
            assignees: weeklyLimitSelectBoxRows?.map((item) => item.id),
          }
        : {}),
      ...(activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces
        ? {
            workspace_ids: weeklyLimitSelectBoxRows?.map((item) => item.id),
          }
        : {}),
    },
  });

  const last_page =
    activeView === CALENDAR_VIEW_TYPES.weeklyUsers
      ? calendarWeeklyUsersLastPage
      : calendarWeeklyWorkspacesLastPage;
  const sidebarData =
    activeView === CALENDAR_VIEW_TYPES.weeklyUsers
      ? calendarWeeklyUsersData
      : calendarWeeklyWorkspacesData;

  const { width } = useWindowSizeDebounced();
  const isQHDResolution = width >= 2560;
  const shouldShowOnlyOneSidebar = width < 1600 && isAdvancedFilterOpen === 1;
  const shouldShowUnscheduledSidebar =
    isUnscheduledMenuOpen &&
    ((!shouldShowOnlyOneSidebar && !isQHDResolution) ||
      (isQHDResolution && isAdvancedFilterOpen === 0));
  const [showWeekend, setShowWeekend] = useState(false);
  const [showWeeklyLimitSelectBox, setShowWeeklyLimitSelectBox] =
    useState(false);

  const [unscheduledSidebarSkipQuery, setUnscheduledSidebarSkipQuery] =
    useState(true);
  useEffect(() => {
    if (isUnscheduledMenuOpen && unscheduledSidebarSkipQuery)
      setUnscheduledSidebarSkipQuery(false);
  }, [isUnscheduledMenuOpen, unscheduledSidebarSkipQuery]);
  const noDateHookData = useNoDate({
    skipQuery: unscheduledSidebarSkipQuery || shouldShowOnlyOneSidebar,
    calendarQueryParams: queryParams,
  });
  const overdueHookData = useOverdue({
    skipQuery: unscheduledSidebarSkipQuery || shouldShowOnlyOneSidebar,
    calendarQueryParams: queryParams,
  });
  const removeItemsFromUnscheduledSidebar = (ids = []) => {
    overdueHookData.deleteManuallyFromListItems(ids);
    noDateHookData.deleteManuallyFromListItems(ids);
  };

  const handleSetCurrentTime = (start_date = new Date()) => {
    dispatch(setLastDailyDate(new Date()));
    const end_date = new Date(start_date);
    end_date.setDate(end_date.getDate() + 7);
    updateStartDateEndDate(
      formatDateForQuery(start_date),
      formatDateForQuery(end_date)
    );
  };

  const handleChangeCalendarSearch = (value) => {
    updateCalendarSearchValue(value);
  };

  useEffect(() => {
    dispatch(handleMoveHiddenDailyToWeekly());
  }, [dispatch, activeView]);

  const [
    skipCalendarWeeklyUsersEventCountData,
    setSkipCalendarWeeklyUsersEventCountData,
  ] = useState(true);
  const [
    skipCalendarWeeklyWorkspacesEventCountData,
    setSkipCalendarWeeklyWorkspacesEventCountData,
  ] = useState(true);
  const {
    calendarWeeklyUsersEventCountData,
    isFetchingCalendarWeeklyUsersEventCount,
    calendarWeeklyWorkspacesEventCountData,
    isFetchingCalendarWeeklyWorkspacesEventCount,
  } = useWeeklyDataCounter({
    searchParams: {
      ...queryParams,
      ...(activeView === CALENDAR_VIEW_TYPES.weeklyUsers
        ? {
            assignees: weeklyLimitSelectBoxRows?.map((item) => item.id),
          }
        : {}),
      workspace_ids:
        activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces
          ? weeklyLimitSelectBoxRows?.map((item) => item.id)
          : [],
      start_date: queryParams?.start_date ?? '',
      end_date: queryParams?.end_date ?? '',
    },
    skipCalendarWeeklyUsersEventCountData,
    skipCalendarWeeklyWorkspacesEventCountData,
  });

  useEffect(() => {
    if (weeklyLimitSelectBoxRows.length) {
      if (activeView === CALENDAR_VIEW_TYPES.weeklyUsers) {
        setSkipCalendarWeeklyUsersEventCountData(false);
        setSkipCalendarWeeklyWorkspacesEventCountData(true);
      } else if (activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces) {
        setSkipCalendarWeeklyWorkspacesEventCountData(false);
        setSkipCalendarWeeklyUsersEventCountData(true);
      }
    }
  }, [weeklyLimitSelectBoxRows, activeView]);

  useEffect(() => {
    dispatch(resetWeeklyEventCount());
  }, [queryParams, dispatch]);

  useEffect(() => {
    const data =
      calendarWeeklyWorkspacesEventCountData?.data ||
      calendarWeeklyUsersEventCountData?.data ||
      [];
    if (data?.length) {
      dispatch(populateWeeklyEventCount(data));
    }
  }, [
    calendarWeeklyUsersEventCountData?.data,
    calendarWeeklyWorkspacesEventCountData?.data,
    dispatch,
  ]);

  const handleHiddenData = (data, forceHideDefault) => {
    const mappedData = data.map((item) => ({
      id: item.id,
      hide: !item.checked,
    }));
    const mutatedState = JSON.parse(JSON.stringify({ ...hiddenData }));
    mappedData.forEach((item) => {
      if (mutatedState[item.id]) mutatedState[item.id].hide = item.hide;
      else
        mutatedState[item.id] = {
          hide: item.hide,
          id: item.id,
          data: item.metaData,
        };
    });

    if (forceHideDefault) {
      for (let key in mutatedState) mutatedState[key].hide = true;
    }

    dispatch(
      setMainDraggableFiltersHiddenData({
        type: activeView,
        data: mutatedState,
      })
    );
  };

  const handleWeeklyLimitSelectBoxAction = (data = []) => {
    setShowWeeklyLimitSelectBox(false);
    const newHiddenData = JSON.parse(JSON.stringify(hiddenData ?? {}));
    data.forEach((item) => {
      if (newHiddenData[item.id]) newHiddenData[item.id] = item;
    });
    dispatch(
      setMainDraggableFiltersHiddenData({
        type: activeView,
        data: newHiddenData,
      })
    );
  };
  const handleWeeklyLimitSelectBoxClose = () => {
    setShowWeeklyLimitSelectBox(false);
    const newHiddenData = JSON.parse(JSON.stringify(hiddenData ?? {}));
    Object.keys(newHiddenData).forEach(
      (key) => (newHiddenData[key].hide = true)
    );
    dispatch(
      setMainDraggableFiltersHiddenData({
        type: activeView,
        data: newHiddenData,
      })
    );
  };

  const isLoading = isFetchingCalendarWeekly;
  const data = calendarWeeklyData;
  const isUninitializedData = isUninitializedCalendarWeekly;

  const finalDragElementTopPosition = useRef(null);
  const lastCursorYPosition = useRef(null);
  const lastDragElementYPosition = useRef(null);
  const clearDnd = () => {
    finalDragElementTopPosition.current = null;
    lastCursorYPosition.current = null;
    lastDragElementYPosition.current = null;
    const droppableItems = document.querySelectorAll('[data-rbd-droppable-id]');
    for (let item of droppableItems) item.onmousemove = null;
  };

  const dndOnDragEndHandler = useCallback(
    ({ destination, draggableId, source }) => {
      const resetDndReduxData = () => {
        dispatch(setUnscheduledIssueCurrentDragId(null));
        dispatch(setTimelineIssueCurrentDragId(null));
        dispatch(setTimelineIssueDraggableElementId(null));
      };
      const destinationDroppableId = destination?.droppableId;
      const sourceDroppableId = source?.droppableId;
      if (!destinationDroppableId) return resetDndReduxData();

      let topPosition = finalDragElementTopPosition.current ?? 0;
      const lastCursorPosition = lastCursorYPosition.current;
      const lastDragElementPosition = lastDragElementYPosition.current;
      const id = parseInt(draggableId?.split('-')?.[1] ?? null);
      const dragFromType = draggableId?.split('-')?.[0] ?? null;
      const destionationId = parseInt(
        destinationDroppableId?.split('-')?.[1] ?? null
      );
      const sourceId = parseInt(sourceDroppableId?.split('-')?.[1] ?? null);

      clearDnd();

      if (lastDragElementPosition < 0 || lastDragElementPosition === null)
        return resetDndReduxData();

      if (lastDragElementPosition && lastCursorPosition) {
        topPosition =
          topPosition + (lastDragElementPosition - lastCursorPosition);
      }

      const handleParamsData = {
        id,
        presetData: {},
        presetPosition: topPosition,
        timelineIndex: destionationId,
        oldTimelineIndex: sourceId,
        activeView,
      };

      if (dragFromType === 'timeline') {
        dispatch(setCurrentActiveIssueDetailsModal(id));
        dispatch(
          setCurrentActiveWeeklyColumnIssueDetailsModal(
            parseInt(destionationId)
          )
        );
        return handleNewItemSetup(handleParamsData, {
          dndTimelineUpdate: true,
          changingTimelineDays: destionationId !== sourceId,
        });
      }

      handleNewItemSetup(handleParamsData);
    },
    [activeView, dispatch, handleNewItemSetup]
  );

  const dndOnDragUpdateHandler = useCallback(
    (context) => {
      const droppableId = context?.destination?.droppableId;
      const draggableId = context?.draggableId;

      if (droppableId) {
        if (draggableId)
          dispatch(setUnscheduledIssueCurrentDragId(draggableId));
        const destinationParentElem = document.querySelector(
          `[data-rbd-droppable-id="${droppableId}"]`
        );
        const draggableItem = document.querySelector(
          `[data-rbd-draggable-id="${draggableId}"]`
        );
        if (destinationParentElem) {
          const position = destinationParentElem.getBoundingClientRect();
          destinationParentElem.onmousemove = (e) => {
            lastDragElementYPosition.current =
              draggableItem.getBoundingClientRect().y;

            const { clientY } = e;
            const fromTop = clientY - position.y ?? 0;
            finalDragElementTopPosition.current = fromTop <= 0 ? 0 : fromTop;
            lastCursorYPosition.current = clientY;
          };
        }
      }
    },
    [dispatch]
  );

  const dndOnDragStartHandler = useCallback(
    ({ draggableId, source }) => {
      const droppableId = source?.droppableId;
      const dragFromType = draggableId?.split('-')?.[0] ?? null;
      dispatch(setCurrentActiveIssueDetailsModal(null));
      dispatch(setCurrentActiveWeeklyColumnIssueDetailsModal(null));
      clearDnd();
      if (dragFromType === 'timeline') {
        if (droppableId) {
          dispatch(setTimelineIssueCurrentDragId(draggableId));
          const destinationParentElem = document.querySelector(
            `[data-rbd-droppable-id="${droppableId}"]`
          );
          const draggableItem = document.querySelector(
            `[data-rbd-draggable-id="${draggableId}"]`
          );

          const intialStartDate = draggableItem.getAttribute('data-start_date');
          const intialDueDate = draggableItem.getAttribute('data-due_date');

          draggableTimelineIssueRevertData.current = {
            start_date: intialStartDate ? parseInt(intialStartDate) : null,
            due_date: intialDueDate ? parseInt(intialDueDate) : null,
          };

          if (destinationParentElem && draggableItem) {
            const position = destinationParentElem.getBoundingClientRect();
            destinationParentElem.onmousemove = (e) => {
              lastDragElementYPosition.current =
                draggableItem.getBoundingClientRect().y;

              const { clientY } = e;
              const fromTop = clientY - position.y ?? 0;
              finalDragElementTopPosition.current = fromTop <= 0 ? 0 : fromTop;
              lastCursorYPosition.current = clientY;
            };
          }
        }
      }
    },
    [dispatch]
  );

  const days = useMemo(() => {
    const days = [];
    const startWeek = new Date(dayjs(currentTime).startOf('isoWeek'));
    for (let i = 0; i < (showWeekend ? 7 : 5); i++) {
      let date = new Date(startWeek);
      date.setDate(date.getDate() + i);
      days.push(date);
    }
    return days;
  }, [currentTime, showWeekend]);

  const { selectedItems, selectedTotalItemsData } = useMemo(() => {
    const data = {
      selectedItems: 0,
      selectedTotalItemsData: 0,
    };

    Object.keys(hiddenData).forEach((key) => {
      if (hiddenData[key] && !hiddenData[key].hide) {
        data.selectedItems++;
        if (hiddenData[key]?.data?.total_calendar_events)
          data.selectedTotalItemsData +=
            hiddenData[key].data.total_calendar_events;
      }
    });

    return data;
  }, [hiddenData]);

  useEffect(() => {
    if (selectedTotalItemsData > CALENDAR_PREVIEW_DATA_LENGTH_THRESHOLD) {
      setShowWeeklyLimitSelectBox(true);
      setSkipGetCalendarWeeklyData(true);
    } else {
      setSkipGetCalendarWeeklyData(false);
    }
  }, [
    selectedTotalItemsData,
    setSkipGetCalendarWeeklyData,
    calendarWeeklyUsersEventCountData,
    calendarWeeklyWorkspacesEventCountData,
  ]);

  useEffect(() => {
    if (data?.length)
      dispatch(
        handleLoadMoreHideData(data, {
          searchParamSessionStarted: searchParamSessionStarted.current,
        })
      );
  }, [dispatch, data, data?.length]);

  const galleryPopupPresented = useSelector(
    (state) => state?.app?.toJS()?.galleryPopupPresented
  );

  return (
    <Page>
      <DragDropContext
        onDragEnd={dndOnDragEndHandler}
        onDragUpdate={dndOnDragUpdateHandler}
        onDragStart={dndOnDragStartHandler}
      >
        <Content data-testid="weeklyCalendarWrapper">
          <Header
            setActiveView={setActiveView}
            currentTime={currentTime}
            setCurrentTime={handleSetCurrentTime}
            activeAdvancedFiltersCount={activeAdvancedFiltersCount}
            clearAdvancedFilter={restUseCalendarProps.clearAdvancedFilters}
            setShowWeekend={setShowWeekend}
            showWeekend={showWeekend}
            weeklyDays={days}
          />
          {isLoading && selectedItems !== 0 ? (
            <LoadingSpinner />
          ) : (
            <Wrapper style={{ zIndex: galleryPopupPresented ? 200 : null }}>
              <CalendarWrapper
                style={{ marginTop: -140, paddingLeft: 0, paddingRight: 0 }}
              >
                <WowDisplayFlex
                  justify="space-evenly"
                  style={{
                    boxShadow: '0 6px 4px -2px rgba(0, 0, 0, 0.1)',
                    paddingBottom: 10,
                    marginBottom: 10,
                    position: 'sticky',
                    top: 0,
                    backgroundColor: '#fff',
                    paddingTop: 130,
                    zIndex: 100,
                  }}
                >
                  {days.map((date, index) => (
                    <WowDisplayFlex
                      data-testid="weeklyCalendarDayColumn"
                      direction="column"
                      {...(index === 0
                        ? {
                            style: {
                              position: 'relative',
                              right: showWeekend ? 10 : width < 1200 ? 30 : 50,
                            },
                          }
                        : index === 1
                        ? {
                            style: {
                              position: 'relative',
                              left: showWeekend
                                ? width < 1550
                                  ? 20
                                  : 0
                                : width < 1150
                                ? 20
                                : 0,
                            },
                          }
                        : index === 2
                        ? {
                            style: {
                              position: 'relative',
                              left: width < 1550 ? 10 : 0,
                            },
                          }
                        : index === days.length - 3
                        ? {
                            style: {
                              position: 'relative',
                              left: showWeekend ? 10 : width < 1200 ? 30 : 50,
                            },
                          }
                        : index === days.length - 2
                        ? {
                            style: {
                              position: 'relative',
                              left: showWeekend ? 10 : 30,
                            },
                          }
                        : index === days.length - 1
                        ? {
                            style: {
                              position: 'relative',
                              left: showWeekend ? 30 : width < 1200 ? 30 : 50,
                            },
                          }
                        : {})}
                    >
                      <CalendarHeaderDay
                        style={{
                          backgroundColor: dayjs().isSame(date, 'day')
                            ? null
                            : '#fff',
                          color: dayjs().isSame(date, 'day') ? null : darkGray,
                        }}
                      >
                        {date.getDate()}
                      </CalendarHeaderDay>
                      <Space height="5" />
                      <WowColorizeText
                        size="12"
                        color={darkGray}
                        uppercase
                        fWeight="bold"
                      >
                        {intl
                          .formatMessage({ id: WEEKDAYS[date.getDay()] })
                          .slice(0, 3)}
                      </WowColorizeText>
                    </WowDisplayFlex>
                  ))}
                </WowDisplayFlex>
                <Space height="15" />
                {selectedItems === 0 ? (
                  <>
                    <Space height="35" />
                    <NoDataMessage>
                      {activeView === CALENDAR_VIEW_TYPES.weeklyUsers
                        ? intl.formatMessage({ id: 'weekly_empty_data_users' })
                        : activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces
                        ? intl.formatMessage({
                            id: 'weekly_empty_data_workspace',
                          })
                        : ''}
                    </NoDataMessage>
                  </>
                ) : (
                  <WeeklyTimeline
                    data={JSON.parse(JSON.stringify(data ?? [])).splice(
                      0,
                      showWeekend ? 7 : 5
                    )}
                    startDayTimes={days}
                    handleUpdateDataItem={handleUpdateDataItem}
                    handleDeleteDataItem={handleDeleteDataItem}
                    isUninitializedData={isUninitializedData}
                    removeItemsFromUnscheduledSidebar={
                      removeItemsFromUnscheduledSidebar
                    }
                    draggableTimelineIssueRevertData={
                      draggableTimelineIssueRevertData
                    }
                  />
                )}
              </CalendarWrapper>
            </Wrapper>
          )}
        </Content>
        {(!isUnscheduledMenuOpen && !shouldShowOnlyOneSidebar) ||
        isQHDResolution ? (
          <MainDraggableFilter
            title={
              weeklyTimelineType && TYPES[weeklyTimelineType]
                ? intl.formatMessage({
                    id: TYPES[weeklyTimelineType].cardTitle,
                  })
                : ''
            }
            listData={sidebarData?.map((item) => ({
              id: item.id,
              display: item.full_name ?? item.name,
              checked:
                typeof hiddenData[item.id] === 'undefined'
                  ? false
                  : hiddenData[item.id]?.hide === true
                  ? false
                  : true,
              metadata: item,
            }))}
            isDragDisabled={true}
            listDataOnChange={handleHiddenData}
            searchValueOnChange={handleChangeCalendarSearch}
            // eslint-disable-next-line eqeqeq
            showLoadMore={!isLoading && page != last_page}
            handleLoadMore={loadMore}
            activeView={activeView}
            clearAllWithoutSearchClicked={clearAllWithoutSearchClicked}
            searchParamSessionStarted={searchParamSessionStarted}
            checkingWithSearchParamStarted={checkingWithSearchParamStarted}
          />
        ) : null}
        {shouldShowUnscheduledSidebar ? (
          <UnscheduledSidebar
            noDateHookData={noDateHookData}
            overdueHookData={overdueHookData}
          />
        ) : null}
        {isAdvancedFilterOpen ? <Filters /> : null}
      </DragDropContext>
      {showWeeklyLimitSelectBox && (
        <WeeklyLimitSelectBox
          isOpen={showWeeklyLimitSelectBox}
          description={intl.formatMessage({
            id: 'too_much_data_reduce_selection',
          })}
          heading={intl.formatMessage({ id: 'too_much_data' })}
          rows={weeklyLimitSelectBoxRows}
          handleAction={handleWeeklyLimitSelectBoxAction}
          handleClose={handleWeeklyLimitSelectBoxClose}
          isFetchingEventCount={
            isFetchingCalendarWeeklyUsersEventCount ||
            isFetchingCalendarWeeklyWorkspacesEventCount
          }
          activeView={activeView}
        />
      )}
    </Page>
  );
};

export default Weekly;
