import isNil from 'lodash.isnil';
import isEmpty from 'lodash.isempty';

export const attachPermissionsToDocument = (documentPermissions) => (
  document
) => {
  if (isNil(document)) {
    return document;
  }

  if (
    isEmpty(documentPermissions) ||
    isNil(documentPermissions.attachmentPermissions)
  ) {
    return { ...document, allowedToEdit: true, allowedToDelete: true };
  }

  const permission = documentPermissions.attachmentPermissions[document.id];

  const perms =
    document.state === 'NEW' || isEmpty(permission)
      ? { allowedToEdit: true, allowedToDelete: true }
      : permission;

  return { ...document, ...perms };
};
