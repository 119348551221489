import React from 'react';
import { ThemeProvider } from 'styled-components/macro';
import { connect } from 'react-redux';
import {
  hasTranslations,
  getEntityLoadingStatus,
} from '../../../redux/entities/selectors';
import { themePath } from '../../../redux/config/selectors';
import { entities } from '../../../config/entitiesConfig';
import { LoadingSpinner } from '../../../components/loading-spinner/loading-spinner.component';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { fetchEntityFromCacheWithFilter } from '../../../redux/entities/action-creators';
import {
  CATEGORY_LIST,
  USER_LIST,
  WORKSPACE_LIST,
} from '../../../redux/filter/filters';
import { ToastContainer } from 'react-toastify';

const idleTime = 1000 * 60 * 5;

const AppBase = (props) => {
  const dispatch = useDispatch();
  //TO DO > check and clear the legacy
  const handleOnActive = () => {
    if (getElapsedTime() > idleTime) {
      dispatch(
        fetchEntityFromCacheWithFilter(entities.WORKSPACE, WORKSPACE_LIST, true)
      );
      dispatch(
        fetchEntityFromCacheWithFilter(entities.CATEGORY, CATEGORY_LIST, true)
      );
      dispatch(fetchEntityFromCacheWithFilter(entities.USERS, USER_LIST, true));
    }
  };

  const { getElapsedTime } = useIdleTimer({
    timeout: idleTime,
    onActive: handleOnActive,
  });

  if (props.translationLoading) {
    return (
      <div className="app">
        <ThemeProvider theme={props.theme}>
          <LoadingSpinner />
        </ThemeProvider>
      </div>
    );
  }

  return (
    <div className="app">
      <ThemeProvider theme={props.theme}>{props.children}</ThemeProvider>
      <ToastContainer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  theme: themePath(state),
  hasTranslations: hasTranslations(state),
  translationLoading: getEntityLoadingStatus(state, entities.LANGUAGE.name),
});

export const App = connect(mapStateToProps)(React.memo(AppBase));
