import { useMemo, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components/macro';

import { Body, Header } from '../../../../../components_new/AdvancedFilters';
import InputMultiSelectV2 from '../../../../../components_new/InputMultiselectV2';
import InputMultiselectUsers from '../../../../../components_new/InputMultiselectUsers';
import QuickFilter from '../../../../../components/quick-filter/quick-filter.component';
import { ActivityButton } from '../../../../../components/button/activity-button/activity-button';

import useSkipQuery from '../../../../../hooks/useSkipQuery';
import { useUsers, useWorkspaces } from '../../../Equipment/hooks';
import useCategories from '../../../IssuesV2/Categories/hooks/useCategoriesInfinite';
import useAssetsInfinite from '../../../Assets/hooks/useAssetsInfinite';
import useEquipmentInfinite from '../../../Equipment/hooks/useEquipmentInfinite';
import useAssetSystems from '../../../Assets/hooks/useSystems';
import useAssetGroups from '../../../Assets/hooks/useGroups';

import dict from '../../../../../config/multilang';

// constants
import { statuses } from '../../constants';
import { issuePriorityFilterOptions } from '../../utils';
import { filtersRed, white } from '../../../../../config/colors';

import { DEFAULT_CONFIG } from './constants';
import { ContentWrapper } from './styles';
import Space from '../../../../../components_new/Space';
import Switch from '../../../../../components_new/Switch/Switch';
import {
  WowColorizeText,
  WowDisplayFlex,
} from '../../../../../components_new/WowBasicElements';

const Filter = (props = {}) => {
  const intl = useIntl();

  const {
    isOpen,
    parentHookProps,
    disableHideFilterItems,
    handleToggle, // used to hide filter if needed
  } = props;

  const {
    // TODO: CHECk
    status,
    priorityId,
    assignees,
    replacements,
    watchers,
    categories,
    workspaces,
    includeSubWs,
    assets,
    asset_system_ids,
    asset_group_ids,
    equipment,

    // persisted filters list
    // used to set default values
    persistedFilters,

    // handler functions
    handleMultipleInputFilterChange,
    handleFilterChange,
    handleWorkspacesFilterChange,
    toggleSubworkspace,
    handleClearAllFilters,

    handleMultiselectChange,
    handleClearMultiselectUsers,
  } = parentHookProps;

  // #region replacements
  const [getOptionsReplacements, setGetOptionsReplacements] = useState(false);
  const { skipQuery: skipQueryReplacements } = useSkipQuery({
    removeSkip: getOptionsReplacements,
  });

  const {
    scrollState: replacementsState,
    handleLoadMore: handleLoadMoreReplacements,
    handleSearch: handleSearchReplacements,
    isLoading: isLoadingReplacements,
    mergeStateItems: mergeStateItemsReplacements,
  } = useUsers(skipQueryReplacements);

  useEffect(() => {
    if (persistedFilters?.replacements?.length > 0) {
      mergeStateItemsReplacements({
        data: persistedFilters.replacements,
      });
    }
  }, [mergeStateItemsReplacements, persistedFilters.replacements]);

  // #endregion

  // #region categories
  const [getOptionsCategories, setGetOptionsCategories] = useState(false);
  const { skipQuery: skipQueryCategories } = useSkipQuery({
    removeSkip: getOptionsCategories,
  });

  const {
    scrollState: categoriesState,
    handleLoadMore: handleLoadMoreCategories,
    handleSearch: handleSearchCategories,
    isLoading: isLoadingCategories,
    mergeStateItems: mergeStateItemsCategories,
  } = useCategories({ skipQuery: skipQueryCategories });

  useEffect(() => {
    if (persistedFilters?.categories?.length > 0) {
      mergeStateItemsCategories({
        data: persistedFilters.categories,
      });
    }
  }, [mergeStateItemsCategories, persistedFilters.categories]);
  // #endregion

  // #workspaces region
  const [getOptionsWorkspaces, setGetOptionsWorkspaces] = useState(false);
  const { skipQuery: skipQueryWorkspaces } = useSkipQuery({
    removeSkip: getOptionsWorkspaces,
  });

  const customWorkspacesParams = useMemo(() => {
    return {
      selected_workspaces: workspaces ?? [],
      fav_first: 1,
    };
  }, [workspaces]);

  const {
    scrollState: workspacesState,
    handleLoadMore: handleLoadMoreWorkspaces,
    handleSearch: handleSearchWorkspaces,
    isLoading: isLoadingWorkspaces,
    mergeStateItems: mergeStateItemsWorkspaces,
  } = useWorkspaces(customWorkspacesParams, skipQueryWorkspaces);

  useEffect(() => {
    if (persistedFilters?.workspaces?.length > 0) {
      mergeStateItemsWorkspaces({
        data: persistedFilters.workspaces,
      });
    }
  }, [mergeStateItemsWorkspaces, persistedFilters.workspaces]);

  // #endregion

  // #region assets region
  const [getOptionsAssets, setGetOptionsAssets] = useState(false);
  const { skipQuery: skipQueryAssets } = useSkipQuery({
    removeSkip: getOptionsAssets,
  });

  const {
    scrollState: assetsDropdownState,
    handleLoadMore: handleLoadMoreAssets,
    handleSearch: handleSearchAssets,
    isFetching: isLoadingAssets,
    mergeStateItems: mergeStateItemsAssets,
  } = useAssetsInfinite({
    skipQuery: skipQueryAssets,
  });

  useEffect(() => {
    if (persistedFilters?.assets?.length > 0) {
      mergeStateItemsAssets({
        data: persistedFilters.assets,
      });
    }
  }, [mergeStateItemsAssets, persistedFilters.assets]);

  // #endregion

  // #region asset systems region
  const [getOptionsAssetSystems, setGetOptionsAssetSystems] = useState(false);
  const { skipQuery: skipQueryAssetSystems } = useSkipQuery({
    removeSkip: getOptionsAssetSystems,
  });

  const {
    scrollState: assetSystemsDropdownState,
    handleLoadMore: handleLoadMoreAssetSystems,
    handleSearch: handleSearchAssetSystems,
    isFetching: isLoadingAssetSystems,
    mergeStateItems: mergeStateItemsAssetSystems,
  } = useAssetSystems({
    skipQuery: skipQueryAssetSystems,
  });

  useEffect(() => {
    if (persistedFilters?.asset_system_ids?.length > 0) {
      mergeStateItemsAssetSystems({
        data: persistedFilters.asset_system_ids,
      });
    }
  }, [mergeStateItemsAssetSystems, persistedFilters.asset_system_ids]);

  // #endregion

  // #region asset systems region
  const [getOptionsAssetGroups, setGetOptionsAssetGroups] = useState(false);
  const { skipQuery: skipQueryAssetGroups } = useSkipQuery({
    removeSkip: getOptionsAssetGroups,
  });

  const {
    scrollState: assetGroupsDropdownState,
    handleLoadMore: handleLoadMoreAssetGroups,
    handleSearch: handleSearchAssetGroups,
    isFetching: isLoadingAssetGroups,
    mergeStateItems: mergeStateItemsAssetGroups,
  } = useAssetGroups({
    skipQuery: skipQueryAssetGroups,
  });

  useEffect(() => {
    if (persistedFilters?.asset_group_ids?.length > 0) {
      mergeStateItemsAssetGroups({
        data: persistedFilters.asset_group_ids,
      });
    }
  }, [mergeStateItemsAssetGroups, persistedFilters.asset_group_ids]);

  // #endregion

  // #region equipment region
  const [getOptionsEquipment, setGetOptionsEquipment] = useState(false);
  const { skipQuery: skipQueryEquipment } = useSkipQuery({
    removeSkip: getOptionsEquipment,
  });

  const {
    scrollState: equipmentDropdownState,
    handleLoadMore: handleLoadMoreEquipment,
    handleSearch: handleSearchEquipment,
    isFetching: isLoadingEquipment,
    mergeStateItems: mergeStateItemsEquipment,
  } = useEquipmentInfinite({
    skipQuery: skipQueryEquipment,
  });

  useEffect(() => {
    if (persistedFilters?.equipment?.length > 0) {
      mergeStateItemsEquipment({
        data: persistedFilters.equipment,
      });
    }
  }, [mergeStateItemsEquipment, persistedFilters.equipment]);
  // #endregion

  const _handleWorkspacesFilterChange = (workspace) => {
    const isSelected = !workspaces?.includes(`${workspace?.id}`);

    const workspacesIds = isSelected
      ? [...workspaces, `${workspace?.id}`]
      : workspaces?.filter?.((workspaceId) => +workspaceId !== workspace?.id);

    let shouldIncludeSubWs = false;

    for (const wsId of workspacesIds) {
      const workspaceFromList = workspacesState?.itemsList?.find(
        (item) => item?.id === +wsId
      );

      if (workspaceFromList?.children?.length > 0) {
        shouldIncludeSubWs = isSelected;
        handleWorkspacesFilterChange(workspace, shouldIncludeSubWs);

        return;
      }
    }

    handleWorkspacesFilterChange(workspace, shouldIncludeSubWs);
  };

  return (
    <Body isOpen={isOpen} disableOverflow>
      <Header onClose={handleToggle}>
        <FormattedMessage id="filters" defaultMessage={dict.filters} />
      </Header>
      <ContentWrapper>
        <ShowOnSmallScreen disabled={disableHideFilterItems}>
          <InputWrapper>
            <QuickFilter
              onFilterChanged={handleFilterChange('status')}
              value={statuses
                .map((status) => ({
                  key: status.value,
                  caption: intl.formatMessage({
                    id: status.label,
                    defaultMessage: dict[status.label],
                  }),
                  className: 'menuitem',
                }))
                .find((currStatus) => currStatus.key === status)}
              filterOptions={statuses.map((status) => ({
                key: status.value,
                caption: intl.formatMessage({
                  id: status.label,
                  defaultMessage: dict[status.label],
                }),
                className: 'menuitem',
              }))}
            />
          </InputWrapper>
        </ShowOnSmallScreen>
        <ShowOnSmallScreen disabled={disableHideFilterItems}>
          <InputWrapper>
            <QuickFilter
              onFilterChanged={handleFilterChange('priority_id')}
              value={issuePriorityFilterOptions(intl).find(
                (priority) => priority.key === priorityId
              )}
              filterOptions={issuePriorityFilterOptions(intl)}
            />
          </InputWrapper>
        </ShowOnSmallScreen>

        <InputMultiselectUsers
          {...DEFAULT_CONFIG.assignees}
          onChange={handleMultiselectChange}
          selected={assignees}
          clearAll={handleClearMultiselectUsers}
        />
        <Space height="20" />

        <InputWrapper>
          <InputMultiSelectV2
            items={replacementsState.itemsList}
            handleGet={() => setGetOptionsReplacements(true)}
            onChange={handleMultipleInputFilterChange('replacements')}
            handleLoadMore={handleLoadMoreReplacements}
            handleSearch={handleSearchReplacements}
            hasMore={replacementsState.hasMore}
            isLoading={isLoadingReplacements}
            selectHeaderLabel={intl.formatMessage({
              id: 'replacement',
            })}
            selected={replacements}
          />
        </InputWrapper>

        <InputMultiselectUsers
          {...DEFAULT_CONFIG.watchers}
          onChange={handleMultiselectChange}
          selected={watchers}
          clearAll={handleClearMultiselectUsers}
        />
        <Space height="20" />

        <InputWrapper>
          <InputMultiSelectV2
            items={categoriesState.itemsList}
            handleGet={() => setGetOptionsCategories(true)}
            onChange={handleMultipleInputFilterChange('categories')}
            handleLoadMore={handleLoadMoreCategories}
            handleSearch={handleSearchCategories}
            hasMore={categoriesState.hasMore}
            isLoading={isLoadingCategories}
            selectHeaderLabel={intl.formatMessage({
              id: 'category',
            })}
            selected={categories}
          />
        </InputWrapper>

        <InputWrapper>
          <InputMultiSelectV2
            items={workspacesState.itemsList}
            handleGet={() => setGetOptionsWorkspaces(true)}
            onChange={_handleWorkspacesFilterChange}
            handleLoadMore={handleLoadMoreWorkspaces}
            handleSearch={handleSearchWorkspaces}
            hasMore={workspacesState.hasMore}
            isLoading={isLoadingWorkspaces}
            selectHeaderLabel={intl.formatMessage({
              id: 'workspace',
            })}
            selected={workspaces}
          />
        </InputWrapper>

        {workspaces.length ? (
          <InputWrapper>
            <>
              <WowDisplayFlex>
                <Switch
                  name="includeWorkspaces"
                  value={Boolean(includeSubWs)}
                  checked={Boolean(includeSubWs)}
                  onChange={(isChecked) => {
                    let newWorkspaces = workspacesState.itemsList.filter(
                      (item) =>
                        workspaces?.includes(String(item.id)) &&
                        item.parent_id === null
                    );

                    let newWorkspacesUpdated = [];

                    const formatWorkspaceItem = (item) => {
                      return {
                        id: item.id,
                        value: item.value,
                        label: item.label || item.name || item.title,
                        name: item.name,
                        parent_id: item.parent_id ?? null,
                        ...(item.children
                          ? {
                              children: item.children.map((child) =>
                                formatWorkspaceItem(child)
                              ),
                            }
                          : {}),
                      };
                    };

                    if (isChecked) {
                      for (const ws of newWorkspaces) {
                        newWorkspacesUpdated.push(formatWorkspaceItem(ws));

                        if (ws.children?.length) {
                          for (const subWs of ws.children) {
                            newWorkspacesUpdated.push(
                              formatWorkspaceItem(subWs)
                            );
                          }
                        }
                      }
                    } else {
                      newWorkspacesUpdated = [...newWorkspaces];
                    }
                    toggleSubworkspace(isChecked, newWorkspacesUpdated);
                  }}
                />
                <WowDisplayFlex>
                  <Space width="10" />
                  <WowColorizeText size="13">
                    {intl.formatMessage({
                      id: 'include_sub_ws',
                    })}
                  </WowColorizeText>
                </WowDisplayFlex>
              </WowDisplayFlex>

              <Space height="10" />
            </>
          </InputWrapper>
        ) : null}

        <InputWrapper>
          <InputMultiSelectV2
            items={assetsDropdownState.itemsList}
            handleGet={() => setGetOptionsAssets(true)}
            onChange={handleMultipleInputFilterChange('assets')}
            handleLoadMore={handleLoadMoreAssets}
            handleSearch={handleSearchAssets}
            hasMore={assetsDropdownState.hasMore}
            isLoading={isLoadingAssets}
            selectHeaderLabel={intl.formatMessage({
              id: 'assets',
            })}
            selected={assets}
          />
        </InputWrapper>

        <InputWrapper>
          <InputMultiSelectV2
            items={assetSystemsDropdownState.itemsList}
            handleGet={() => setGetOptionsAssetSystems(true)}
            onChange={handleMultipleInputFilterChange('asset_system_ids')}
            handleLoadMore={handleLoadMoreAssetSystems}
            handleSearch={handleSearchAssetSystems}
            hasMore={assetSystemsDropdownState.hasMore}
            isLoading={isLoadingAssetSystems}
            selectHeaderLabel={intl.formatMessage({
              id: 'asset_systems',
            })}
            selected={asset_system_ids}
          />
        </InputWrapper>

        <InputWrapper>
          <InputMultiSelectV2
            items={assetGroupsDropdownState.itemsList}
            handleGet={() => setGetOptionsAssetGroups(true)}
            onChange={handleMultipleInputFilterChange('asset_group_ids')}
            handleLoadMore={handleLoadMoreAssetGroups}
            handleSearch={handleSearchAssetGroups}
            hasMore={assetGroupsDropdownState.hasMore}
            isLoading={isLoadingAssetGroups}
            selectHeaderLabel={intl.formatMessage({
              id: 'asset_group',
            })}
            selected={asset_group_ids}
          />
        </InputWrapper>

        <InputWrapper>
          <InputMultiSelectV2
            items={equipmentDropdownState.itemsList}
            handleGet={() => setGetOptionsEquipment(true)}
            onChange={handleMultipleInputFilterChange('equipment')}
            handleLoadMore={handleLoadMoreEquipment}
            handleSearch={handleSearchEquipment}
            hasMore={equipmentDropdownState.hasMore}
            isLoading={isLoadingEquipment}
            selectHeaderLabel={intl.formatMessage({
              id: 'equipment',
            })}
            selected={equipment}
          />
        </InputWrapper>

        <ButtonWrapper>
          <ActivityButton
            icon="icon-close"
            caption={intl.formatMessage({
              id: 'clear_all',
              defaultMessage: dict.clear_all,
            })}
            backColor={filtersRed}
            color={white}
            onClick={handleClearAllFilters}
          />
        </ButtonWrapper>
      </ContentWrapper>
    </Body>
  );
};

export default Filter;

const InputWrapper = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const ShowOnSmallScreen = styled.div`
  width: 100%;
  ${({ disabled }) =>
    !disabled
      ? `display: none; 

  @media (max-width: 1620px) {
    display: block;
    width: 100%;
  }`
      : null}
`;

const ButtonWrapper = styled.div`
  margin-left: -10px;
  margin-bottom: 13px;
  display: flex;
`;
