import { FooterWrapper } from './styles';
import PropTypes from 'prop-types';

const Footer = ({ children }) => {
  return <FooterWrapper data-testid="footer">{children}</FooterWrapper>;
};

Footer.propTypes = {
  children: PropTypes.node,
};

export default Footer;
