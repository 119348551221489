import { createSlice } from '@reduxjs/toolkit';

const INIT_STATE = {
  entity: null,
  elementsInSecondRow: {},
  showMore: false,
  isSaveModalOpen: false,
  isPreviewModalOpen: false,
  quickFilters: {
    standard: [],
    saved: [],
  },
  latestSavedFilter: {},
  assetsSearch: '',
  previewFilter: {},
};

export const filtersSlice = createSlice({
  name: 'listFilters',
  initialState: INIT_STATE,
  reducers: {
    setEntity: (state, action) => {
      state.entity = action.payload;
    },
    setElementsInSecondRow: (state, action) => {
      state.elementsInSecondRow = action.payload;
    },
    setShowMore: (state, action) => {
      state.showMore = action.payload;
    },
    setIsSaveModalOpen: (state, action) => {
      state.isSaveModalOpen = action.payload;
    },
    setIsPreviewModalOpen: (state, action) => {
      state.isPreviewModalOpen = action.payload;
    },
    setQuickFilters: (state, action) => {
      state.quickFilters = {
        ...state.quickFilters,
        ...action.payload,
      };
    },
    setLatestSavedFilter: (state, action) => {
      const { entity } = state;
      state.latestSavedFilter[entity] = action.payload;
    },
    setAssetsSearch: (state, action) => {
      state.assetsSearch = action.payload;
    },
    setPreviewFilter: (state, action) => {
      state.previewFilter = action.payload;
    },
  },
});

export const {
  setEntity,
  setElementsInSecondRow,
  setShowMore,
  setIsSaveModalOpen,
  setIsPreviewModalOpen,
  setQuickFilters,
  setLatestSavedFilter,
  setAssetsSearch,
  setPreviewFilter,
} = filtersSlice.actions;

export default filtersSlice.reducer;
