export const MULTPLE_SELECT_FILTERS = {
  assets: 'assets_ids',
  asset_system_ids: 'asset_system_ids',
  asset_group_ids: 'asset_group_ids',
  categories: 'categories_ids',
  equipment: 'equipments_ids',
  replacements: 'replacements_ids',
  workspaces: 'workspaces_ids',
};

export const INIT_ADVANCED_FILTERS_MULTISELECT = {
  assignees: [],
  assignees_companies: [],
  assignees_externals: [],
  replacements_ids: [],
  watchers_ids: [],
  watchers_companies_ids: [],
  watchers_externals_ids: [],
  workspaces_ids: [],
  assets_ids: [],
  asset_system_ids: [],
  asset_group_ids: [],
  equipments_ids: [],
  categories_ids: [],
};

export const INIT_ADVANCED_FILTERS = {
  status: null,
  priority_id: null,
  include_sub_ws: null,
  ...INIT_ADVANCED_FILTERS_MULTISELECT,
};
