import { useRef, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import pick from 'lodash.pick';
import {
  INIT_ADVANCED_FILTERS,
  INIT_ADVANCED_FILTERS_MULTISELECT,
} from '../constants';

import { updatePersistedFilters } from '../../../../redux/config/actions';
import { formatMultiselectItemsWithChildren } from '../../../../utils/filters';

const usePersistFilter = ({ persist = false, type = 'issue' }) => {
  const dispatch = useDispatch();

  const _ref = useRef({});

  const setFilter = useCallback((value) => (_ref.current = value), []);

  const syncFilter = useCallback((params = {}) => {
    _ref.current = {
      ...pick(
        params,
        Object.keys(params).filter(
          (key) => !Object.keys(INIT_ADVANCED_FILTERS_MULTISELECT).includes(key)
        )
      ),
      ...pick(
        { ..._ref.current },
        Object.keys({ ..._ref.current }).filter((key) => {
          const _key = key.replace('_params', '');
          return (
            !_key.includes('issue_state') &&
            Object.keys(INIT_ADVANCED_FILTERS).includes(_key) &&
            Object.keys(params).includes(_key)
          );
        })
      ),
    };
  }, []);

  const updateFilter = useCallback((key, item, shouldIncludeSubWs) => {
    const _mutatedItem = {
      ...item,
      ...(item.children ? { total_subws: item.children.length } : {}),
    };
    //extended key is needed due to BE
    const extendedKey = `${key}_params`;
    const targetArray = _ref.current?.[extendedKey] ?? [];

    const newValues = formatMultiselectItemsWithChildren(
      targetArray,
      _mutatedItem,
      shouldIncludeSubWs
    );

    _ref.current = {
      ..._ref.current,
      [extendedKey]: newValues,
    };
  }, []);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (persist) dispatch(updatePersistedFilters(_ref.current, type));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    setFilter,
    syncFilter,
    updateFilter,
  };
};

export default usePersistFilter;
