import { useInfiniteLazyQuery } from '../../../../hooks/useInfinite';
import { useLazyGetUsersQuery } from '../service';

const useUsersInfinite = ({
  skip,
  customParams,
  preventReset,
  preferCacheValue,
  entityKey,
} = {}) => {
  const {
    data,
    isLoading,
    handleLoadMore,
    hasMore,
    handleUpdateQueryParams,
    query,
    total,
    handleDelete,
    handleSearch,
  } = useInfiniteLazyQuery({
    skip,
    preventReset,
    customParams,
    preferCacheValue,
    useLazyQuery: useLazyGetUsersQuery,
    entityKey,
  });

  return {
    data,
    isLoading,
    handleLoadMore,
    hasMore,
    handleUpdateQueryParams,
    query,
    total,
    handleDelete,
    handleSearch,
  };
};

export default useUsersInfinite;
