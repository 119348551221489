import { newRed, purple500 } from '../../../../config/colors';

export const ICON_CONFIG = {
  add: {
    color: purple500,
    className: 'icon icon-plus',
    spaceRight: '0',
    size: '12',
    clickable: true,
  },
  close: {
    color: newRed,
    className: 'icon icon-close',
    spaceRight: '0',
    size: '13',
    clickable: true,
  },
};
