import styled from 'styled-components/macro';
import { WowListItemSmallTitle } from '../../../../components/wowlist/ItemSmall';

export const HideOnMediumScreen = styled.div`
  & > :first-child { {
    margin-left: 0px;
  }
  @media (max-width: 1599.98px) {
    display: none;
  }
`;

export const HideOnLargeScreen = styled.div`
  width: 100%;

  ${({ disabled }) =>
    !disabled
      ? `@media (min-width: 1600px) {
    display: none;
  }`
      : null}
`;

export const SmallItemTitle = styled(WowListItemSmallTitle)`
  font-weight: 600;
`;
