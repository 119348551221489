import { useSelector } from 'react-redux';
import { getFilters } from '../selectors';
import { setFilters } from '../slice';

const useFilters = () => {
  const filters = useSelector(getFilters);
  return {
    filters,
    setFilters,
  };
};

export default useFilters;
