import { useIntl } from 'react-intl';
import { ActivityButton } from '../../../components/button/activity-button/activity-button';
import ActionButton from '../../../components/sticky-header/ActionButton';
import Tooltip from '../../../components_new/Tooltip';
import { WowTooltipWrapper } from '../../../components_new/WowBasicElements';
import { filtersBlue, white } from '../../../config/colors';
import { mainAccent } from '../../../helpers/theme';
import useVisible from '../../../hooks/useVisible';

import { Menu } from '../../Menu';
import { EXPORT_TYPES } from './constants';
import { ButtonWrapper } from './styles';

const ExportButton = ({ handleExport, disabled, tooltipButtonText }) => {
  const { ref, isVisible, toggleVisible } = useVisible(false);
  const intl = useIntl();
  const handleToggle = () => {
    if (disabled) return;
    toggleVisible();
  };

  return (
    <ButtonWrapper ref={ref}>
      <Tooltip
        tooltipContent={
          tooltipButtonText ? (
            <WowTooltipWrapper>{tooltipButtonText}</WowTooltipWrapper>
          ) : null
        }
      >
        <ActivityButton
          icon="icon-download"
          caption={intl.formatMessage({
            id: 'export',
          })}
          backColor={mainAccent ?? filtersBlue}
          color={white}
          onClick={handleToggle}
          disabled={disabled}
        />

        {isVisible && (
          <Menu
            size="responsive"
            position="right"
            distanceX="-10"
            distanceY="-160"
          >
            {EXPORT_TYPES.map((type, index) => (
              <ActionButton
                key={index}
                variant="menu-item"
                onClick={() => handleExport(type)}
                uppercase
              >
                {type}
              </ActionButton>
            ))}
          </Menu>
        )}
      </Tooltip>
    </ButtonWrapper>
  );
};

export default ExportButton;
