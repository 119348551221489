import React, { createRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { FormattedMessage } from 'react-intl';

import { formatDateToFilters } from '../../helpers/date.helper';
import {
  DateRangeWrapper,
  DoneButton,
  DateSpanWrapper,
  DateSpan,
  Dash,
  IconWrapper,
  DateWrapper,
  AddDateRange,
} from './styles';
import { ThemedIcon } from '../../helpers/theme';

const RenderDates = ({ placeholder, startDate, endDate }) => {
  let dates = placeholder;

  if (startDate) {
    dates = <DateSpan>{formatDateToFilters(startDate)}</DateSpan>;
  }

  if (startDate && endDate) {
    dates = (
      <DateSpan>
        {formatDateToFilters(startDate)}
        <Dash>-</Dash>
        {formatDateToFilters(endDate)}
      </DateSpan>
    );
  }

  return dates;
};

const DateRange = ({
  startDate,
  endDate,
  disabled,
  onStartChange,
  onEndChange,
  onClear,
  onShowDateRangeChange = () => {},
  format,
  doneButtonText,
  placeholder,
  filter,
}) => {
  const [showDateRange, setShowDateRange] = useState(
    () => startDate && endDate
  );
  const dateRangeRef = createRef();

  const openDatepicker = () => {
    if (disabled) {
      return;
    }

    // hack WOW-1912
    onShowDateRangeChange(true);

    dateRangeRef.current.setOpen(true);
  };

  const closeDatepicker = () => {
    // hack WOW-1912
    onShowDateRangeChange(false);

    dateRangeRef.current?.setOpen?.(false);
  };

  const handleDateChange = (date) => {
    if (!startDate) {
      return onStartChange(date);
    }

    if (startDate && !endDate) {
      return onEndChange(date);
    }

    if (startDate && endDate) {
      onStartChange(date);
      onEndChange(undefined);
    }
  };

  const handleSelect = (date) => {
    if (!disabled && startDate && !endDate && sameDay(date, startDate)) {
      handleDateChange(date);
    }
  };

  const sameDay = (d1, d2) => {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  const toggleShowDateRange = () => {
    setShowDateRange(!showDateRange);
  };

  const handleClearDateRange = () => {
    setShowDateRange(!showDateRange);

    onClear();
  };

  return (
    <DateWrapper>
      {!showDateRange ? (
        <AddDateRange onClick={toggleShowDateRange} secondaryAccent>
          + <FormattedMessage id="add_date_range" />
        </AddDateRange>
      ) : (
        <DateRangeWrapper>
          <DatePicker
            ref={dateRangeRef}
            selected={startDate}
            onChange={handleDateChange}
            onSelect={handleSelect}
            selectsEnd={Boolean(!endDate)}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate && !endDate ? startDate : null}
            calendarClassName="date-range"
            format={format}
            shouldCloseOnSelect={false}
            disabled={disabled}
            popperPlacement="top"
            popperModifiers={{
              preventOverflow: {
                enabled: true,
                escapeWithReference: false,
                boundariesElement: 'viewport',
              },
            }}
            onClickOutside={closeDatepicker}
          >
            <DoneButton onClick={closeDatepicker}>{doneButtonText}</DoneButton>
          </DatePicker>

          <DateSpanWrapper onClick={openDatepicker}>
            <RenderDates
              startDate={startDate}
              endDate={endDate}
              placeholder={placeholder}
            />
          </DateSpanWrapper>

          <IconWrapper>
            {startDate || endDate ? (
              <ThemedIcon
                className="icon icon-close"
                onClick={handleClearDateRange}
              />
            ) : (
              <ThemedIcon className="icon icon-plus" onClick={openDatepicker} />
            )}
          </IconWrapper>
        </DateRangeWrapper>
      )}
    </DateWrapper>
  );
};

export default DateRange;
