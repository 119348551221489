import { useEffect } from 'react';

import {
  RTK_HOOK_CONFIG,
  INFINITE_SCROLL_INITIAL_STATE,
} from '../../../../config/constants';
import useInfiniteScrollData from '../../Equipment/hooks/useInfiniteScrollData'; //TO DO - lift
import { useGetGroupsQuery } from '../Groups/service';

const useGroups = ({ skipQuery, customParams } = {}) => {
  const {
    scrollState,
    handleLoadMore,
    handleSearch,
    handleStateUpdate,
    mergeStateItems,
    deleteManuallyFromListItems,
    handleCustomQuery,
  } = useInfiniteScrollData({
    ...INFINITE_SCROLL_INITIAL_STATE,
    query: {
      ...INFINITE_SCROLL_INITIAL_STATE.query,
      ...(customParams || {}),
    },
    id: 'assetGroups',
  });

  const { data: fetchedData, isFetching } = useGetGroupsQuery(
    { ...scrollState.query },
    {
      ...RTK_HOOK_CONFIG,
      skip: skipQuery || false,
    }
  );

  useEffect(() => {
    handleStateUpdate(fetchedData);
  }, [fetchedData, handleStateUpdate]);

  return {
    scrollState,
    handleLoadMore,
    handleSearch,
    isFetching,
    handleStateUpdate,
    mergeStateItems,
    deleteManuallyFromListItems,
    handleCustomQuery,
  };
};

export default useGroups;
