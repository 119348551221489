import React from 'react';
import PropTypes from 'prop-types';
import { Aside } from './styles';

const AdvancedFilters = ({ isOpen, disableOverflow, children, zIndex }) => {
  return (
    <Aside
      isOpen={isOpen}
      disableOverflow={disableOverflow}
      zIndex={zIndex}
      data-testid="advancedFiltersWrapper"
    >
      {children}
    </Aside>
  );
};

AdvancedFilters.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  disableOverflow: PropTypes.bool,
};

export default AdvancedFilters;
