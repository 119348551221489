import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import DateRange from '../../../../../components/date-range/date-range';
import {
  Body,
  Header,
  Footer,
} from '../../../../../components_new/AdvancedFilters';
import DropdownFilter from '../../../../../components_new/Dropdown/DropdownFilter';
import Space from '../../../../../components_new/Space';
import { StatusFilterDropdown } from '../../../../../components_new/StickyHeader';
import { dateFormats } from '../../../../../config/config';
import useSkipQuery from '../../../../../hooks/useSkipQuery';
import useAssetsInfinite from '../../../Assets/hooks/useAssetsInfinite';
import { HideOnLargeScreen } from '../../../Equipment/components/styles';
import { useWorkspaces } from '../../../Equipment/hooks';
import useSchedules from '../../../scheduling/hooks/useSchedules';
import useCategories from '../../Categories/hooks/useCategoriesInfinite';
import { issueStatusOptions } from '../../constants';
import useExport from '../../hooks/useExport';
import { ContentWrapper } from './styles';
import InputMultiselect from '../../../../../components_new/InputMultiselectV2';
import Switch from '../../../../../components_new/Switch/Switch';
import ButtonAdd from '../../../../../components_new/ButtonAdd';
import {
  WowColorizeText,
  WowDisplayFlex,
} from '../../../../../components_new/WowBasicElements';
import { filtersAdvancedGray, newRed } from '../../../../../config/colors';
import ExportButton from '../../../../../components_new/AdvancedFilters/ExportButton';
import ModalExportData from '../ModalExportData';
import { extractFiltersValues, getDifference } from '../../utils';
import useUserPositions from '../../hooks/useUserPositions';

import { filterWithSearch } from '../../../../../utils/utility';
import InputMultiselectUsers from '../../../../../components_new/InputMultiselectUsers';
import { DEFAULT_CONFIG } from './constants';
import useFilters from './hooks/useFilters';

const Filters = (props) => {
  const intl = useIntl();

  const {
    isOpen,
    parentHookProps, //TO DO > naming
    handleToggle,
    persistedFilters,
    disableHideFilterItems,
    hiddenFilters, //if more filters are needed conditionally make config
    enableExport,
    persistKey,
  } = props;

  const {
    issueType,
    issueStatus,
    setIssueStatus,
    issuePriority,
    includeSubWs,
    setIncludeSubWs,
    workspaces,
    assetIds,
    scheduleIds,
    categories,
    setSingleSelectFilter,
    setMultiselectFilter,
    clearAdvancedFilters,
    queryParams,
    setDateFrom,
    dateFrom,
    setDateTo,
    dateTo,
    clearDateRange,
    /* activeAdvancedFiltersCount, */
    filterByJobPosition,
    clearMultiselectUsers,
  } = parentHookProps;

  const [isFilterOverflowVisible, setIsFilterOverflowVisible] = useState(false);
  const [isShowMore, setShowMore] = useState(false);
  const toggleShowMore = () => setShowMore(!isShowMore);
  const disableExport = false; /* activeAdvancedFiltersCount === 0; */

  const extractFilterValues = useCallback(
    (existingItems = [], property) => {
      return extractFiltersValues(
        existingItems,
        property,
        persistedFilters?.parameters
      );
    },
    [persistedFilters]
  );

  //#region export region
  const [isVisibleExportModal, setIsVisibleExportModal] = useState(false);

  const [exportFileType, setExportFileType] = useState();
  const [activeFormat, setActiveFormat] = useState('summary');
  const handleFormatToggle = (e) => {
    const { name } = e.target;
    setActiveFormat(name);
  };

  const handleToggleExportModal = () => {
    // when is process active
    // wait to be done
    if (isProcessingExport) {
      return;
    }
    setIsVisibleExportModal((prevState) => !prevState);
  };

  const { isProcessingExport, exportItems } = useExport();

  const handleExport = async (fileType, isSummary = false) => {
    const { density, isAdvancedFilterOpen, ...restParams } = queryParams;
    const payload = {};

    for (const [key, value] of Object.entries(restParams)) {
      payload[key] = Array.isArray(value) ? value.toString() : value;
    }

    if (isSummary) payload.report_type = 'summary';

    await exportItems({
      fileType,
      payload,
    });
    setIsVisibleExportModal(false);
  };

  const onExportTypeClick = (fileType) => {
    setIsVisibleExportModal(true);
    setExportFileType(fileType);
    if (fileType !== 'pdf') {
      handleFormatToggle({
        target: {
          name: 'summary',
        },
      });
      return;
    }
    handleExport(fileType);
  };

  //#endregion

  // Workspaces region
  const [getOptionsWorkspaces, setGetOptionsWorkspaces] = useState(false);
  const { skipQuery: skipQueryWorkspaces } = useSkipQuery({
    removeSkip: getOptionsWorkspaces,
  });

  const {
    scrollState: workspacesState,
    handleLoadMore: handleLoadMoreWorkspaces,
    handleSearch: handleSearchWorkspaces,
    isLoading: isLoadingWorkspaces,
  } = useWorkspaces({ include_collabs: 1, fav_first: 1 }, skipQueryWorkspaces);

  const onWorkspacesSelect = (ws) => {
    const isSelected = !workspaces?.includes(`${ws?.id}`);
    let shouldIncludeSubWs = false;

    const workspacesIds = isSelected
      ? [...workspaces, `${ws?.id}`]
      : workspaces?.filter?.((workspaceId) => +workspaceId !== ws?.id);

    for (const wsId of workspacesIds) {
      const workspace = workspacesState?.itemsList?.find(
        (item) => item?.id === +wsId
      );

      if (workspace?.children?.length > 0) {
        shouldIncludeSubWs = isSelected;
        setMultiselectFilter(ws, 'workspaces', shouldIncludeSubWs);
        return;
      }
    }

    setMultiselectFilter(ws, 'workspaces', shouldIncludeSubWs);
  };

  // User Positions region
  const [getOptionsUserPositions, setGetOptionsUserPositions] = useState(false);
  const { skipQuery: skipQueryUserPositions } = useSkipQuery({
    removeSkip: getOptionsUserPositions,
  });
  const {
    scrollState: userPositionsState,
    handleLoadMore: handleLoadMoreUserPositions,
    handleSearch: handleSearchUserPositions,
    isLoading: isLoadingUserPositions,
  } = useUserPositions(skipQueryUserPositions);

  // Categories region
  const [getOptionsCategories, setGetOptionsCategories] = useState(false);
  const { skipQuery: skipQueryCategories } = useSkipQuery({
    removeSkip: getOptionsCategories,
  });

  const {
    scrollState: categoriesState,
    handleLoadMore: handleLoadMoreCategories,
    handleSearch: handleSearchCategories,
    isLoading: isLoadingCategories,
  } = useCategories({ skipQuery: skipQueryCategories });

  // Assets region
  const [getOptionsAssets, setGetOptionsAssets] = useState(false);
  const { skipQuery: skipQueryAssets } = useSkipQuery({
    removeSkip: getOptionsAssets,
  });

  const {
    scrollState: assetsState,
    handleLoadMore: handleLoadMoreAssets,
    handleSearch: handleSearchAssets,
    isFetching: isLoadingAssets,
  } = useAssetsInfinite({
    skipQuery: skipQueryAssets,
  });

  // Schedules region
  const [getOptionsSchedules, setGetOptionsSchedules] = useState(false);
  const { skipQuery: skipQuerySchedules } = useSkipQuery({
    removeSkip: getOptionsSchedules,
  });

  const {
    scrollState: schedulesState,
    handleLoadMore: handleLoadMoreSchedules,
    handleSearch: handleSearchSchedules,
    isFetching: isLoadingSchedules,
  } = useSchedules({
    skipQuery: skipQuerySchedules,
  });

  const getLabelPrefix = (key) => intl.formatMessage({ id: key }) + ': ';

  const {
    selectedAssignees,
    selectedWorkedOnBy,
    selectedReporters,
    selectedWatchers,
    handleMultiselectChange,
  } = useFilters({ setMultiselectFilter, queryParams, persistKey });

  return (
    <Body isOpen={Number(isOpen) === 1 ? true : false} disableOverflow>
      <Header onClose={handleToggle}>
        <FormattedMessage id="filters" />
      </Header>
      <ContentWrapper overflow={isFilterOverflowVisible ? 'visible' : 'auto'}>
        <HideOnLargeScreen disabled={disableHideFilterItems}>
          <StatusFilterDropdown
            onChange={setIssueStatus}
            value={issueStatus || ''}
            placeholder={intl.formatMessage({
              id: 'status',
            })}
            options={issueStatusOptions.map((issueStatus) => ({
              ...issueStatus,
              label: intl.formatMessage(issueStatus.label),
            }))}
            dropdown
          />
          <Space height="10" />

          <DropdownFilter
            labelPrefix={getLabelPrefix('priority')}
            filterName="priority"
            onChange={(value) => setSingleSelectFilter(value, 'priority_id')}
            value={issuePriority}
          />
          <Space height="10" />
        </HideOnLargeScreen>

        <DropdownFilter
          labelPrefix={getLabelPrefix('type')}
          filterName="type"
          onChange={(value) => setSingleSelectFilter(value, 'issue_type')}
          value={issueType}
        />
        <Space height="10" />
        {/*TO DO > date range is from old issue*/}
        {!hiddenFilters?.dateRange ? (
          <>
            <DateRange
              startDate={dateFrom}
              endDate={dateTo}
              onStartChange={setDateFrom}
              onEndChange={setDateTo}
              onClear={clearDateRange}
              format={dateFormats.STANDARD_DATE_FILTERS}
              placeholder={intl.formatMessage({
                id: 'add_date_range',
              })}
              doneButtonText={intl.formatMessage({
                id: 'done',
              })}
              onShowDateRangeChange={(showDateRange) => {
                setIsFilterOverflowVisible(showDateRange);
              }}
            />
            <Space height="10" />
          </>
        ) : null}

        {!hiddenFilters?.workspaces ? (
          <>
            {' '}
            <InputMultiselect
              items={filterWithSearch({
                items: !workspacesState.query.search
                  ? extractFilterValues(
                      workspacesState.itemsList,
                      'workspaces_params'
                    )
                  : workspacesState.itemsList,
                search: workspacesState.query.search,
              })}
              handleGet={() => setGetOptionsWorkspaces(true)}
              onChange={onWorkspacesSelect}
              handleLoadMore={handleLoadMoreWorkspaces}
              handleSearch={handleSearchWorkspaces}
              hasMore={workspacesState.hasMore}
              isLoading={isLoadingWorkspaces}
              selectHeaderLabel={intl.formatMessage({
                id: 'workspace',
              })}
              selected={workspaces ?? []}
              hiddenItemsIds={
                workspacesState?.query?.search
                  ? getDifference(
                      workspacesState.itemsList,
                      'workspaces',
                      persistedFilters?.parameters
                    )
                  : []
              }
              menuHeight={-1}
            />
            {/* TODO: when BE is ready this condition should be changed */}
            {workspaces.length ? (
              <>
                <Space height="10" />
                <WowDisplayFlex>
                  <Switch
                    name="include_sub_ws"
                    value={includeSubWs}
                    checked={includeSubWs}
                    onChange={(isChecked) => {
                      let workspaceIds = [];

                      const itemsList = !workspacesState.query.search
                        ? extractFilterValues(
                            workspacesState.itemsList,
                            'workspaces_params'
                          )
                        : workspacesState.itemsList;

                      const newWorkspaces = itemsList.filter(
                        (item) =>
                          workspaces?.includes(String(item.id)) &&
                          !item.parent_id
                      );

                      if (isChecked) {
                        for (const ws of newWorkspaces) {
                          workspaceIds.push(ws.id);

                          if (ws.children?.length) {
                            workspaceIds.push(
                              ...ws.children.map((child) => child.id)
                            );
                          }
                        }
                      } else {
                        workspaceIds = newWorkspaces.map((item) => item.id);
                      }

                      setIncludeSubWs(isChecked, workspaceIds);
                    }}
                    disabled={false}
                  />
                  <WowDisplayFlex>
                    <Space width="10" />
                    <WowColorizeText size="13">
                      {intl.formatMessage({
                        id: 'include_sub_ws',
                      })}
                    </WowColorizeText>
                  </WowDisplayFlex>
                </WowDisplayFlex>

                <Space height="10" />
              </>
            ) : null}
            <Space height="10" />{' '}
          </>
        ) : null}

        {!hiddenFilters?.assignees ? (
          <>
            {' '}
            <InputMultiselectUsers
              {...DEFAULT_CONFIG.assignees}
              onChange={handleMultiselectChange}
              selected={selectedAssignees}
              clearAll={clearMultiselectUsers}
            />
            <Space height="10" />{' '}
          </>
        ) : null}
        <InputMultiselectUsers
          {...DEFAULT_CONFIG.workedOnBy}
          onChange={handleMultiselectChange}
          selected={selectedWorkedOnBy}
          clearAll={clearMultiselectUsers}
        />
        {!hiddenFilters?.filterByJobPosition && (
          <>
            <Space height="10" />
            <InputMultiselect
              items={userPositionsState.itemsList}
              handleGet={() => setGetOptionsUserPositions(true)}
              onChange={(item) =>
                setMultiselectFilter(item, 'filter_by_job_positions')
              }
              handleLoadMore={handleLoadMoreUserPositions}
              handleSearch={handleSearchUserPositions}
              hasMore={userPositionsState.hasMore}
              isLoading={isLoadingUserPositions}
              selectHeaderLabel={intl.formatMessage({
                id: 'position',
              })}
              selected={filterByJobPosition ?? []}
            />
          </>
        )}
        <Space height="10" />
        <InputMultiselect
          items={extractFilterValues(
            categoriesState.itemsList,
            'categories_params'
          )}
          handleGet={() => setGetOptionsCategories(true)}
          onChange={(item) => setMultiselectFilter(item, 'categories')}
          handleLoadMore={handleLoadMoreCategories}
          handleSearch={handleSearchCategories}
          hasMore={categoriesState.hasMore}
          isLoading={isLoadingCategories}
          selectHeaderLabel={intl.formatMessage({
            id: 'category',
          })}
          selected={categories ?? []}
        />
        <Space height="10" />
        {isShowMore ? (
          <>
            <InputMultiselect
              items={extractFilterValues(
                assetsState.itemsList,
                'asset_ids_params'
              )}
              handleGet={() => setGetOptionsAssets(true)}
              onChange={(item) => setMultiselectFilter(item, 'asset_ids')}
              handleLoadMore={handleLoadMoreAssets}
              handleSearch={handleSearchAssets}
              hasMore={assetsState.hasMore}
              isLoading={isLoadingAssets}
              selectHeaderLabel={intl.formatMessage({
                id: 'asset',
              })}
              selected={assetIds ?? []}
            />
            <Space height="10" />

            <InputMultiselect
              items={extractFilterValues(
                schedulesState.itemsList,
                'schedule_id_params'
              )}
              handleGet={() => setGetOptionsSchedules(true)}
              onChange={(item) => setMultiselectFilter(item, 'schedule_id')}
              handleLoadMore={handleLoadMoreSchedules}
              handleSearch={handleSearchSchedules}
              hasMore={schedulesState.hasMore}
              isLoading={isLoadingSchedules}
              selectHeaderLabel={intl.formatMessage({
                id: 'scheduling',
              })}
              selected={scheduleIds ?? []}
            />
            <Space height="10" />
            <InputMultiselectUsers
              {...DEFAULT_CONFIG.reporters}
              onChange={handleMultiselectChange}
              selected={selectedReporters}
              clearAll={clearMultiselectUsers}
            />
            <Space height="10" />
            <InputMultiselectUsers
              {...DEFAULT_CONFIG.watchers}
              onChange={handleMultiselectChange}
              selected={selectedWatchers}
              clearAll={clearMultiselectUsers}
            />
            <Space height="10" />
          </>
        ) : null}
        <ButtonAdd
          onClick={toggleShowMore}
          icon={isShowMore ? 'icon-arrowtop-02' : 'icon-arrowdown-02'}
          color="purple"
          size={12}
        >
          <WowColorizeText color={filtersAdvancedGray}>
            <FormattedMessage id={isShowMore ? 'show_less' : 'show_more'} />
          </WowColorizeText>
        </ButtonAdd>
        <Space height="20" />

        <ButtonAdd onClick={clearAdvancedFilters} color="purple" icon={null}>
          <WowColorizeText color={newRed}>
            <FormattedMessage id="reset_all_filters" />
          </WowColorizeText>
        </ButtonAdd>
      </ContentWrapper>

      {enableExport ? (
        <Footer>
          <ExportButton
            handleExport={onExportTypeClick}
            disabled={disableExport}
            tooltipButtonText={
              disableExport ? (
                <FormattedMessage id="add_filters_to_export" />
              ) : null
            }
          />
        </Footer>
      ) : null}

      {isVisibleExportModal && (
        <ModalExportData
          isOpen={isVisibleExportModal}
          handleToggle={handleToggleExportModal}
          fileType={exportFileType}
          handleFormatToggle={handleFormatToggle}
          activeFormat={activeFormat}
          handleExport={handleExport}
          isProcessingExport={isProcessingExport}
          title={intl.formatMessage({
            id: 'in_progress',
          })}
          message={intl.formatMessage({
            id: 'file_download_in_progress',
          })}
        />
      )}
    </Body>
  );
};

export default Filters;
