export const DEFAULT_CONFIG = {
  assignees: {
    labelKey: 'assigned_to',
    entityKey: 'scheduleAssignees',
    queryKey: 'assignees',
  },
  watchers: {
    labelKey: 'watchers',
    entityKey: 'scheduleWatchers',
    queryKey: 'watchers',
  },
};

export const QUERY_KEYS = {
  assignees: ['assignees', 'assignees_companies', 'assignees_externals'],
  watchers: ['watchers', 'watchers_companies', 'watchers_externals'],
};

export const LEGACY_INTPUT_COMMON_STYLE_OVERRIDE = {
  width: '300px',
  right: 0,
  left: 'auto',
};
