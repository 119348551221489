import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { Menu } from '../menu/menu.component';
import { MenuItem } from '../menu/menu-item/menu-item.component';
import { ESC_KEY } from '../../config/config';
import { connect } from 'react-redux';
import { getFilter } from '../../redux/filter/selectors';
import { QuickFilterWrapper } from './style';
import { ThemedIcon } from '../../helpers/theme';

class QuickFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterMenuHidden: true,
    };
  }

  toggleMenu = () => {
    if (this.props.disabled) {
      return;
    }

    this.setState({ filterMenuHidden: !this.state.filterMenuHidden });
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  escFunc = (e) => {
    if (e.keyCode === ESC_KEY && !this.state.filterMenuHidden) {
      this.toggleMenu();
    }

    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(e.target) &&
      !this.state.filterMenuHidden
    ) {
      this.toggleMenu();
    }
  };

  setFilter = (option) => {
    this.setState({ selectedOption: option });
    this.toggleMenu();
    this.props.onFilterChanged(option.key);
  };

  componentDidMount = () => {
    document.addEventListener('keydown', this.escFunc, false);
    document.addEventListener('mousedown', this.escFunc, false);
  };

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.escFunc, false);
    document.removeEventListener('mousedown', this.escFunc, false);
  };

  getFilterId = () => {
    return this.props.filterId || 'statusFilter';
  };

  getCurrentFilterCaption = () => {
    if (this.props.filterParameters) {
      const option = this.props.filterOptions.find(
        (x) => x.key === this.props.filterParameters[this.getFilterId()]
      );

      if (option) {
        if (option.customDisplayLabel) {
          return option.customDisplayLabel;
        }

        return option.caption;
      }
    }

    if (this.props.value) {
      return this.props.value.caption;
    }

    return this.props.filterOptions[0].caption;
  };

  isOptionSelected = (option) =>
    this.props.filterParameters
      ? this.props.filterParameters[this.getFilterId()] === option.key
      : false;

  renderFilterOption = (option) => (
    <MenuItem
      key={option.key}
      text={option.caption}
      className={option.className}
      isFilterComponent={true}
      onChangeFilter={this.setFilter.bind(this, option)}
      isSelected={this.isOptionSelected(option)}
      mainAccent
      noThemeText
    />
  );

  componentDidUpdate = (prevProps) => {
    if (!prevProps.disabled && this.props.disabled) {
      this.setState({ filterMenuHidden: true });
    }
  };

  renderButton = () => {
    if (this.props.customButton) {
      const Button = this.props.customButton;

      return (
        <Button
          disabled={this.props.disabled}
          intl={this.props.intl}
          data-test="filter-caption"
        />
      );
    }

    return (
      <div className="filter-menu-button">
        <div className="ellipsis" data-test="filter-caption">
          {this.getCurrentFilterCaption()}
        </div>
        <ThemedIcon mainAccent className="icon icon-arrowdown-02" />
      </div>
    );
  };

  render = () => (
    <QuickFilterWrapper
      className="quick-filter"
      data-test="quick-filter-button"
      hasCustomButton={this.props.customButton}
    >
      <span
        className="menu-wrapper"
        data-test="menu-wrapper"
        ref={this.setWrapperRef}
        onClick={this.toggleMenu}
      >
        {this.renderButton()}
        {!this.state.filterMenuHidden && (
          <Menu
            {...{ menuWidth: this.props.menuWidth }}
            className={this.props.menuWidth}
          >
            {this.props.filterOptions.map(this.renderFilterOption)}
          </Menu>
        )}
      </span>
    </QuickFilterWrapper>
  );
}

const optionShape = PropTypes.shape({
  key: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  className: PropTypes.string,
});

QuickFilter.propTypes = {
  filterOptions: PropTypes.arrayOf(optionShape).isRequired,
  selectedOption: optionShape,
  filterName: PropTypes.string,
  filterId: PropTypes.string,
  onFilterChanged: PropTypes.func.isRequired,
  customButton: PropTypes.elementType,
  menuWidth: PropTypes.string,
};

QuickFilter.defaultProps = {
  customButton: null,
  menuWidth: '',
};

export const Select = injectIntl(QuickFilter);

const mapStateToProps = (state, ownProps) => ({
  filterParameters: getFilter(ownProps.filterName)(state),
});

export default injectIntl(connect(mapStateToProps)(QuickFilter));
