import styled from 'styled-components/macro';
import { outline } from '../../../config/colors';

export const FooterWrapper = styled.div`
  position: absolute;
  padding: 15px 0;
  width: calc(100% - 40px);
  bottom: 0px;

  &:before {
    content: '';
    position: absolute;
    height: 1px;
    width: calc(100% + 40px);
    background-color: ${outline};
    left: -20px;
    top: 0;
  }
`;
