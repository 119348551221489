import PropTypes from 'prop-types';
import useCollaboratorListSimpleInfinite from '../../../../views/internal/Collaborators/hooks/useCollaboratorListSimpleInfinite';
import ItemsList from '../ItemsList';
import Search from '../Search';

const Collaborators = (props) => {
  const { entityKey, queryKey, onChange, handleClose, selected } = props;

  const _entityKey = `${entityKey}Users`;
  const { data, isLoading, handleLoadMore, hasMore, handleSearch } =
    useCollaboratorListSimpleInfinite({ entityKey: _entityKey });

  const handleChange = (item) => onChange(item, `${queryKey}_companies`);

  return (
    <>
      <Search iconCallback={handleClose} handleSearch={handleSearch} />
      <ItemsList
        data={data}
        hasMore={hasMore}
        handleLoadMore={handleLoadMore}
        handleChange={handleChange}
        isLoading={isLoading}
        selected={selected}
      />
    </>
  );
};

Collaborators.propTypes = {
  entityKey: PropTypes.string.isRequired,
  queryKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
};

export default Collaborators;
