import PropTypes from 'prop-types';
import InfiniteScroll from '../../../InfiniteScroll';
import { InfoWrapper, Item, LoaderWrapper, NoItems } from './styles';
import { LoadingSpinner } from '../../../../components/loading-spinner/loading-spinner.component';
import { FormattedMessage } from 'react-intl';
import { useMemo } from 'react';
import { WowIcon, WowImage } from '../../../WowBasicElements';
import { purple500 } from '../../../../config/colors';

const ItemsList = (props) => {
  const { data, hasMore, handleLoadMore, handleChange, isLoading, selected } =
    props;

  const selectedIdsSet = useMemo(
    () => new Set(selected.map((item) => item.id)),
    [selected]
  );

  if (data.length === 0) {
    return isLoading ? (
      <LoaderWrapper>
        <LoadingSpinner small />
      </LoaderWrapper>
    ) : (
      <NoItems>
        <FormattedMessage id="no_items" />
      </NoItems>
    );
  }

  return (
    <InfiniteScroll
      dataLength={data.length}
      handleLoadMore={handleLoadMore}
      hasMore={hasMore}
      height={-1}
    >
      {data.map((item) => {
        const isSelected = selectedIdsSet.has(item.id);
        const imageAlt = `profile-image-${item.label}`;
        return (
          <Item
            onClick={() => handleChange(item)}
            key={item.id}
            selected={isSelected}
          >
            <InfoWrapper>
              {item.profile_image?.length ? (
                <WowImage src={item.profile_image} alt={imageAlt} />
              ) : null}
              {item.label}{' '}
            </InfoWrapper>
            {isSelected ? (
              <WowIcon
                className="icon icon-check"
                spaceRight="0"
                color={purple500}
              />
            ) : null}
          </Item>
        );
      })}
    </InfiniteScroll>
  );
};

ItemsList.propTypes = {
  data: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  handleLoadMore: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  selected: PropTypes.array.isRequired,
};

export default ItemsList;
