import styled, { css } from 'styled-components/macro';
import {
  darkerGray,
  filtersBlue,
  gray,
  darkGray,
} from '../../../../../config/colors';

export const FormatWrapper = styled.div`
  color: ${darkerGray};
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  padding-bottom: 10px;
`;

export const ExportOptionsWrapper = styled.div`
  display: flex;
`;

export const ExportOption = styled.button`
  height: 50px;
  background-color: ${({ active }) => (active ? filtersBlue : '#E2EAF2')};
  border-radius: 6px;
  width: 100%;
  font-size: 17px;
  text-transform: uppercase;
  color: ${({ active }) => (active ? '#fff' : darkerGray)};
  position: relative;

  :hover {
    cursor: pointer;
  }

  ${({ active }) =>
    active &&
    css`
      &:after {
        border-right: solid 8px transparent;
        border-left: solid 8px transparent;
        border-top: solid 7px ${filtersBlue};
        transform: translateX(-50%);
        position: absolute;
        z-index: -1;
        content: '';
        top: calc(100% - 1px);
        left: 50%;
        height: 0;
        width: 0;
      }
    `}
`;

export const InfoWrapper = styled.div`
  color: ${darkerGray};
  font-size: 14px;
  padding: 35px 0;
  display: flex;
  align-items: center;
`;

export const IconImportant = styled.i.attrs({
  className: 'icon icon-important-round',
})`
  color: ${gray};
  margin-right: 10px;
  font-size: 18px;
`;

export const Footer = styled.div`
  display: flex;

  button:first-of-type {
    margin-right: 15px;
  }
`;

export const Title = styled.h4`
  color: ${darkGray};
  font-size: 22px;
  margin-bottom: 1rem;
`;

export const Message = styled.span`
  color: ${darkerGray};
`;

export const MainContent = styled.div`
  padding: 1rem 4rem;
  text-align: center;
  > div {
    position: relative;
    top: 30%;
    > svg {
      position: relative;
    }
  }
`;
