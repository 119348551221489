import { useIntl } from 'react-intl';
import { useMemo, forwardRef } from 'react';
import {
  getIssueRatingsList,
  getIssueTypesList,
  getOptionsWithIntl,
  getPrioritiesList,
} from '../../views/internal/IssuesV2/Issue/utils';

import Dropdown from './Dropdown';

import { hardWhite } from '../../config/colors';

const optionsMap = {
  priority: [
    {
      value: '',
      name: 'priority.all',
      key: 'all',
    },
    ...getPrioritiesList(),
  ],
  type: getIssueTypesList(),
  rating: getIssueRatingsList(),
};

const DropdownFilter = forwardRef(
  (
    {
      filterName,
      onChange,
      labelPrefix,
      value,
      options,
      includeBackground = true,
    },
    ref
  ) => {
    const intl = useIntl();
    const _options = useMemo(
      () => options || optionsMap[filterName],
      [filterName, options]
    );

    return (
      <Dropdown
        size="responsive"
        backgroundColor={value && includeBackground && hardWhite}
        options={getOptionsWithIntl(intl, _options)}
        onChange={(value) => onChange(value.value)}
        value={value ?? ''}
        labelPrefix={labelPrefix}
      />
    );
  }
);

export default DropdownFilter;
