import styled from 'styled-components/macro';

export const OuterWrapper = styled.div`
  height: 70px;
  position: sticky;
  top: 0px;
  left: 0;
  padding-top: 78px;
  margin-top: -150px;
  z-index: 100;
`;

export const InnerWrapper = styled.div`
  width: 100%;
  background-color: #fff;
  padding-top: 20px;
  z-index: 100;
  height: 156px;
  box-shadow: 0 15px 15px -20px #333;
`;
