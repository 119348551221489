import PropTypes from 'prop-types';
import { Label, Wrapper } from './styles';
import { WowIcon, WowImage } from '../../../../WowBasicElements';

const Pill = ({ item, handleRemove }) => {
  const imageAlt = `profile-image-${item.label}`;
  const hasImage = item.profile_image?.length;
  return (
    <Wrapper hasImage={hasImage}>
      {hasImage ? <WowImage src={item.profile_image} alt={imageAlt} /> : null}
      <Label>{item.label}</Label>

      <WowIcon
        className="icon-close"
        spaceRight="0"
        spaceLeft="5"
        color="white"
        size="12"
        clickable
        onClick={() => handleRemove(item)}
      />
    </Wrapper>
  );
};

Pill.propTypes = {
  item: PropTypes.object.isRequired,
  handleRemove: PropTypes.func.isRequired,
};

export default Pill;
