export const DEFAULT_CONFIG = {
  assignees: {
    labelKey: 'assigned_to',
    entityKey: 'issueAssignees',
    queryKey: 'assignees',
  },
  workedOnBy: {
    labelKey: 'worked_on_by',
    entityKey: 'issueWorkedOnBy',
    queryKey: 'worked_on_by',
  },
  reporters: {
    labelKey: 'reporter',
    entityKey: 'issueReporters',
    queryKey: 'reporters',
  },
  watchers: {
    labelKey: 'watchers',
    entityKey: 'issueWatchers',
    queryKey: 'watchers',
  },
};

export const QUERY_KEYS = {
  assignees: ['assignees', 'assignees_companies', 'assignees_externals'],
  workedOnBy: [
    'worked_on_by',
    'worked_on_by_companies',
    'worked_on_by_externals',
  ],
  reporters: ['reporters', 'reporters_companies', 'reporters_externals'],
  watchers: ['watchers', 'watchers_companies', 'watchers_externals'],
};
