import PropTypes from 'prop-types';
import ItemsList from '../ItemsList';
import useUsersInfinite from '../../../../views/internal/users/hooks/useUsersInfinite';
import Search from '../Search';

const Users = (props) => {
  const { entityKey, queryKey, onChange, handleClose, selected } = props;
  const _entityKey = `${entityKey}Users`;

  const { data, isLoading, handleLoadMore, hasMore, handleSearch } =
    useUsersInfinite({ entityKey: _entityKey });

  const handleChange = (item) => onChange(item, queryKey);

  return (
    <>
      <Search iconCallback={handleClose} handleSearch={handleSearch} />
      <ItemsList
        data={data}
        hasMore={hasMore}
        handleLoadMore={handleLoadMore}
        handleChange={handleChange}
        isLoading={isLoading}
        selected={selected}
      />
    </>
  );
};

Users.propTypes = {
  entityKey: PropTypes.string.isRequired,
  queryKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
};

export default Users;
