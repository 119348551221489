import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { gray, darkerGray, black } from '../../../config/colors';
import { RenderIf } from '../../render-if/render-if.component';
import { themeWithDefault } from '../../../helpers/theme';

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${themeWithDefault('backColor')};
  color: ${themeWithDefault('color')};
  padding: 2px 16px;
  border-radius: 1000px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  font-size: 14px;
  margin-left: 10px;
  cursor-events: ${(props) => (props.disabled ? 'none' : 'all')} i {
    margin-right: 8px;
    height: 16px;
    font-size: 16px;
    display: flex;
    align-items: center;
  }

  .icon-arrowtop-02 {
    font-size: 20px;
    color: ${darkerGray};
  }
`;
const Icon = styled.i`
  margin-right: 10px;
`;

const ActivityButtonBase = (props) => (
  <Button
    secondaryAccent={props.secondaryAccent}
    backColor={props.disabled ? gray : props.backColor}
    color={props.disabled ? black : props.color}
    onClick={props.onClick}
    data-test={props['data-test'] || ''}
    disabled={props.disabled}
    data-testid={props['data-testid'] || null}
  >
    <RenderIf if={props.icon}>
      <Icon className={`icon ${props.icon}`} style={props.iconStyles ?? {}} />
    </RenderIf>
    {props.caption}
  </Button>
);

ActivityButtonBase.propTypes = {
  caption: PropTypes.string,
  icon: PropTypes.string,
  backColor: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

export const ActivityButton = ActivityButtonBase;
