import styled from 'styled-components/macro';
import {
  darkerGray,
  filtersAdvancedGray,
  hardWhite,
  outline,
} from '../../config/colors';
import { Menu } from '../Menu';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const MenuStyled = styled(Menu).attrs({
  className: 'dropdown-menu',
  'data-testid': 'dropdownMenu',
})`
  box-shadow: 0px 2px 10px #3b4a7440;
  top: 8px;
`;

export const MenuItem = styled.div`
  color: ${darkerGray};
  font-size: 14px;
  padding: 12px 10px 12px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e6edf4;
  height: 40px;

  &:hover {
    background-color: ${hardWhite};
    cursor: pointer;
  }
`;

export const SubMenuStyled = styled(Menu).attrs({
  className: 'dropdown-menu',
  'data-testid': 'dropdownMenu',
})`
  box-shadow: 0px 2px 10px #3b4a7440;
  top: 8px;
`;

export const SubMenuSearchWrapper = styled.div`
  padding: 5px 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${outline};
`;

export const SubMenuSearchInput = styled.input`
  flex: 1;
  height: 30px;
  outline: none;
  color: ${filtersAdvancedGray};
  width: 100%;
`;
