import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual, omit } from 'lodash-es';
import { useIntl } from 'react-intl';
import { getConfigPersistedFilters } from '../../../../../../redux/config/selectors';
import { updatePersistedFilters } from '../../../../../../redux/config/actions';

const QUERY_KEYS = {
  assignees: ['assignees', 'assignees_companies', 'assignees_externals'],
  watchers: [
    'watchers_ids',
    'watchers_companies_ids',
    'watchers_externals_ids',
  ],
};

const useSelected = ({
  setMultiselectFilter,
  updateQueryParams,
  queryParams,
  persistKey,
} = {}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const persistedFilters = useSelector(
    (state) => getConfigPersistedFilters(state, persistKey),
    isEqual
  );

  const getSelected = useCallback(
    (key) => {
      const selected = persistedFilters?.parameters?.[`${key}_params`] ?? [];
      return selected.map((item) => ({ ...item, type: key }));
    },
    [persistedFilters]
  );

  const concatenateSelected = useCallback(
    (keys) => {
      return keys.reduce((result, key) => {
        return result.concat(getSelected(key));
      }, []);
    },
    [getSelected]
  );

  const notAssignedValue = intl.formatMessage({ id: 'not_assigned' });

  const selectedAssignees = useMemo(() => {
    return [
      ...(queryParams?.assignees?.includes('0')
        ? [{ id: 0, value: notAssignedValue, label: notAssignedValue }]
        : []),
      ...concatenateSelected(QUERY_KEYS.assignees),
    ];
  }, [concatenateSelected, notAssignedValue, queryParams?.assignees]);

  const selectedWatchers = useMemo(
    () => concatenateSelected(QUERY_KEYS.watchers),
    [concatenateSelected]
  );

  const handleMultiselectChange = (item, key) => {
    const _key = key.startsWith('watchers') ? `${key}_ids` : key;
    //NOTE: do not use persist if id === 0 to match BE
    if (item.id === 0) {
      setMultiselectFilter(item, _key);
      return;
    }

    const persistedFiltersKey = `${_key}_params`;

    const mutatedArray = [
      ...(persistedFilters.parameters?.[persistedFiltersKey] || []),
    ];

    const index = mutatedArray.findIndex((_item) => +_item.id === +item.id);

    if (index !== -1) {
      mutatedArray.splice(index, 1);
    } else {
      mutatedArray.push({ ...item, type: _key });
    }
    setMultiselectFilter(item, _key);

    dispatch(
      updatePersistedFilters(
        { ...persistedFilters.parameters, [persistedFiltersKey]: mutatedArray },
        persistKey
      )
    );
  };

  const handleClearMultiselectUsers = useCallback(
    (key) => {
      const keys = [];
      const qp = {};
      for (const _key of QUERY_KEYS[key]) {
        keys.push(_key);
        keys.push(_key + '_params');
        qp[_key] = [];
      }
      updateQueryParams({
        ...queryParams,
        ...qp,
      });
      dispatch(
        updatePersistedFilters(
          omit(persistedFilters.parameters, keys),
          persistKey
        )
      );
    },
    [
      dispatch,
      persistKey,
      persistedFilters?.parameters,
      queryParams,
      updateQueryParams,
    ]
  );

  return {
    selectedAssignees,
    selectedWatchers,
    getSelected,
    handleMultiselectChange,
    handleClearMultiselectUsers,
  };
};

export default useSelected;
