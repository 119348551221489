import qs from 'query-string';
import { assetsApi } from '../service';

const assetGroupsApi = assetsApi.injectEndpoints({
  endpoints: (builder) => ({
    getGroups: builder.query({
      query: (params) => {
        const url = qs.stringifyUrl(
          { url: 'asset/group', query: params },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
        );
        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'AssetsGroups', id })),
              { type: 'AssetsGroups', id: 'LIST' },
            ]
          : [{ type: 'AssetsGroups', id: 'LIST' }],
    }),
    getGroup: builder.query({
      query: (id) => {
        const url = `asset/group/${id}`;

        return url;
      },
      providesTags: (result) =>
        result
          ? [{ type: 'AssetsGroups', id: result.id }]
          : [{ type: 'AssetsGroups', id: 'LIST' }],
    }),
    addGroup: builder.mutation({
      query(body) {
        return {
          url: 'asset/group',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'AssetsGroups', id: 'LIST' }],
    }),
    editGroup: builder.mutation({
      query(payload) {
        const { id, ...restPayload } = payload;
        return {
          url: `asset/group/${id}`,
          method: 'PUT',
          body: restPayload,
        };
      },
      invalidatesTags: [{ type: 'AssetsGroups', id: 'LIST' }],
    }),
    deleteGroup: builder.mutation({
      query(id) {
        return {
          url: `asset/group/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: 'AssetsGroups', id: 'LIST' }],
    }),
    archiveMultipleGroup: builder.mutation({
      query(payload) {
        return {
          url: `asset/group/multi_archive`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: [{ type: 'AssetsGroups', id: 'LIST' }],
    }),
    deleteMultipleGroup: builder.mutation({
      query(payload) {
        return {
          url: `asset/group/multi_delete`,
          method: 'DELETE',
          body: payload,
        };
      },
      invalidatesTags: [{ type: 'AssetsGroups', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetGroupsQuery,
  useLazyGetGroupsQuery,
  useGetGroupQuery,
  useAddGroupMutation,
  useEditGroupMutation,
  useDeleteGroupMutation,
  useArchiveMultipleGroupMutation,
  useDeleteMultipleGroupMutation,
} = assetGroupsApi;
