import styled from 'styled-components/macro';
import {
  white,
  darkerGray,
  dateGray,
  neonGreen,
  gray,
  pillGray,
  datePurple,
  boxShadow,
  outline,
  darkGray,
  gradientBlue,
  borderLightGray,
} from '../../config/colors';
import { themeColour } from '../../helpers/theme';

export const DateRangeWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 15px;
  background-color: ${outline};
  color: ${darkGray};
  height: 30px;
  width: 100%;
  font-size: 12px;

  /*  */
  .react-datepicker {
    font-family: 'nunitosans', 'Roboto', sans-serif;
    font-weight: 700;
    border: none;
    font-size: 13px;
  }

  .react-datepicker__current-month {
    color: ${darkerGray};
    font-size: 15px;
  }

  .react-datepicker__header {
    background: transparent;
    border: none;
  }

  .react-datepicker__day,
  .react-datepicker__day:hover {
    position: relative;
    background: none;
    color: ${darkGray};
  }

  .react-datepicker__day::before {
    content: '';
  }

  .react-datepicker__day--outside-month {
    color: ${gray};
  }

  .react-datepicker__day--disabled {
    color: ${borderLightGray};
  }

  .react-datepicker__day--today {
    font-weight: unset;
    position: relative;

    &::after {
      content: '\25CF';
      position: absolute;
      top: 0.7em;
      left: calc(50% - 3px);
      width: 1px;
      color: $priority-low;
    }
  }

  .react-datepicker__month-container,
  .react-datepicker,
  .react-datepicker__week {
    width: 100%;
  }

  .react-datepicker__day-names {
    padding-top: 34px;
  }

  .react-datepicker__day-name {
    color: ${darkGray};
  }

  /*  */

  .react-datepicker {
    right: 0;
    font-family: 'nunitosans';
    border: none;
    box-shadow: ${boxShadow};
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker-popper {
    min-width: 280px;
    left: -30px !important;
  }

  .react-datepicker-wrapper {
    .react-datepicker__input-container input {
      display: none;
    }
  }

  .date-range {
    padding: 10px 5px;
    top: 5px;
    max-width: 330px;
    font-family: nunitosans, Roboto, sans-serif;
    box-shadow: rgb(59 74 116 / 25%) 0px 2px 10px;
    border-radius: 8px;
    border: none;

    .react-datepicker__month-container {
      width: 100%;
      .react-datepicker__month {
        margin: 0;
      }
      .react-datepicker__day-names {
        padding-top: 1.5rem;
        padding-bottom: 0.5rem;
      }
    }

    .react-datepicker__navigation {
      top: 20px;
    }

    .react-datepicker__navigation--previous {
      left: 8%;
    }

    .react-datepicker__navigation--next {
      right: 8%;
    }

    .react-datepicker__day-name {
      width: 40px;
      margin: 0;
    }

    .react-datepicker__week {
      margin: 1rem 0;
      z-index: 1;
      position: relative;
    }

    .react-datepicker__day {
      margin: 0;
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 12px;
      color: ${dateGray};
      font-weight: bold;
      outline: none;
    }

    .react-datepicker__day--selected {
      border-radius: 50%;
      color: ${white};
    }

    .react-datepicker__day--disabled {
      color: ${gray};
    }

    .react-datepicker__day--keyboard-selected {
      background-color: ${white};
      color: ${dateGray};
    }

    .react-datepicker__day--in-range {
      background: ${datePurple};
      color: ${dateGray};
      border-radius: 0;
      margin: 0;
    }

    .react-datepicker__day--range-start,
    .react-datepicker__day--range-end,
    .react-datepicker__day:hover {
      background: ${themeColour};
      color: ${white};
      border-radius: 50%;
      position: relative;
    }

    .react-datepicker__day--in-range:hover:before {
      content: ' ';
      background: ${datePurple};
      height: 40px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
    }

    .react-datepicker__day--disabled:hover {
      color: ${gray};
      background: none;
    }

    .react-datepicker__day--range-start::before,
    .react-datepicker__day--selecting-range-start::before {
      content: ' ';
      background: ${datePurple};
      height: 40px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }

    .react-datepicker__day--range-end::before,
    .react-datepicker__day--selecting-range-end::before {
      content: ' ';
      background: ${datePurple};
      height: 40px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }

    .react-datepicker__day--in-selecting-range {
      background: ${datePurple};
      color: ${dateGray};
      border-radius: 0;
      &:hover {
        background: ${themeColour};
        color: ${white};
      }
    }

    .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--selecting-range-end {
      background: ${themeColour};
      color: ${white};
      border-radius: 50%;
      position: relative;
    }
  }
`;

export const DateSpanWrapper = styled.div`
  font-size: 12px;
  padding: 0 14px;
  width: 100%;
`;

export const DateSpan = styled.span`
  color: ${darkerGray};
  width: 100%;
`;

export const Dash = styled.span`
  color: ${darkerGray};
  font-size: 13px;
  margin: 0 5px;
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 14px;

  .icon {
    color: ${themeColour};
    cursor: pointer;
  }
`;

export const DoneButton = styled.button`
  background-color: ${neonGreen};
  font-size: 10px;
  color: ${white};
  padding: 5px 20px;
  border-radius: 5px;
  float: right;
  text-transform: uppercase;
  cursor: pointer;
  margin: 5px 15px 10px 0;
  font-weight: 600;
  outline: none;
`;

export const ClearDatesIcon = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${pillGray};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  margin-left: 10px;
  cursor: pointer;

  &:after {
    content: 'x';
    position: absolute;
    font-size: 16px;
    color: ${darkerGray};
  }
`;

export const DateWrapper = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
`;

export const AddDateRange = styled.span`
  font-size: 14px;
  line-height: 2;
  display: inline-block;
  height: 30px;
  color: ${(props) => themeColour(props) ?? gradientBlue};
  cursor: pointer;
`;
