import { useCallback, useEffect, useState } from 'react';
import qs from 'query-string';
import dayjs from 'dayjs';
import { camelCase, isEqual, isNil } from 'lodash-es';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  CALENDAR_VIEW_TYPES,
  INIT_QUERY_PARAMS,
  PERSIST_KEY,
  SUPPORTED_PARAMS,
} from '../constants';
import useWeeklyCalendar from './useWeeklyCalendar';
import useDailyCalendar from './useDailyCalendar';
import useSearchQuery from '../../../../hooks/useSearchQuery';
import useFilters from './useFilters';
import {
  formatArrayFromQueryParam,
  formatStatusFromQueryParams,
  parseInitialSearch,
  parseParameters,
  syncFiltersWithSearch,
} from '../../IssuesV2/utils';
import usePersistFilter from '../../IssuesV2/hooks/usePersistFilter';
import { getConfigPersistedFilters } from '../../../../redux/config/selectors';
import {
  ACTIVE_ISSUE_STATUSES,
  ARCHIVED_ISSUE_STATUSES,
  INIT_ADVANCED_FILTERS,
  ISSUE_STATUSES,
  SUPPORTED_PARAMS_ADVANCED_FILTER,
} from '../../IssuesV2/constants';
import { formatDateForQuery, formatStartDateFromQueryParam } from '../utils';
import {
  handleJobPositionHiddenData,
  setLastDailyDate,
  setTimelineIssueCurrentDragId,
  setTimelineIssueDraggableElementId,
  setUnscheduledIssueCurrentDragId,
} from '../slice';
import { QUERY_KEYS } from '../../IssuesV2/components/Filters/constants';
import { updatePersistedFilters } from '../../../../redux/config/actions';
import { formatMultiselectIdsWithChildren } from '../../../../utils/filters';

const useCalendar = ({
  customRTKConfig,
  calendarView = CALENDAR_VIEW_TYPES.dailyUsers,
  persistAdvancedFilters = false,
  weeklyBodyAdditionalParams = {},
} = {}) => {
  const { setFilter, updateFilter, syncFilter } = usePersistFilter({
    persist: persistAdvancedFilters,
    type: 'calendar',
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { filters, setFilters } = useFilters();
  const [currentPage, setCurrentPage] = useState(1);

  const persistedFilters = useSelector(
    (state) => getConfigPersistedFilters(state, 'calendar'),
    isEqual
  );

  const { queryParams, updateQueryParams } = useSearchQuery(
    { ...parseParameters(persistedFilters?.parameters), ...filters },
    SUPPORTED_PARAMS
  );

  // hook for waiting on persisted filters
  // to be retrieved
  // in case when there's no search inside url
  useEffect(() => {
    setFilter(persistedFilters?.parameters);
    // case when there's already search
    // update search with missing parameters if there's any
    if (persistedFilters && search) {
      setFilter(syncFiltersWithSearch(persistedFilters?.parameters, search));
      history.replace(
        `${pathname}${parseInitialSearch(
          qs.parse(search),
          search,
          INIT_QUERY_PARAMS
        )}`
      );
      return;
    }

    if (persistedFilters && !search) {
      const parsedParameters = parseParameters(persistedFilters?.parameters);
      history.replace(
        `${pathname}?${parseInitialSearch(
          persistedFilters?.parameters,
          qs.stringify(parsedParameters),
          INIT_QUERY_PARAMS
        )}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persistedFilters]);

  useEffect(() => {
    syncFilter(queryParams);
    dispatch(
      setFilters({
        ...queryParams,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]); //TO DO > check

  //#region count of active filters
  const [activeAdvancedFiltersCount, setActiveAdvancedFiltersCount] =
    useState(0);

  useEffect(() => {
    const count = Object.keys(queryParams).filter((item) =>
      Object.keys(SUPPORTED_PARAMS_ADVANCED_FILTER).includes(item)
    ).length;

    setActiveAdvancedFiltersCount(count);
  }, [queryParams]);
  //#endregion

  const clearWorkspaces = useCallback(() => {
    updateQueryParams({
      ...queryParams,
      workspaces: [],
    });
    setCurrentPage(1);
  }, [queryParams, updateQueryParams]);

  const setSingleSelectFilter = useCallback(
    (value, key) => {
      updateQueryParams({
        ...queryParams,
        [key]: value,
      });
      setCurrentPage(1);
    },
    [queryParams, updateQueryParams]
  );

  const setMultiselectFilter = useCallback(
    (item, key, shouldIncludeSubWs) => {
      updateFilter(key, item, shouldIncludeSubWs);
      const selectedIds = formatArrayFromQueryParam(queryParams, key);

      const newIds = formatMultiselectIdsWithChildren(
        selectedIds,
        item,
        shouldIncludeSubWs
      );

      updateQueryParams({
        ...queryParams,
        [key]: newIds,
        ...(shouldIncludeSubWs ? { include_sub_ws: '1' } : {}),
      });
      setCurrentPage(1);
    },
    [queryParams, updateFilter, updateQueryParams]
  );

  const setIncludeSubWs = useCallback(
    (isChecked, workspaceIds) => {
      const newQueryParams = {
        ...queryParams,
        include_sub_ws: isChecked ? '1' : '0',
        page: 1,
        ...(workspaceIds ? { workspaces: workspaceIds } : {}),
      };

      if (!isChecked) delete newQueryParams.include_sub_ws;

      updateQueryParams(newQueryParams);
    },
    [queryParams, updateQueryParams]
  );

  const setIssueStatus = useCallback(
    (values = '', parent) => {
      if (isNil(parent)) {
        switch (values) {
          case ISSUE_STATUSES.ALL_ACTIVE:
            updateQueryParams({
              ...queryParams,
              'issue_state[active]':
                queryParams?.['issue_state[active]'] === '' ||
                queryParams?.['issue_state[active]']?.split(',').length !==
                  Object.keys(ACTIVE_ISSUE_STATUSES).length
                  ? ACTIVE_ISSUE_STATUSES.join(',')
                  : null,
              issue_state: null,
            });
            setCurrentPage(1);
            break;
          case ISSUE_STATUSES.ARCHIVED:
            updateQueryParams({
              ...queryParams,
              'issue_state[archived]':
                queryParams?.['issue_state[archived]'] === '' ||
                queryParams?.['issue_state[archived]']?.split(',').length !==
                  Object.keys(ARCHIVED_ISSUE_STATUSES).length
                  ? ARCHIVED_ISSUE_STATUSES.join(',')
                  : null,
            });
            setCurrentPage(1);
            break;
          case ISSUE_STATUSES.ALL:
            updateQueryParams({
              ...queryParams,
              issue_state: queryParams?.issue_state ? null : values,
              'issue_state[active]': null,
              'issue_state[archived]': null,
            });
            setCurrentPage(1);
            break;
          default:
            break;
        }
        return;
      }

      updateQueryParams({
        ...queryParams,
        issue_state: null,
        [`issue_state[${
          parent === ISSUE_STATUSES.ALL_ACTIVE ? 'active' : 'archived'
        }]`]:
          values
            ?.split(',')
            ?.filter?.((value) => value !== parent)
            .join(',') ?? [],
      });
      setCurrentPage(1);
    },
    [queryParams, updateQueryParams]
  );

  const clearAdvancedFilters = useCallback(() => {
    updateQueryParams({
      ...INIT_QUERY_PARAMS,
      ...INIT_ADVANCED_FILTERS,
    });
  }, [updateQueryParams]);

  const clearMultiselectUsers = useCallback(
    (key) => {
      const params = {};
      const mutatedPersistedFiltersParams = { ...persistedFilters?.parameters };
      for (const _key of QUERY_KEYS[camelCase(key)]) {
        params[_key] = INIT_ADVANCED_FILTERS[_key];
        delete mutatedPersistedFiltersParams[`${_key}_params`];
      }

      updateQueryParams({ ...queryParams, ...params });
      dispatch(
        updatePersistedFilters(mutatedPersistedFiltersParams, PERSIST_KEY)
      );
    },
    [dispatch, persistedFilters?.parameters, queryParams, updateQueryParams]
  );

  const [skipGetCalendarDailyUsers, setSkipGetCalendarDailyUsers] =
    useState(true);

  const [
    skipGetCalendarDailyWorkspacesData,
    setSkipGetCalendarDailyWorkspacesData,
  ] = useState(true);

  const [skipGetCalendarWeeklyUsers, setSkipGetCalendarWeeklyUsers] =
    useState(true);

  const [skipGetCalendarWeeklyWorkspaces, setSkipGetCalendarWeeklyWorkspaces] =
    useState(true);

  const [skipGetCalendarWeeklyData, setSkipGetCalendarWeeklyData] =
    useState(true);

  const {
    calendarWeeklyData,
    isFetchingCalendarWeekly,
    isUninitializedCalendarWeekly,
    handleDeleteWeeklyDataItem,
    handleUpdateWeeklyDataItem,
    handleNewWeeklyItemSetup,
    calendarWeeklyUsersLastPage,
    calendarWeeklyUsersCurrentPage,
    calendarWeeklyWorkspacesLastPage,
    calendarWeeklyWorkspacesCurrentPage,
    calendarWeeklyUsersFetchData,
    isFetchingCalendarWeeklyUsers,
    isUninitializedCalendarWeeklyUsers,
    calendarWeeklyWorkspacesFetchData,
    isFetchingCalendarWeeklyWorkspaces,
    isUninitializedCalendarWeeklyWorkspaces,
    calendarWeeklyUsersData,
    calendarWeeklyWorkspacesData,
  } = useWeeklyCalendar({
    customRTKConfig,
    queryParams,
    filters,
    persistedFilters,
    currentPage,
    skipGetCalendarWeeklyWorkspaces,
    skipGetCalendarWeeklyUsers,
    skipGetCalendarWeeklyData,
    weeklyBodyAdditionalParams,
  });

  const {
    calendarDailyUsersData,
    calendarDailyWorkspacesData,
    calendarDailyWorkspacesCalendarData,
    handleDeleteDailyDataItem,
    handleUpdateDailyDataItem,
    handleNewDailyItemSetup,
    calendarDailyUsersCalendarData,
    calendarDailyUsersLastPage,
    isFetchingCalendarDailyUsers,
    isUninitializedCalendarDailyUsers,
    handleMoveToCalendarDailyUsersData,
    calendarDailyWorkspacesLastPage,
    isFetchingCalendarDailyWorkspaces,
    isUninitializedCalendarDailyWorkspaces,
    handleMoveToCalendarDailyWorkspacesData,
    isLoadingFirstTimeCalendarDailyUsers,
    isLoadingFirstTimeCalendarDailyWorkspaces,
  } = useDailyCalendar({
    customRTKConfig,
    currentPage,
    queryParams,
    filters,
    persistedFilters,
    skipGetCalendarDailyUsers,
    skipGetCalendarDailyWorkspacesData,
  });

  const [allowToSetSkipOnWeeklyData, setAllowToSetSkipOnWeeklyData] =
    useState(false);

  useEffect(() => {
    setCurrentPage(1);
    if (calendarView === CALENDAR_VIEW_TYPES.dailyUsers) {
      setSkipGetCalendarDailyUsers(false);
    } else if (calendarView === CALENDAR_VIEW_TYPES.dailyWorkspaces) {
      setSkipGetCalendarDailyWorkspacesData(false);
    } else if (calendarView === CALENDAR_VIEW_TYPES.weeklyWorkspaces) {
      setSkipGetCalendarWeeklyWorkspaces(false);
    } else if (calendarView === CALENDAR_VIEW_TYPES.weeklyUsers) {
      setSkipGetCalendarWeeklyUsers(false);
    }
  }, [calendarView]);

  const updateSameStartDateEndDate = useCallback(
    (date) => {
      updateQueryParams({
        ...queryParams,
        start_date: date,
        end_date: date,
      });
      setCurrentPage(1);
    },
    [updateQueryParams, queryParams]
  );

  const updateStartDateEndDate = useCallback(
    (start_date, end_date) => {
      updateQueryParams({
        ...queryParams,
        start_date,
        end_date,
      });
    },
    [updateQueryParams, queryParams]
  );

  const updateStartDate = useCallback(
    (date) => {
      updateQueryParams({
        ...queryParams,
        start_date: date,
      });
    },
    [updateQueryParams, queryParams]
  );

  const updateEndDate = useCallback(
    (date) => {
      updateQueryParams({
        ...queryParams,
        start_date: date,
      });
    },
    [updateQueryParams, queryParams]
  );

  useEffect(() => {
    if (
      (calendarView === CALENDAR_VIEW_TYPES.weeklyUsers ||
        calendarView === CALENDAR_VIEW_TYPES.weeklyWorkspaces) &&
      !allowToSetSkipOnWeeklyData
    ) {
      const date = queryParams?.start_date ?? new Date();
      const startWeek = new Date(dayjs(date).startOf('isoWeek'));
      const endWeek = new Date(dayjs(date).startOf('isoWeek'));
      endWeek.setDate(endWeek.getDate() + 7);
      updateStartDateEndDate(
        formatDateForQuery(startWeek),
        formatDateForQuery(endWeek)
      );
      window.setTimeout(() => setAllowToSetSkipOnWeeklyData(true), 0);
    }
  }, [
    queryParams?.start_date,
    setAllowToSetSkipOnWeeklyData,
    allowToSetSkipOnWeeklyData,
    calendarView,
    updateStartDateEndDate,
  ]);

  useEffect(() => {
    if (
      calendarView === CALENDAR_VIEW_TYPES.dailyUsers ||
      calendarView === CALENDAR_VIEW_TYPES.dailyWorkspaces
    ) {
      dispatch(setLastDailyDate(queryParams?.start_date));
    }
  }, [calendarView, dispatch, queryParams?.start_date]);

  const updateCalendarSearchValue = useCallback(
    (value) => {
      updateQueryParams({
        ...queryParams,
        calendar_search: value,
      });
      setCurrentPage(1);
    },
    [updateQueryParams, queryParams]
  );

  const loadMore = useCallback(() => {
    updateQueryParams({ ...queryParams });
    setCurrentPage((prevState) => prevState + 1);
  }, [updateQueryParams, queryParams]);

  const handleDeleteDataItem = useCallback(
    (id) => {
      if (!id) return;
      dispatch(setUnscheduledIssueCurrentDragId(null));
      dispatch(setTimelineIssueCurrentDragId(null));
      dispatch(setTimelineIssueDraggableElementId(null));
      if (
        calendarView === CALENDAR_VIEW_TYPES.dailyUsers ||
        calendarView === CALENDAR_VIEW_TYPES.dailyWorkspaces
      ) {
        handleDeleteDailyDataItem({ calendarView, id });
      } else if (
        calendarView === CALENDAR_VIEW_TYPES.weeklyUsers ||
        calendarView === CALENDAR_VIEW_TYPES.weeklyWorkspaces
      ) {
        handleDeleteWeeklyDataItem({ id });
      }
    },
    [
      calendarView,
      dispatch,
      handleDeleteDailyDataItem,
      handleDeleteWeeklyDataItem,
    ]
  );

  const handleUpdateDataItem = useCallback(
    (data, additionalParams = {}) => {
      if (!data) return;
      dispatch(setUnscheduledIssueCurrentDragId(null));
      dispatch(setTimelineIssueCurrentDragId(null));
      if (
        !data.assignee_id &&
        data.id &&
        calendarView !== CALENDAR_VIEW_TYPES.weeklyUsers &&
        calendarView !== CALENDAR_VIEW_TYPES.weeklyWorkspaces &&
        calendarView !== CALENDAR_VIEW_TYPES.dailyWorkspaces
      )
        return handleDeleteDataItem(data.id);
      if (
        calendarView === CALENDAR_VIEW_TYPES.dailyUsers ||
        calendarView === CALENDAR_VIEW_TYPES.dailyWorkspaces
      ) {
        handleUpdateDailyDataItem({ data, additionalParams, calendarView });
      } else if (
        calendarView === CALENDAR_VIEW_TYPES.weeklyUsers ||
        calendarView === CALENDAR_VIEW_TYPES.weeklyWorkspaces
      ) {
        handleUpdateWeeklyDataItem({ data, additionalParams });
      }
    },
    [
      calendarView,
      dispatch,
      handleDeleteDataItem,
      handleUpdateDailyDataItem,
      handleUpdateWeeklyDataItem,
    ]
  );

  const handleNewItemSetup = useCallback(
    (data = {}, additionalParams = {}) => {
      const { id, timelineIndex, timelineId, activeView } = data;
      if (
        !id ||
        ((activeView === CALENDAR_VIEW_TYPES.weeklyUsers ||
          activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces) &&
          typeof timelineIndex === 'undefined') ||
        ((activeView === CALENDAR_VIEW_TYPES.dailyUsers ||
          activeView === CALENDAR_VIEW_TYPES.dailyWorkspaces) &&
          !timelineId)
      )
        return;
      if (
        activeView === CALENDAR_VIEW_TYPES.dailyUsers ||
        activeView === CALENDAR_VIEW_TYPES.dailyWorkspaces
      ) {
        handleNewDailyItemSetup({ data, additionalParams });
      } else if (
        activeView === CALENDAR_VIEW_TYPES.weeklyUsers ||
        activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces
      ) {
        handleNewWeeklyItemSetup({ data, additionalParams });
      }
    },
    [handleNewDailyItemSetup, handleNewWeeklyItemSetup]
  );

  useEffect(() => {
    const filterByJobPosition = formatArrayFromQueryParam(
      queryParams,
      'filter_by_job_positions'
    );
    if (filterByJobPosition?.length) {
      dispatch(handleJobPositionHiddenData(filterByJobPosition));
    }
  }, [calendarDailyUsersData, calendarWeeklyUsersData, queryParams, dispatch]);

  return {
    queryParams,

    handleUpdateDataItem,
    handleDeleteDataItem,
    handleNewItemSetup,

    calendarDailyUsersData,
    calendarDailyUsersCalendarData,
    calendarDailyUsersLastPage,
    isFetchingCalendarDailyUsers,
    isUninitializedCalendarDailyUsers,
    isLoadingFirstTimeCalendarDailyUsers,
    handleMoveToCalendarDailyUsersData,

    calendarDailyWorkspacesData,
    calendarDailyWorkspacesCalendarData,
    calendarDailyWorkspacesLastPage,
    isFetchingCalendarDailyWorkspaces,
    isUninitializedCalendarDailyWorkspaces,
    isLoadingFirstTimeCalendarDailyWorkspaces,
    handleMoveToCalendarDailyWorkspacesData,

    calendarWeeklyData,
    isFetchingCalendarWeekly,
    isUninitializedCalendarWeekly,
    calendarWeeklyUsersLastPage,
    calendarWeeklyUsersCurrentPage,
    calendarWeeklyWorkspacesLastPage,
    calendarWeeklyWorkspacesCurrentPage,
    calendarWeeklyUsersFetchData,
    isFetchingCalendarWeeklyUsers,
    isUninitializedCalendarWeeklyUsers,
    calendarWeeklyWorkspacesFetchData,
    isFetchingCalendarWeeklyWorkspaces,
    isUninitializedCalendarWeeklyWorkspaces,
    calendarWeeklyUsersData,
    calendarWeeklyWorkspacesData,
    setSkipGetCalendarWeeklyData,

    setIssueStatus,
    updateSameStartDateEndDate,
    updateStartDateEndDate,
    updateStartDate,
    updateEndDate,
    updateCalendarSearchValue,
    loadMore,
    setSingleSelectFilter,
    setMultiselectFilter,
    clearAdvancedFilters,
    clearWorkspaces,
    activeAdvancedFiltersCount,
    issuePriority: queryParams.priority_id ? +queryParams.priority_id : '',
    issueType: queryParams.issue_type ?? '',
    issueStatus: formatStatusFromQueryParams(queryParams),
    issueRating: queryParams.rating ? +queryParams.rating : '',
    assignees: formatArrayFromQueryParam(queryParams, 'assignees'),
    workspaces: formatArrayFromQueryParam(queryParams, 'workspaces'),
    workedOnBy: formatArrayFromQueryParam(queryParams, 'worked_on_by'),
    reporters: formatArrayFromQueryParam(queryParams, 'reporters'),
    assetIds: formatArrayFromQueryParam(queryParams, 'asset_ids'),
    scheduleIds: formatArrayFromQueryParam(queryParams, 'schedule_id'),
    watchers: formatArrayFromQueryParam(queryParams, 'watchers'),
    categories: formatArrayFromQueryParam(queryParams, 'categories'),
    calendar_search: formatArrayFromQueryParam(queryParams, 'calendar_search'),
    filterByJobPosition: formatArrayFromQueryParam(
      queryParams,
      'filter_by_job_positions'
    ),
    page: currentPage,
    startDate: formatStartDateFromQueryParam(queryParams?.start_date),
    clearMultiselectUsers,
    setIncludeSubWs,
    includeSubWs: queryParams.include_sub_ws === '1',
  };
};

export default useCalendar;
