import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ADD_TO_WORKSPACE_INFO_MESSAGE_KEYS, MODE } from '../constants';
import { useAddUsersToWorkspacesMutation } from '../../../views/internal/users/service';

const useAddToWorkspace = ({
  localSelectedItems,
  workspaceName,
  workspaceIds,
  handleAddSuccessCallback,
}) => {
  const intl = useIntl();

  const itemsNotInWorkspace = useMemo(() => {
    return localSelectedItems.filter((item) => item.isNotInWorkspace);
  }, [localSelectedItems]);

  const formattedInfoMessages = useMemo(() => {
    const isMultiSelected = itemsNotInWorkspace.length > 1;
    const keys = isMultiSelected
      ? ADD_TO_WORKSPACE_INFO_MESSAGE_KEYS[MODE.multi]
      : ADD_TO_WORKSPACE_INFO_MESSAGE_KEYS[MODE.single];

    const messages = {
      message: intl
        .formatMessage({ id: keys.messageKey })
        .replace(':workspace_name', workspaceName),
      secondaryMessage: isMultiSelected
        ? intl.formatMessage({ id: keys.secondaryMessageKey })
        : intl
            .formatMessage({ id: keys.secondaryMessageKey })
            .replace(':user_name', itemsNotInWorkspace[0]?.full_name),
    };

    return messages;
  }, [intl, itemsNotInWorkspace, workspaceName]);

  const [addUsersToWorkspace, { isLoading }] =
    useAddUsersToWorkspacesMutation();

  const handleSave = async () => {
    const response = await addUsersToWorkspace({
      workspace_ids: workspaceIds,
      user_ids: itemsNotInWorkspace.map((item) => item.id),
      role: 'user',
    });

    if (response?.data?.success) {
      const newItems = localSelectedItems.map((item) => ({
        ...item,
        isNotInWorkspace: false,
      }));
      handleAddSuccessCallback(newItems);
    }
  };

  return {
    itemsNotInWorkspace,
    formattedInfoMessages,
    handleSave,
    isLoading,
  };
};

export default useAddToWorkspace;
