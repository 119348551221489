import { useCallback } from 'react';
import { useLazyGetUsersFilterMixCollabsQuery } from '../service';
import { useInfiniteScrollData } from '../../../../hooks/useInfinite';

const useUsersFilterMixCollabsInfinite = ({
  skip,
  customParams,
  preventReset,
  preferCacheValue,
  entityKey,
} = {}) => {
  const [getUsersFilterMixCollabs] = useLazyGetUsersFilterMixCollabsQuery();

  const getItems = useCallback(
    async (params) => {
      if (skip) return;

      const result = await getUsersFilterMixCollabs(
        params,
        preferCacheValue
      ).unwrap();

      const { companies, externals, members } = result?.data ?? {};
      const formattedCompanies = companies.map((item) => ({
        ...item,
        type: 'companies',
      }));
      const formattedExternals = externals.map((item) => ({
        ...item,
        type: 'externals',
      }));
      const formatedData = members
        .concat(formattedCompanies)
        .concat(formattedExternals);

      return { ...result, data: formatedData };
    },
    [skip, getUsersFilterMixCollabs, preferCacheValue]
  );

  const {
    data,
    hasMore,
    isLoading,
    handleLoadMore,
    total,
    handleSearch,
    handleDelete,
    query,
    handleUpdateQueryParams,
  } = useInfiniteScrollData({
    entityKey,
    getItems,
    skip,
    preventReset,
    customParams,
  });

  return {
    data,
    isLoading,
    handleLoadMore,
    hasMore,
    handleUpdateQueryParams,
    query,
    total,
    handleDelete,
    handleSearch,
  };
};

export default useUsersFilterMixCollabsInfinite;
