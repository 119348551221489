import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';

import { getIsAdvancedFilterOpen as schedulingGetIsAdvancedFilterOpen } from '../../scheduling/selectors';
import { getAdvancedFilterState as equipmentGetAdvancedFilterState } from '../../Equipment/selectors';
import { getIsAdvancedFilterOpen as assetsGetIsAdvancedFilterOpen } from '../../Assets/selectors';
import { ADVANCED_FILTER_OPEN_KEY } from '../../IssuesV2/constants';
import { selectListViewValue } from '../../IssuesV2/selectors';
import useWindowSizeDebounced from '../../../../hooks/useWindowSizeDebounced';
import { internalRoutePaths } from '../../../../config/config';
import { getIsAdvancedFilterOpen as calendarGetIsAdvancedFilterOpen } from '../../Calendar/selectors';

const useLargeScreenWidth = () => {
  const location = useLocation();

  const schedulingIsAdvancedFilterOpen = useSelector(
    schedulingGetIsAdvancedFilterOpen
  );
  const equipmentIsAdvancedFilterOpen = useSelector(
    equipmentGetAdvancedFilterState
  );
  const assetsIsAdvancedFilterOpen = useSelector(assetsGetIsAdvancedFilterOpen);
  const issuesIsAdvancedFilterOpen = useSelector(
    selectListViewValue(ADVANCED_FILTER_OPEN_KEY)
  );
  const calendarIsAdvancedFilterOpen = useSelector(
    calendarGetIsAdvancedFilterOpen
  );
  const { width } = useWindowSizeDebounced();
  let largeScreenWidth;
  if (width > 2300 && location?.pathname) {
    if (
      (location.pathname === internalRoutePaths.ISSUES &&
        issuesIsAdvancedFilterOpen) ||
      (location.pathname === internalRoutePaths.SCHEDULING &&
        schedulingIsAdvancedFilterOpen) ||
      (location.pathname === internalRoutePaths.EQUIPMENT &&
        Number(equipmentIsAdvancedFilterOpen)) ||
      (location.pathname === internalRoutePaths.ASSETS &&
        Number(assetsIsAdvancedFilterOpen)) ||
      (location.pathname === internalRoutePaths.CALENDAR &&
        Number(calendarIsAdvancedFilterOpen))
    )
      largeScreenWidth = 2200;
  }

  return { largeScreenWidth };
};

export default useLargeScreenWidth;
