/* eslint-disable camelcase */
import axios from 'axios';
import keys from 'lodash.keys';
import { config } from '../config/apiConfig';
import { checkForUnauthorisedAccess, handleUnauthorisedAccess } from './helper';
export const authHeader = (authToken) => ({
  Authorization: `Bearer ${authToken}`,
});

export const apiCall = (
  method,
  url,
  content_type,
  data = false,
  headers = {}
) => {
  const base = data === false ? {} : { body: JSON.stringify(data) };
  // temporary hack
  const acceptHeader =
    content_type === config.CONTENT_TYPES.APPLICATION_JSON
      ? { Accept: 'application/json' }
      : {};

  return fetch(url, {
    ...base,
    method: method,
    headers: {
      ...acceptHeader,
      'Content-Type': content_type,
      ...headers,
    },
  })
    .then(checkForUnauthorisedAccess)
    .catch(handleUnauthorisedAccess);
};

export const apiCallBuilder =
  (method, headers) =>
  (url, data = {}, additionalHeaders = {}) => {
    let additionalOptions = {};

    if (keys(data).length) {
      additionalOptions = {
        ...additionalOptions,
        body: JSON.stringify(data),
      };
    }

    return fetch(url, {
      ...additionalOptions,
      method: method,
      headers: {
        ...headers,
        ...additionalHeaders,
      },
    })
      .then(checkForUnauthorisedAccess)
      .catch(handleUnauthorisedAccess);
  };

export const getJSONWithoutCache = apiCallBuilder('POST', {
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
});

export const getJSONWithoutCacheGetReq = apiCallBuilder('GET', {
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
});

export const authReq = (url, httpReqType, content_type, authToken, data) =>
  apiCall(httpReqType, url, content_type, data, authHeader(authToken)).then(
    (_) => _.json()
  );

export const authReq2 = (url, httpReqType, content_type, authToken, data) =>
  apiCall(httpReqType, url, content_type, data, authHeader(authToken));

export const downloadFile = (url, authToken, body) =>
  getJSONWithoutCache(url, body, authHeader(authToken));

export const downloadFileGetReq = (url, authToken, body) =>
  getJSONWithoutCacheGetReq(url, body, authHeader(authToken));

export const downloadFileWithCancelation = (
  url,
  authToken,
  body,
  cancelationSource
) =>
  axios.post(url, body, {
    cancelToken: cancelationSource.token,
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      ...authHeader(authToken),
    },
  });

export const jsonAuthReq = (url, httpReqType, content_type, authToken, data) =>
  apiCall(httpReqType, url, content_type, data, {
    ...authHeader(authToken),
    Accept: content_type,
  }).then((_) => _.json());

export const authGET = (url, content_type, authToken) =>
  apiCall('GET', url, content_type, false, authHeader(authToken)).then((_) =>
    _.json()
  );

export const notificationApi = (url, httpReqType, authToken) =>
  apiCall(
    httpReqType,
    url,
    'application/json',
    false,
    authHeader(authToken)
  ).then((_) => _.json());

export const authPOST = (url, content_type, data, authToken) =>
  apiCall('POST', url, content_type, data, authHeader(authToken)).then((_) =>
    _.json()
  );
export const authPUT = (url, content_type, data, authToken) =>
  apiCall('PUT', url, content_type, data, authHeader(authToken)).then((_) =>
    _.json()
  );

export const apiCallWithFormData = (
  method,
  url,
  data,
  authToken,
  content_type
) => {
  return fetch(url, {
    method: method,
    body: data,
    headers: {
      Accept: content_type || 'application/json',
      ...authHeader(authToken),
    },
  })
    .then(checkForUnauthorisedAccess)
    .catch(handleUnauthorisedAccess);
};
