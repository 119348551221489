/* eslint-disable react/prop-types */
import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';

import { App } from './views/external/app/app';
import MobileAppRedirect from './views/external/MobileAppRedirect';
import { connect } from 'react-redux';
import { loggedIn } from './redux/auth/selectors';
import { history } from './redux/store';

import MainView from './views/internal/main/main.view';

// internationalization
import { IntlProvider } from 'react-intl';
import { getUserLanguage } from './redux/user/selectors';
import { getTranslation } from './redux/entities/selectors';

import de from './lang/de.json';
import en from './lang/en.json';
import pl from './lang/pl.json';
import tr from './lang/tr.json';
import hr from './lang/hr.json';
import uk from './lang/uk.json';
import ru from './lang/ru.json';
import it from './lang/it.json';
import hu from './lang/hu.json';
import ro from './lang/ro.json';
import ErrorBoundary from './views/external/ErrorBoundary';

const messages = {
  de,
  en,
  pl,
  tr,
  hr,
  uk,
  ru,
  it,
  hu,
  ro,
};

const DefaultPath = lazy(() =>
  import('./views/external/default-path/DefaultPath')
);
const ServerErrorView = lazy(() => import('./views/external/status/500.view'));
const ThankYou = lazy(() => import('./views/external/ThankYou'));
const PublicIssue = lazy(() =>
  import('./views/external/publicIssue/PublicIssue')
);
const ReportIssue = lazy(() =>
  import('./views/external/workspace/ReportIssue')
);
const ExtendHandout = lazy(() =>
  import('./views/external/equipment/ExtendHandout')
);
const EquipmentReportFound = lazy(() =>
  import('./views/external/equipment/ReportFound')
);
const ForgotPassword = lazy(() =>
  import('./views/external/forgot-password/forgot-password.view')
);
const ForgotPasswordRequest = lazy(() =>
  import('./views/external/forgot-password/request-forgot-password')
);

const LoginView = lazy(() => import('./views/external/LoginScreen'));
const AssetsReportIssue = lazy(() =>
  import('./views/external/assets/ReportIssue')
);
const NotFoundView = lazy(() => import('./views/external/status/404.view'));
const PublicIssueWatchersUnsubscribe = lazy(() =>
  import('./views/external/publicIssue/PublicIssueWatchersUnsubscribe')
);

const SignUp = lazy(() => import('./views/external/auth/SignUp'));
const Collaborator = lazy(() => import('./views/external/Collaborator'));
const LoginWithToken = lazy(() => import('./views/external/LoginWithToken'));

export const PublicRoutes = function (state) {
  return (
    <App history={history} theme={state.theme}>
      <IntlProvider
        locale={state.lang}
        messages={messages[state.lang]}
        onError={(err) => {
          if (err.code === 'MISSING_TRANSLATION') {
            // eslint-disable-next-line no-console
            console.warn('Missing translation', err.message);
            return;
          }
          throw err;
        }}
      >
        <ErrorBoundary>
          <Router>
            <MobileAppRedirect>
              <Suspense fallback={<></>}>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={(props) => <DefaultPath {...state} {...props} />}
                  />

                  <Route exact path="/404" component={NotFoundView} />
                  <Route exact path="/500" component={ServerErrorView} />
                  <Route path="/change-password" component={ForgotPassword} />
                  <Route
                    exact
                    path="/forgot-password"
                    component={ForgotPasswordRequest}
                  />

                  <Route
                    exact
                    path="/auth/tokenlogin"
                    component={LoginWithToken}
                  />

                  <Route exact path="/signup" component={SignUp} />

                  <PrivateRoute
                    path="/dashboard"
                    component={MainView}
                    loggedIn={state.loggedIn}
                  />

                  <Route exact path="/login" component={LoginView} />
                  <Route
                    path="/public/issue/watchers-unsubscribe"
                    component={PublicIssueWatchersUnsubscribe}
                  />
                  <PublicIssueRoute
                    path="/public/issue"
                    component={PublicIssue}
                    loggedIn={state.loggedIn}
                  />
                  <Route path="/public/report" component={ReportIssue} />
                  <Route
                    path="/public/equipment/extend_handout"
                    component={ExtendHandout}
                  />
                  <Route
                    path="/public/equipment/group/extend_handout"
                    render={(props) => <ExtendHandout {...props} isGroup />}
                  />
                  <Route
                    path="/public/equipment/report"
                    component={EquipmentReportFound}
                  />
                  <Route
                    path="/public/equipment/group/found"
                    render={(props) => (
                      <EquipmentReportFound {...props} isGroup />
                    )}
                  />
                  <Route
                    path="/public/asset/report"
                    component={AssetsReportIssue}
                  />
                  <Route
                    path="/public/collaboration_action"
                    component={Collaborator}
                  />
                  <Route path="/public/thank-you" component={ThankYou} />
                  <Route
                    path="*"
                    component={(props) => <DefaultPath {...state} {...props} />}
                  />
                </Switch>
              </Suspense>
            </MobileAppRedirect>
          </Router>
        </ErrorBoundary>
        {/* </ConnectedRouter> */}
      </IntlProvider>
    </App>
  );
};

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
}

function PublicIssueRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );
}

const mapStateToProps = (state) => ({
  loggedIn: loggedIn(state),
  lang: getUserLanguage(state),
  messages: getTranslation(state),
});

export const Routes = connect(mapStateToProps)(PublicRoutes);
