import styled from 'styled-components/macro';
import { darkGray, white } from '../../../config/colors';

export const Wrapper = styled.div`
  display: flex;
  min-width: 30px;
  min-height: 20px;
  background-color: ${darkGray};
  color: ${white};
  border-radius: 20px;
  flex-direction: row;
  align-items: center;
  padding: 2px 4px 2px 10px;
  font-size: 14px;
  margin: 5px 0px 5px 5px;
  flex: 0 0 auto;
  max-width: 90%;
`;
export const InfoWrapper = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  max-width: inherit;

  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 8px;
    margin-left: -8px;
  }
`;
export const Text = styled.span`
  word-break: break-all;
  height: 100%;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  justify-content: ${(props) => props.justify || 'center'};
`;
export const RemoveIcon = styled.span`
  cursor: pointer;
  font-size: 12px;
`;
