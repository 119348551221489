import { INIT_QUERY_PARAMS as INIT_ISSUE_LIST_QUERY_PARAMS } from '../../views/internal/IssuesV2/constants';
import { INIT_QUERY_PARAMS as INIT_CALENDAR_QUERY_PARAMS } from '../../views/internal/Calendar/constants';

export const defaultTheme = {
  main: ['#5B47FF', '#9243FD'],
  mainAccent: '#9243FD',
  secondary: '#25C465',
  secondaryAccent: '#5B47FF',
};
export const initState = {
  theme: defaultTheme,
  flowConfig: {},
  client: {},
  config: {},
};

export const INIT_FILTER_STATE = {
  issue: {
    query_string: '',
    parameters: INIT_ISSUE_LIST_QUERY_PARAMS ?? {},
  },
  calendar: {
    query_string: '',
    parameters: INIT_CALENDAR_QUERY_PARAMS ?? {},
  },
};
