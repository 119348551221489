import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Space from '../../../../../components_new/Space';
import Switch from '../../../../../components_new/Switch/Switch';
import { getCurrentTimeMarkerPosition } from '../DailyTimeline/utils';
import StickyHeader, {
  AdvancedFilterButton,
  HeaderSection,
  Title,
  Sort,
} from '../../../../../components_new/StickyHeader';
import MainDropdown from '../../../../../components_new/StickyHeader/MainDropdown';
import {
  WowDisplayFlex,
  WowIcon,
} from '../../../../../components_new/WowBasicElements';
import { DropdownMultipleWithSearch } from '../../../../../components_new/Dropdown';
import {
  darkGray,
  purple500,
  white,
  filtersAdvancedGray,
} from '../../../../../config/colors';
import { ButtonWithIcon, TodayButton } from '../../styles';
import {
  MAIN_MENU_OPTIONS,
  YEARLY_LIMIT_MIN_YEAR,
  YEARLY_SORT_OPTIONS,
} from './constants';
import { MONTH_KEYS } from '../../../../../config/config';
import { CALENDAR_VIEW_TYPES, YEARLY_QUERY_PARAMS_MAP } from '../../constants';
import {
  getActiveView,
  getIsAdvancedFilterOpen,
  getIsUnscheduledMenuOpen,
} from '../../selectors';
import {
  updateAdvancedFiltersMenuOpen,
  updateUnscheduledMenuOpen,
} from '../../slice';
import useWindowSizeDebounced from '../../../../../hooks/useWindowSizeDebounced';
import CalendarDatePicker from './components/CalendarDatePicker';
import useWeeklyConfig from '../WeeklyTimeline/hooks/useConfig';

import useYearly from '../Yearly/hooks/useYearly';

import { useWorkspaces } from '../../../Equipment/hooks';
import useSkipQuery from '../../../../../hooks/useSkipQuery';
import { filterWithSearch } from '../../../../../utils/utility';

import dict from '../../../../../config/multilang';

const Header = (props) => {
  const {
    setActiveView = () => {},
    currentTime = new Date(),
    setCurrentTime = () => {},
    activeAdvancedFiltersCount,
    clearAdvancedFilter,
    setShowWeekend = () => {},
    showWeekend,
    weeklyDays = [],
  } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const history = useHistory();

  const activeView = useSelector(getActiveView);

  const {
    sortBy,
    setSortBy,
    sortDirection,
    toggleSortDirection,
    workspaces,
    setMultiselectFilter,
    clearWorkspaces,
  } = useYearly({
    preventReset: true,
    skip: true,
    persistAdvancedFilters: false,
  });

  const isUnscheduledMenuOpen = useSelector(getIsUnscheduledMenuOpen);
  const handleUnscheduledMenuOpen = () =>
    dispatch(updateUnscheduledMenuOpen(!isUnscheduledMenuOpen));

  const [showCalendarDatePicker, setShowCalendarDatePicker] = useState();

  const isAdvancedFilterOpen = useSelector(getIsAdvancedFilterOpen);
  const handleAdvancedFilterToggle = () =>
    dispatch(updateAdvancedFiltersMenuOpen(1 - Number(isAdvancedFilterOpen)));

  const { width } = useWindowSizeDebounced();
  const isQHDResolution = width >= 2560;
  const shouldShowOnlyOneSidebar = width < 1600 && isAdvancedFilterOpen === 1;
  const isUnscheduledMenuActive =
    isUnscheduledMenuOpen &&
    ((!shouldShowOnlyOneSidebar && !isQHDResolution) ||
      (isQHDResolution && isAdvancedFilterOpen === 0));

  const { eachHourHeightPxBox } = useWeeklyConfig();

  // #region workspaces
  const [getOptionsWorkspaces, setGetOptionsWorkspaces] = useState(false);
  const { skipQuery: skipQueryWorkspaces } = useSkipQuery({
    removeSkip: getOptionsWorkspaces,
  });

  const customWsParams = useMemo(
    () => ({
      include_collabs: 1,
      fav_first: 1,
    }),
    []
  );

  const {
    scrollState: workspacesDropdownState,
    handleLoadMore: handleLoadMoreWorkspaces,
    handleSearch: handleSearchWorkspaces,
    isLoading: isLoadingWorkspaces,
  } = useWorkspaces(customWsParams, skipQueryWorkspaces);
  // #endregion

  const showTime = useCallback(() => {
    if (
      activeView === CALENDAR_VIEW_TYPES.dailyUsers ||
      activeView === CALENDAR_VIEW_TYPES.dailyWorkspaces
    )
      return `${intl.formatMessage({
        id: MONTH_KEYS?.[currentTime.getMonth() + 1] ?? ' ',
      })} ${currentTime.getDate()}, ${currentTime.getFullYear()}`;

    if (
      activeView === CALENDAR_VIEW_TYPES.weeklyUsers ||
      activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces
    ) {
      return `${intl
        .formatMessage({
          id: MONTH_KEYS?.[currentTime.getMonth() + 1] ?? ' ',
        })
        .slice(0, 3)} 
        ${new Date(weeklyDays[0]).getDate()}
        ${' - '}
        ${
          new Date(weeklyDays[weeklyDays.length - 1]).getMonth() !==
          currentTime.getMonth()
            ? intl
                .formatMessage({
                  id:
                    MONTH_KEYS?.[
                      new Date(weeklyDays[weeklyDays.length - 1]).getMonth() + 1
                    ] ?? ' ',
                })
                .slice(0, 3)
            : ''
        }
        ${new Date(weeklyDays[weeklyDays.length - 1]).getDate()}${', '}
        ${currentTime.getFullYear()}`;
    }

    if (activeView === CALENDAR_VIEW_TYPES.yearly)
      return `${currentTime.getFullYear()}`;
  }, [activeView, currentTime, intl, weeklyDays]);

  const handleArrows = useCallback(
    (next = false) => {
      const prevState = currentTime;
      let newDate = new Date(
        prevState.getFullYear(),
        prevState.getMonth(),
        prevState.getDate(),
        prevState.getHours(),
        prevState.getMinutes(),
        prevState.getSeconds()
      );
      if (
        activeView === CALENDAR_VIEW_TYPES.dailyUsers ||
        activeView === CALENDAR_VIEW_TYPES.dailyWorkspaces
      ) {
        if (next) newDate.setDate(newDate.getDate() + 1);
        else newDate.setDate(newDate.getDate() - 1);
      } else if (
        activeView === CALENDAR_VIEW_TYPES.weeklyUsers ||
        activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces
      ) {
        const startWeek = new Date(dayjs(newDate).startOf('isoWeek'));
        if (next) startWeek.setDate(startWeek.getDate() + 7);
        else startWeek.setDate(startWeek.getDate() - 7);
        newDate = startWeek;
      } else if (activeView === CALENDAR_VIEW_TYPES.yearly) {
        if (next)
          newDate = new Date(dayjs(newDate).add(1, 'year').startOf('year'));
        else {
          newDate = new Date(
            dayjs(newDate).subtract(1, 'year').startOf('year')
          );
        }
      }
      setCurrentTime(newDate);
    },
    [activeView, setCurrentTime, currentTime]
  );

  const handleCalendarPick = (time = new Date()) => {
    if (
      activeView === CALENDAR_VIEW_TYPES.dailyUsers ||
      activeView === CALENDAR_VIEW_TYPES.dailyWorkspaces
    ) {
      setCurrentTime(new Date(time));
    } else if (
      activeView === CALENDAR_VIEW_TYPES.weeklyUsers ||
      activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces
    ) {
      const startWeek = new Date(dayjs(time).startOf('isoWeek'));
      setCurrentTime(new Date(startWeek));
    }
  };

  const setToday = () => {
    if (
      activeView === CALENDAR_VIEW_TYPES.weeklyUsers ||
      activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces
    ) {
      const prevState = new Date();
      let newDate = new Date(
        prevState.getFullYear(),
        prevState.getMonth(),
        prevState.getDate(),
        prevState.getHours(),
        prevState.getMinutes(),
        prevState.getSeconds()
      );
      const startWeek = new Date(dayjs(newDate).startOf('isoWeek'));
      setCurrentTime(startWeek);
      window.setTimeout(() => {
        const contentElem = document.getElementsByClassName('content')?.[0];
        if (contentElem) {
          contentElem.scrollTop =
            getCurrentTimeMarkerPosition({
              eachHourHeightPxBox,
            }) - 50;
        }
      }, 0);
    } else {
      setCurrentTime(new Date());
    }
  };

  const showPrevArrow = useMemo(() => {
    if (activeView === CALENDAR_VIEW_TYPES.yearly) {
      return currentTime.getFullYear() > YEARLY_LIMIT_MIN_YEAR;
    }

    return true;
  }, [activeView, currentTime]);

  const showNextArrow = useMemo(() => {
    const currentYear = new Date().getFullYear();

    if (activeView === CALENDAR_VIEW_TYPES.yearly) {
      return currentTime.getFullYear() < currentYear + 1;
    }

    return true;
  }, [activeView, currentTime]);

  return (
    <StickyHeader>
      <HeaderSection style={{ position: 'relative' }}>
        {showCalendarDatePicker && (
          <CalendarDatePicker
            activeView={activeView}
            setShowCalendarDatePicker={setShowCalendarDatePicker}
            handleCalendarPick={handleCalendarPick}
            currentTime={currentTime}
            highlightDates={weeklyDays}
          />
        )}
        <Title
          style={{ marginLeft: 0 }}
          isClickable={activeView !== CALENDAR_VIEW_TYPES.yearly}
          data-testid="calendarTimeShowTitle"
          onClick={() => {
            if (activeView === CALENDAR_VIEW_TYPES.yearly) return;
            setShowCalendarDatePicker((prevState) => !prevState);
          }}
        >
          {showTime()}
        </Title>
        <WowDisplayFlex>
          {showPrevArrow ? (
            <WowIcon
              className="icon icon-arrowleft-02"
              size="25"
              color={darkGray}
              display="flex"
              spaceRight="0"
              clickable
              onClick={() => handleArrows()}
              data-testid="arrowPrev"
            />
          ) : null}
          {showNextArrow ? (
            <WowIcon
              className="icon icon-arrowright-02"
              size="25"
              color={darkGray}
              display="flex"
              clickable
              onClick={() => handleArrows(true)}
              data-testid="arrowNext"
            />
          ) : null}
        </WowDisplayFlex>
        <TodayButton onClick={setToday}>
          <FormattedMessage id="today" />
        </TodayButton>

        {activeView === CALENDAR_VIEW_TYPES.yearly && (
          <DropdownMultipleWithSearch
            options={filterWithSearch({
              items: workspacesDropdownState.itemsList,
              search: workspacesDropdownState.query.search,
            })}
            handleLoadMore={handleLoadMoreWorkspaces}
            hasMore={workspacesDropdownState.hasMore}
            handleSearch={handleSearchWorkspaces}
            handleChange={(item) => {
              setMultiselectFilter(
                item,
                YEARLY_QUERY_PARAMS_MAP.workspaces,
                true
              );
            }}
            handleClear={clearWorkspaces}
            isLoading={isLoadingWorkspaces}
            selected={workspaces}
            messageKey="workspace"
            handleGet={() => setGetOptionsWorkspaces(true)}
            withCache
          />
        )}

        {(activeView === CALENDAR_VIEW_TYPES.weeklyUsers ||
          activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces) && (
          <>
            <div style={{ marginTop: 5, marginLeft: 15, marginRight: 10 }}>
              <span style={{ position: 'relative', top: 2 }}>
                <Switch
                  value={showWeekend}
                  onChange={() => setShowWeekend((prevState) => !prevState)}
                  data-testid="weeklyCalendarShowWeekendSwitch"
                />
              </span>
              <div
                style={{
                  position: 'relative',
                  bottom: 4,
                  color: filtersAdvancedGray,
                  fontSize: '0.9em',
                  paddingLeft: 8,
                  display: 'inline-block',
                }}
              >
                {intl.formatMessage({ id: 'show_weekend' })}
              </div>
            </div>
          </>
        )}
        <AdvancedFilterButton
          onClick={handleAdvancedFilterToggle}
          advancedFiltersCount={activeAdvancedFiltersCount}
          onClearAdvancedFilters={(e) => {
            e.stopPropagation();
            clearAdvancedFilter();
          }}
          labelKey="filters"
        />
      </HeaderSection>
      <HeaderSection>
        {/* sort options for yearly schedules */}
        {activeView === CALENDAR_VIEW_TYPES.yearly && (
          <Sort
            onChange={setSortBy}
            onOrderChange={toggleSortDirection}
            options={YEARLY_SORT_OPTIONS.map((sortOption) => ({
              value: sortOption.value,
              label: intl.formatMessage({
                id: sortOption.label,
                defaultMessage: dict[sortOption.label],
              }),
            }))}
            order={sortDirection}
            selectedOption={sortBy}
          />
        )}

        {activeView !== CALENDAR_VIEW_TYPES.yearly && (
          <ButtonWithIcon
            onClick={handleUnscheduledMenuOpen}
            isActive={isUnscheduledMenuActive}
          >
            <WowIcon
              className="icon icon-plus"
              color={isUnscheduledMenuActive ? white : purple500}
              spaceRight="5"
            />
            <FormattedMessage id="unscheduled" />
          </ButtonWithIcon>
        )}

        <Space width="10" />
        <MainDropdown
          options={MAIN_MENU_OPTIONS}
          value={activeView}
          onChange={(value) => {
            // hack used to set search param as empty
            // so search for yearly params is populated from config
            if (value === CALENDAR_VIEW_TYPES.yearly) {
              history.replace(pathname);
            }
            setActiveView(value);
          }}
          position="right"
        />
      </HeaderSection>
    </StickyHeader>
  );
};

export default Header;
