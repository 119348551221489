import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import dayjs from 'dayjs';

import Header from '../Header';
import Filters from '../Filters';
import YearlyTimeline from '../YearlyTimeline';

import {
  Content,
  Page,
  Wrapper,
} from '../../../../../components_new/WowListPage';
import { CalendarWrapper } from '../../styles';

import useYearly from './hooks/useYearly';
import { LoadingSpinner } from '../../../../../components/loading-spinner/loading-spinner.component';
import EmptyScreen from '../../../../../components_new/EmptyScreen';
import { getIsAdvancedFilterOpen } from '../../selectors';

const StyledCalendarWrapper = styled(CalendarWrapper)`
  padding: 0px;
  position: sticky;
  top: 0px;
  min-height: auto;
`;

const Yearly = (props) => {
  const { setActiveView = () => {} } = props;

  const {
    data,
    isLoading,
    handleSetCurrentTime,
    year,
    activeAdvancedFiltersCount,
    filterProps,
    query,
  } = useYearly();

  const contentElement = document.querySelector('.content');
  const isAdvancedFilterOpen = useSelector(getIsAdvancedFilterOpen);

  //NOTE > due to UI bug horizontal scroll appears so we move it at the end so filter is shown
  //TO DO > this will be addressed with new filters UI/UX
  useEffect(() => {
    if (contentElement) {
      contentElement.scrollIntoView({
        inline: 'end',
      });
    }
  }, [contentElement, isAdvancedFilterOpen]);

  const galleryPopupPresented = useSelector(
    (state) => state?.app?.toJS()?.galleryPopupPresented
  );

  return (
    <Page>
      <Content data-testid="yearlyCalendarWrapper">
        <Header
          setActiveView={setActiveView}
          currentTime={dayjs().year(+year).toDate()}
          setCurrentTime={handleSetCurrentTime}
          activeAdvancedFiltersCount={activeAdvancedFiltersCount}
          clearAdvancedFilter={filterProps.handleClearAllFilters}
        />
        <Wrapper style={{ zIndex: galleryPopupPresented ? 200 : null }}>
          {isLoading && query?.page === 1 ? (
            <LoadingSpinner />
          ) : data.length ? (
            <StyledCalendarWrapper>
              <YearlyTimeline />
            </StyledCalendarWrapper>
          ) : (
            <EmptyScreen
              mode={activeAdvancedFiltersCount > 0 ? 'filtered' : 'all'}
              keys={{ title: 'what_is_schedules', text: 'no_schedules_text' }}
              icon="icon-scheduling"
            />
          )}
        </Wrapper>
      </Content>
      <Filters />
    </Page>
  );
};

export default Yearly;
