import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';
import { FormattedMessage, useIntl } from 'react-intl';
import Selected from './components/Selected';
import useKeyPress from '../../hooks/useKeyPress';
import useVisible from '../../hooks/useVisible';
import {
  MenuItem,
  MenuStyled,
  SubMenuSearchInput,
  SubMenuSearchWrapper,
  SubMenuStyled,
  Wrapper,
} from './styles';
import { MenuItems } from '../Menu';
import {
  ICON_ARROW_RIGHT_CONFIG,
  MENU_ITEMS,
  POPER_CONFIG,
  VIEWS,
  VIEW_TYPES,
} from './constants';
import { WowIcon } from '../WowBasicElements';
import useUsersFilterMixCollabsInfinite from '../../views/internal/users/hooks/useUsersFilterMixCollabsInfinite';
import ItemsList from './components/ItemsList';
import { useDebounce } from '../../hooks/useDebounce';
import { useMemo } from 'react';
import { formatSelectedItems } from './utils';
import { useSelector } from 'react-redux';
import { getUser } from '../../redux/user/selectors';

const InputMultiselectUsers = (props) => {
  const {
    labelKey,
    onMenuToggle = () => {},
    entityKey, //entity key used for redux
    queryKey, //query key is used as query param
    onChange,
    selected,
    clearAll,
  } = props;

  const intl = useIntl();
  const loggedInUser = useSelector(getUser);
  const isCollabEnabled = loggedInUser?.client?.settings?.include_collab;
  const [_selected, _setSelected] = useState([]);

  useEffect(() => {
    _setSelected(formatSelectedItems(selected));
  }, [selected]);

  const menuItems = useMemo(() => {
    return isCollabEnabled
      ? MENU_ITEMS
      : MENU_ITEMS.filter((item) => item.id !== 2);
  }, [isCollabEnabled]);

  const [referenceElement, setReferenceElement] = useState(null);
  const { ref: refUseVisible, isVisible, setIsVisible } = useVisible(false);
  const [popperElement, setPopperElement] = useState(null);

  const { attributes } = usePopper(
    referenceElement,
    popperElement,
    POPER_CONFIG
  );

  const handleMenuToggle = () => {
    setIsVisible((prevState) => !prevState);
    onMenuToggle(!isVisible);
  };

  const {
    ref: refUseVisibleSubMenu,
    isVisible: isVisibleSubMenu,
    setIsVisible: setIsVisibleSubMenu,
  } = useVisible(false);

  const [activeSubMenu, setActiveSubMenu] = useState();

  const handleOpenSubMenu = (subMenu) => {
    setIsVisible(false);
    setIsVisibleSubMenu(true);
    setActiveSubMenu(subMenu);
  };

  const handleCloseSubMenu = () => {
    setIsVisible(true);
    setIsVisibleSubMenu(false);
  };

  useKeyPress('Escape', () => {
    setIsVisible(false);
    setIsVisibleSubMenu();
  });

  //#region search
  const _entityKey = `${entityKey}FilterMixCollabs`;

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 400);

  const customParams = useMemo(() => {
    return { ...(debouncedSearch.length ? { search: debouncedSearch } : {}) };
  }, [debouncedSearch]);

  const { data, isLoading, handleLoadMore, hasMore, handleSearch } =
    useUsersFilterMixCollabsInfinite({
      entityKey: _entityKey,
      customParams,
      skip: !Object.keys(customParams).length,
    });

  useEffect(() => {
    handleSearch(debouncedSearch);
  }, [debouncedSearch, handleSearch]);

  const handleChange = (item) => {
    const _queryKey = item.type ? `${queryKey}_${item.type}` : queryKey;
    onChange(item, _queryKey);
  };

  return (
    <>
      <Wrapper ref={refUseVisible}>
        <Selected
          labelKey={labelKey}
          setReferenceElement={setReferenceElement}
          handleMenuToggle={handleMenuToggle}
          items={_selected}
          onChange={onChange}
          queryKey={queryKey}
          clearAll={clearAll}
        />
        <Wrapper>
          {isVisible ? (
            <MenuStyled ref={setPopperElement} {...attributes.popper}>
              <SubMenuSearchWrapper>
                <SubMenuSearchInput
                  placeholder={intl.formatMessage({ id: 'search' })}
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  autoFocus
                />
              </SubMenuSearchWrapper>

              {search?.length ? (
                <ItemsList
                  data={data}
                  hasMore={hasMore}
                  handleLoadMore={handleLoadMore}
                  handleChange={handleChange}
                  isLoading={isLoading}
                  selected={_selected}
                />
              ) : (
                <MenuItems>
                  {menuItems.map((item) => (
                    <MenuItem
                      key={item.id}
                      onClick={() => handleOpenSubMenu(VIEW_TYPES[item.type])}
                    >
                      <FormattedMessage id={item.labelKey} />
                      <WowIcon {...ICON_ARROW_RIGHT_CONFIG} />
                    </MenuItem>
                  ))}
                  {queryKey.includes('assignees') ? (
                    <MenuItem
                      onClick={() =>
                        handleChange({
                          id: 0,
                          value: intl.formatMessage({ id: 'not_assigned' }),
                        })
                      }
                    >
                      <FormattedMessage id="not_assigned" />
                    </MenuItem>
                  ) : null}
                </MenuItems>
              )}
            </MenuStyled>
          ) : null}
        </Wrapper>
      </Wrapper>
      <Wrapper ref={refUseVisibleSubMenu}>
        {isVisibleSubMenu ? (
          <SubMenuStyled>
            {VIEWS[activeSubMenu]({
              entityKey,
              queryKey,
              onChange,
              handleClose: handleCloseSubMenu,
              selected: _selected,
            })}
          </SubMenuStyled>
        ) : null}
      </Wrapper>
    </>
  );
};

InputMultiselectUsers.propTypes = {
  labelKey: PropTypes.string.isRequired,
  onMenuToggle: PropTypes.func,
  entityKey: PropTypes.string.isRequired,
  queryKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  clearAll: PropTypes.func.isRequired,
};

export default InputMultiselectUsers;
