import { useCallback, useEffect, useMemo, useState } from 'react';
import qs from 'query-string';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { useInfiniteLazyQuery } from '../../../../../../hooks/useInfinite';
import { useLazyGetCalendarYearlySchedulesQuery } from '../../../service';
import { useSelector } from 'react-redux';
import { getConfigPersistedFilters } from '../../../../../../redux/config/selectors';
import { isEqual, isNil, omit, isEmpty } from 'lodash-es';
import useSearchQuery from '../../../../../../hooks/useSearchQuery';
import {
  parseInitialSearch,
  parseParameters,
  formatArrayFromQueryParam,
  syncFiltersWithSearch,
} from '../../../../IssuesV2/utils';
import {
  INIT_QUERY_PARAMS_YEARLY,
  SUPPORTED_PARAMS_ADVANCED_FILTER_YEARLY,
  SUPPORTED_PARAMS_YEARLY,
  YEARLY_QUERY_PARAMS_MAP,
} from '../../../constants';

import usePersistYearlyCalendarFilter from './usePersistYearlyCalendarFilter';
import useSelected from './useSelected';
import { updatePersistedFilters } from '../../../../../../redux/config/actions';

import { MULTPLE_SELECT_FILTERS, INIT_ADVANCED_FILTERS } from '../constants';
import { formatMultiselectIdsWithChildren } from '../../../../../../utils/filters';

const useYearly = ({
  skip,
  preventReset,
  persistAdvancedFilters = false,
} = {}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname, search } = useLocation();

  const { setFilter, updateFilter, syncFilter } =
    usePersistYearlyCalendarFilter({
      persist: persistAdvancedFilters,
    });

  const persistedFilters = useSelector(
    (state) => getConfigPersistedFilters(state, 'yearly-calendar'),
    isEqual
  );

  const { queryParams, updateQueryParams } = useSearchQuery(
    { ...parseParameters(persistedFilters?.parameters) },
    SUPPORTED_PARAMS_YEARLY,
    !persistAdvancedFilters
  );

  useEffect(() => {
    if (!persistAdvancedFilters) return;
    syncFilter(queryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, persistAdvancedFilters]); //TO DO > check

  useEffect(() => {
    if (!persistAdvancedFilters) return;
    setFilter(persistedFilters?.parameters);
    // case when there's already search
    // update search with missing parameters if there's any
    if (persistedFilters && search) {
      setFilter(syncFiltersWithSearch(persistedFilters?.parameters, search));
      history.replace(
        `${pathname}${parseInitialSearch(
          qs.parse(search),
          search,
          INIT_QUERY_PARAMS_YEARLY
        )}`
      );
      return;
    }

    if (persistedFilters && !search) {
      const parsedParameters = parseParameters(persistedFilters?.parameters);
      history.replace(
        `${pathname}?${parseInitialSearch(
          persistedFilters?.parameters,
          qs.stringify(parsedParameters),
          INIT_QUERY_PARAMS_YEARLY
        )}`
      );
      return;
    }
    // when initialized persisted filters
    if (isNil(persistedFilters) || isEmpty(persistedFilters)) {
      history.replace(
        `${pathname}?${parseInitialSearch(
          {},
          search,
          INIT_QUERY_PARAMS_YEARLY
        )}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persistedFilters]);

  //#region count of active filters
  const [activeAdvancedFiltersCount, setActiveAdvancedFiltersCount] =
    useState(0);

  useEffect(() => {
    const count = Object.keys(queryParams).filter((item) =>
      Object.keys(SUPPORTED_PARAMS_ADVANCED_FILTER_YEARLY).includes(item)
    ).length;

    setActiveAdvancedFiltersCount(count);
  }, [queryParams]);
  //#endregion

  const _skip = useMemo(() => {
    return (
      skip ||
      Object.keys(queryParams).length <
        Object.keys(INIT_QUERY_PARAMS_YEARLY).length
    );
  }, [queryParams, skip]);

  const _customParams = useMemo(() => {
    return { ...queryParams, paginate_by: 50 };
  }, [queryParams]);

  const { data, isLoading, handleLoadMore, hasMore, query, handleEdit } =
    useInfiniteLazyQuery({
      skip: _skip,
      entityKey: 'calendarYearlySchedules',
      useLazyQuery: useLazyGetCalendarYearlySchedulesQuery,
      preferCacheValue: true,
      customParams: _customParams,
      idKey: 'scheduleId',
      preventReset: preventReset,
    });

  //TO DO => do we actully need start_end date or to use `year` as param that is reauired
  const handleSetCurrentTime = useCallback(
    (date) => {
      updateQueryParams({
        ...queryParams,
        year: dayjs(date).year(),
      });
    },
    [updateQueryParams, queryParams]
  );

  const setSortBy = useCallback(
    (value) => {
      updateQueryParams({
        ...queryParams,
        page: 1,
        sort_by: value,
      });
    },
    [queryParams, updateQueryParams]
  );

  const toggleSortDirection = useCallback(() => {
    const nextSortDirection =
      queryParams.sort_direction === 'asc' ? 'desc' : 'asc';

    updateQueryParams({
      ...queryParams,
      page: 1,
      sort_direction: nextSortDirection,
    });
  }, [queryParams, updateQueryParams]);

  const setIncludeSubWs = useCallback(
    (isChecked, workspaceItems) => {
      const newQueryParams = {
        ...queryParams,
        include_sub_ws: isChecked ? '1' : '0',
        ...(workspaceItems
          ? { workspaces_ids: workspaceItems.map((item) => item.id) }
          : {}),
        page: 1,
      };

      if (!isChecked) delete newQueryParams.include_sub_ws;

      updateQueryParams(newQueryParams);
    },
    [queryParams, updateQueryParams]
  );

  const setMultiselectFilter = useCallback(
    (item, key, shouldIncludeSubWs = false) => {
      updateFilter(key, item, shouldIncludeSubWs);
      const selectedIds = formatArrayFromQueryParam(queryParams, key);

      const newIds = formatMultiselectIdsWithChildren(
        selectedIds,
        item,
        shouldIncludeSubWs
      );
      updateQueryParams({
        ...queryParams,
        [key]: newIds,
        ...(shouldIncludeSubWs ? { include_sub_ws: '1' } : {}),
      });
    },
    [queryParams, updateFilter, updateQueryParams]
  );

  const setSingleSelectFilter = useCallback(
    (value, key) => {
      updateQueryParams({
        ...queryParams,
        [key]: value,
      });
    },
    [queryParams, updateQueryParams]
  );

  const clearWorkspaces = useCallback(() => {
    updateQueryParams({
      ...queryParams,
      workspaces_ids: [],
    });
  }, [queryParams, updateQueryParams]);

  const {
    selectedAssignees,
    selectedWatchers,
    handleMultiselectChange,
    handleClearMultiselectUsers,
  } = useSelected({
    setMultiselectFilter,
    queryParams,
    persistKey: 'yearly-calendar',
    updateQueryParams,
  });

  const persistedFilterData = useMemo(
    () =>
      Object.keys(MULTPLE_SELECT_FILTERS).reduce((acc, key) => {
        acc[key] = (
          persistedFilters?.parameters?.[
            `${MULTPLE_SELECT_FILTERS[key]}_params`
          ] || []
        ).map((item) => ({
          ...item,
          title: item.value,
        }));
        return acc;
      }, {}),
    [persistedFilters]
  );

  const clearAdvancedFilters = useCallback(() => {
    updateQueryParams({
      ...INIT_QUERY_PARAMS_YEARLY,
      ...omit(INIT_ADVANCED_FILTERS, 'status'),
    });
    dispatch(updatePersistedFilters({}, 'yearly-calendar'));
  }, [dispatch, updateQueryParams]);

  return {
    data,
    isLoading,
    handleLoadMore,
    handleEdit,
    hasMore,
    query,
    handleSetCurrentTime,
    year: queryParams?.year ?? `${dayjs().year()}`,

    sortBy: queryParams.sort_by,
    setSortBy,

    sortDirection: queryParams.sort_direction,
    toggleSortDirection,

    clearWorkspaces,
    workspaces: formatArrayFromQueryParam(queryParams, 'workspaces_ids'),
    setMultiselectFilter,

    activeAdvancedFiltersCount,

    filterProps: {
      status: queryParams?.status ?? 'all',
      priorityId: queryParams?.priority_id ?? '',
      assignees: selectedAssignees,
      replacements: formatArrayFromQueryParam(queryParams, 'replacements_ids'),
      watchers: selectedWatchers,
      categories: formatArrayFromQueryParam(queryParams, 'categories_ids'),
      workspaces: formatArrayFromQueryParam(queryParams, 'workspaces_ids'),
      assets: formatArrayFromQueryParam(queryParams, 'assets_ids'),
      asset_system_ids: formatArrayFromQueryParam(
        queryParams,
        'asset_system_ids'
      ),
      asset_group_ids: formatArrayFromQueryParam(
        queryParams,
        'asset_group_ids'
      ),
      equipment: formatArrayFromQueryParam(queryParams, 'equipments_ids'),
      includeSubWs: queryParams.include_sub_ws === '1',

      persistedFilters: persistedFilterData,

      handleMultipleInputFilterChange: (type) => (value) =>
        setMultiselectFilter(value, YEARLY_QUERY_PARAMS_MAP[type] ?? type),
      handleFilterChange: (type) => (value) =>
        setSingleSelectFilter(value, type),
      handleWorkspacesFilterChange: (value, shouldIncludeSubWs) =>
        setMultiselectFilter(
          value,
          YEARLY_QUERY_PARAMS_MAP.workspaces,
          shouldIncludeSubWs
        ),
      toggleSubworkspace: setIncludeSubWs,
      handleClearAllFilters: clearAdvancedFilters,

      handleMultiselectChange: handleMultiselectChange,
      handleClearMultiselectUsers,
    },
  };
};

export default useYearly;
