import styled from 'styled-components/macro';
import {
  darkerGray,
  hardWhite,
  outline,
  purple500,
} from '../../../../config/colors';
import { WowDisplayFlex } from '../../../WowBasicElements';

export const Item = styled.div`
  padding: 12px 14px;
  color: ${({ selected }) => (selected ? purple500 : darkerGray)};
  line-height: 1;
  border-bottom: 1px solid ${outline};
  font-size: 14px;
  word-break: break-all;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
    background-color: ${hardWhite};
  }
`;

export const InfoWrapper = styled(WowDisplayFlex)`
  img {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    margin-right: 8px;
  }
`;

export const LoaderWrapper = styled(WowDisplayFlex)`
  > div {
    position: relative;
    transform: translate(-50%, 0px);
    height: 40px;
  }
`;

export const NoItems = styled.div`
  text-align: center;
  font-size: 14px;
  padding: 15px;
`;
