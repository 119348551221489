import styled from 'styled-components/macro';

import {
  filtersAdvancedGray,
  purple500,
  outline,
  darkGray,
} from '../../../../../config/colors';

export const OuterWrapper = styled.div`
  width: 240px;
  position: relative;
`;

export const Wrapper = styled.div`
  position: fixed;
  padding: 20px;
  height: 100vh;
  width: 240px;
  background-color: #fff;
  border: 1px solid ${outline};
  border-top: 0;
`;

export const Title = styled.div`
  font-size: 13px;
  color: ${filtersAdvancedGray};
  margin-bottom: 20px;
`;

export const SearchWrapper = styled.div`
  margin-bottom: 20px;
`;

export const FilterActionButtonsWrapper = styled.div`
  margin-bottom: 15px;
  display: flex;
`;

export const FilterActionButton = styled.div`
  color: ${purple500};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
`;

export const ListItemWrapper = styled.div`
  padding: 5px;
  /* height: 35px; */
  display: flex;
  justify-content: space-between;
`;

export const ListItemLeft = styled.div`
  display: flex;
`;

export const ListItemRight = styled.div`
  font-size: 17px;
  position: relative;
  bottom: 1px;
`;

export const ListItemText = styled.div`
  font-size: 16px;
  position: relative;
  bottom: 1px;
  color: ${darkGray};
`;

export const CheckboxWrapper = styled.div`
  margin-right: 9px;
  position: relative;
  left: -3px;
`;

export const ListItemsWrapper = styled.div``;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
