import styled from 'styled-components/macro';
import * as Tabs from '@radix-ui/react-tabs';
import {
  filtersAdvancedGray,
  outline,
  gradientBlue,
  darkerGray,
  hardWhite,
} from '../../../../../config/colors';

export const OuterWrapper = styled.div`
  width: 240px;
  position: relative;
`;

export const Wrapper = styled.div`
  padding: 20px;
  height: 100%;
  width: 240px;
  background-color: #fff;
  border: 1px solid ${outline};
  border-top: 0;
  position: fixed;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-size: 13px;
  color: ${filtersAdvancedGray};
  margin-bottom: 20px;
`;

export const TabsTrigger = styled(Tabs.Trigger)`
  position: relative;
  text-transform: uppercase;
  font-size: 12px;
  border-bottom: 2px solid ${filtersAdvancedGray};
  color: ${filtersAdvancedGray};
  cursor: pointer;
  flex: 1;
  text-align: center;
  padding-bottom: 5px;

  &[data-state='active'] {
    color: ${gradientBlue};
    border-bottom: 2px solid ${gradientBlue};
  }
`;

export const TabsList = styled(Tabs.List)`
  display: flex;
  justify-content: space-between;
`;

export const TabWrapper = styled.div`
  margin-top: 20px;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

export const SmallFiltersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DataWrapper = styled.div`
  margin-top: 5px;
`;

export const DataItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;
  ${({ isDragging }) =>
    isDragging
      ? `
    background-color: ${hardWhite};
    border: 1px dashed #5848FB66;
    border-radius: 2px;
    height: 100px !important;
    width: 200px !important;
  `
      : ''}
`;

export const DataItemStatus = styled.div`
  width: 10px;
  height: 10px;
  min-width: 10px;
  border-radius: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor ?? '#000'};
  margin-right: 5px;
`;

export const DataItemLabel = styled.div`
  margin-right: 5px;
  font-size: 13px;
  color: ${darkerGray};
`;
