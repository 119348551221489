const allWhitespace = /\s/g;

const transformValue = (value, index = 0) =>
  typeof value !== 'undefined'
    ? value.slice(0, 10).replace(allWhitespace, '')
    : index;

export const optionKey = (option, index, isSelected) =>
  `options-option-${transformValue(option.value, index)}-${index}${
    isSelected ? '-selected' : ''
  }`;

export const formatItem = (item, selectedIds) => ({
  ...item,
  selected: selectedIds.some((id) => Number(id) === item.id || id === item.id),
  children:
    item.children?.length &&
    item.children.map((child) => formatItem(child, selectedIds)),
});

export const flatMapSelectedItems = (items) => {
  const uniqueItemsMap = new Map();

  const mutatedItems = [...items];

  while (mutatedItems.length) {
    const item = mutatedItems.pop();

    if (item.selected && !uniqueItemsMap.has(item.id)) {
      uniqueItemsMap.set(item.id, item);
    }

    if (item.children?.length) {
      item.children.forEach((child) => {
        if (child.selected && !uniqueItemsMap.has(child.id)) {
          uniqueItemsMap.set(child.id, child);
        }
      });
    }
  }

  return Array.from(uniqueItemsMap.values());
};
