import { useEffect, useMemo, useRef } from 'react';

import { useGetUserPositionsQuery } from '../service';
import {
  INFINITE_SCROLL_INITIAL_STATE,
  RTK_HOOK_CONFIG,
} from '../../Equipment/constants';

import useInfiniteScrollData from '../../Equipment/hooks/useInfiniteScrollData';

const useUserPositions = (skipQuery, customParams) => {
  const {
    scrollState,
    parseFetchedData,
    handleLoadMore,
    handleSearch,
    handleStateUpdate,
    mergeStateItems,
    handleCustomQuery,
  } = useInfiniteScrollData({
    ...INFINITE_SCROLL_INITIAL_STATE,
    query: {
      ...INFINITE_SCROLL_INITIAL_STATE.query,
    },
  });

  const forceItemsOverride = useRef(false);

  const params = useMemo(() => {
    return {
      ...scrollState.query,
      ...customParams,
    };
  }, [scrollState?.query, customParams]);

  const {
    data: fetchedData,
    isFetching,
    isLoading,
    refetch: refetchData,
  } = useGetUserPositionsQuery(params, {
    ...RTK_HOOK_CONFIG,
    skip: skipQuery || false,
  });

  const refetch = () => {
    forceItemsOverride.current = true;
    refetchData();
  };

  useEffect(() => {
    handleStateUpdate(
      { data: fetchedData?.map((item) => ({ id: item, name: item })) },
      forceItemsOverride.current
    );
    forceItemsOverride.current = false;
  }, [fetchedData, handleStateUpdate]);

  return {
    scrollState,
    parseFetchedData,
    handleLoadMore,
    handleSearch,
    isFetching,
    mergeStateItems,
    isLoading,
    handleCustomQuery,
    refetch,
  };
};

export default useUserPositions;
