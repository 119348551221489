import { useEffect } from 'react';

import {
  RTK_HOOK_CONFIG,
  INFINITE_SCROLL_INITIAL_STATE,
} from '../../../../config/constants';
import useInfiniteScrollData from '../../Equipment/hooks/useInfiniteScrollData'; //TO DO - lift
import { useGetSystemsQuery } from '../Systems/service';

const useSystems = ({ skipQuery, customParams } = {}) => {
  const {
    scrollState,
    handleLoadMore,
    handleSearch,
    handleStateUpdate,
    mergeStateItems,
    deleteManuallyFromListItems,
  } = useInfiniteScrollData({
    ...INFINITE_SCROLL_INITIAL_STATE,
    query: {
      ...INFINITE_SCROLL_INITIAL_STATE.query,
      ...(customParams || {}),
    },
    id: 'assetSystems',
  });

  const { data: fetchedData, isFetching } = useGetSystemsQuery(
    { ...scrollState.query },
    {
      ...RTK_HOOK_CONFIG,
      skip: skipQuery || false,
    }
  );

  useEffect(() => {
    handleStateUpdate(fetchedData);
  }, [fetchedData, handleStateUpdate]);

  return {
    scrollState,
    handleLoadMore,
    handleSearch,
    isFetching,
    handleStateUpdate,
    mergeStateItems,
    deleteManuallyFromListItems,
  };
};

export default useSystems;
