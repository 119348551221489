import Pill from '../Pill';
import { SelectWrapper, SelectedItems, SelectHeader } from '../styles';
import { purple500 } from '../../../config/colors';
import { WowIcon } from '../../WowBasicElements';

const SelectedPills = ({
  handleClick,
  selectedItems,
  onRemove,
  selectHeaderLabel,
}) => {
  return (
    <SelectWrapper hasSelectedItems={!!selectedItems?.length}>
      <SelectHeader onClick={handleClick}>{selectHeaderLabel}</SelectHeader>
      <WowIcon
        color={purple500}
        className="icon icon-plus"
        onClick={handleClick}
      />

      {selectedItems?.length ? (
        <SelectedItems data-test="selected-items">
          {selectedItems.map((item, index) => (
            <Pill
              index={index}
              key={item.id}
              item={item}
              canRemove={!item.disabled}
              onRemove={onRemove(item)}
            />
          ))}
        </SelectedItems>
      ) : null}
    </SelectWrapper>
  );
};

export default SelectedPills;
