import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import isNil from 'lodash.isnil';
import classNames from 'classnames';
import { LoadingSpinner } from '../loading-spinner/loading-spinner.component';
import { themeColour, themedFont } from '../../helpers/theme';
import {
  greenButtonColor,
  redButtonColor,
  buttonInactive,
  buttonInactiveText,
  buttonInactiveHover,
  white,
  lighterGray,
} from '../../config/colors';

import './btn.component.scss';
const setPosition = (props) => {
  if (props.floatRight) {
    return 'float: right;';
  }

  return '';
};
const setBoxShadowColor = (props) => {
  if (props.hoverBorderButton) {
    if (props.red) {
      return redButtonColor;
    }

    if (props.green) {
      return greenButtonColor;
    }
  }

  return 'transparent';
};
const getLabelButtonTextColor = (props) => {
  if (props.isHovering && props.hoverBorderButton) {
    return white;
  }

  if (props.active) {
    return redButtonColor;
  }

  return lighterGray;
};
const setButtonHover = (props) => {
  if (!props.hover) {
    return;
  }

  const newProps = { ...props, isHovering: true };

  if (props.hoverFullButton) {
    return `
      &:hover {
        background: ${themeColour(newProps)};
        color: white;
      }
    `;
  }

  if (props.hoverBorderButton) {
    return `
      border: 2px solid ${setBoxShadowColor(props)};

      &:hover {
        background: ${setBoxShadowColor(newProps)};
        color: ${white}
      }
    `;
  }

  if (newProps.labelButton) {
    return `
      background-color: ${themeColour(newProps)};
      color: ${getLabelButtonTextColor(newProps)};
      -webkit-text-fill-color: ${getLabelButtonTextColor(newProps)};
    `;
  }

  const hasActiveProp = !isNil(newProps.active);

  if (hasActiveProp && !newProps.active) {
    if (!isNil(newProps.secondary)) {
      return `
        background-color: ${buttonInactive};
        color: ${buttonInactiveText};

        &:hover {
          background-color: ${buttonInactiveHover}
        }
      `;
    }
  }

  return '';
};
const setButtonAdaptibleProps = (props) => {
  if (props.wide) {
    return 'min-width: 150px;';
  }

  if (props.adaptible) {
    return `
      min-width: unset;
      width: unset;
    `;
  }

  return '';
};
const setButtonHeight = (props) => {
  if (props.heightAdaptible) {
    return 'auto';
  }

  if (props.small) {
    return '22px';
  }

  return 'auto';
};
const setButtonPadding = (props) => {
  if (props.heightAdaptible) {
    return '5px';
  }

  return '0px';
};
const setBorder = (props) => `border-color: ${setBoxShadowColor(props)}`;
const setSize = (props) => {
  if (props.small) {
    return `
      border-radius: 11px;
      height: ${setButtonHeight(props)};
      color: ${white};
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      margin-left: ${props.noMarginLeft ? 0 : '1rem'}; 
      text-transform: initial;
      letter-spacing: 0.3px;
      line-height: 100%;
      padding-top: ${setButtonPadding(props)};
      padding-bottom: ${setButtonPadding(props)};
    `;
  }
};
const ButtonBase = styled.button`
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  flex: 1;
  font-weight: 800;
  letter-spacing: 1.33px;
  line-height: 10px;
  transform: translateZ(0);
  padding: ${({ isLoading }) => (isLoading ? '10px 15px 10px 15px' : '15px')};
  border-radius: 8px;
  text-align: center;
  background: ${themeColour};
  color: ${themedFont};

  ${setButtonHover}
  ${setButtonAdaptibleProps}
  ${setPosition}
  ${setBorder}
  ${setSize}
  ${({ fontSize }) => fontSize && `font-size:${fontSize}px`};
`;

const BtnBase = (props) => {
  const classes = classNames([props.className].join(' '), {
    wide: props.wide,
    adaptible: props.adaptible,
    [`active-${props.active}`]: props.active,
    disabled: props.disabled,
  });

  return (
    <ButtonBase
      {...props}
      className={classes}
      onClick={props.onClick}
      disabled={!!props.disabled}
      isLoading={props.isLoading}
    >
      {props.isLoading ? <LoadingSpinner isLocal small /> : props.name}
    </ButtonBase>
  );
};

BtnBase.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  /** Sets min width to 150px */
  wide: PropTypes.bool,
  /** Unsets width */
  adaptible: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  /** Shows loader */
  isLoading: PropTypes.bool,
  /** Sets button to primary or secondary. See up for more info */
  primary: PropTypes.bool,
  // secondary: PropTypes.bool,
  /** Sets various size props; Look up for more info */
  small: PropTypes.bool,
  /** Has no body. Only button text is visible */
  labelButton: PropTypes.bool,
  /** Floats right */
  floatRight: PropTypes.bool,
  /** Uses green as the background color */
  green: PropTypes.bool,
  /** Uses red as the background color */
  red: PropTypes.bool,

  /** Colors */
  main: PropTypes.bool,
  mainAccent: PropTypes.bool,
  secondaryAccent: PropTypes.bool,
  secondary: PropTypes.bool,

  /**
   * Hover darken
   *
   * Darkens current colour on hover
   */
  hoverDarken: PropTypes.bool,

  /** Hover options */
  /** Button will have a full body on hover */
  hoverFullButton: PropTypes.bool,
  /** Button will have an empty body but will have a */
  hoverBorderButton: PropTypes.bool,
  /** If gradient color is used, reverse the colors */
  reverseGradient: PropTypes.bool,
};

BtnBase.defaultProps = {
  onClick: undefined,
  className: '',
  wide: false,
  adaptible: false,
  active: null,
  disabled: false,
  isLoading: false,
};

export const Btn = BtnBase;
