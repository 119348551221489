import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { mergeData } from './utils';
import { isFunction } from '../../../utils/core';
import { ERRORS } from './constants';

const initState = {};

const parseResult = (result) => {
  if (!result.meta) return result;
  return {
    data: result.data,
    last_page: +result.meta.lastPage,
    current_page: +result.meta.page,
    total: +result.meta.totalCount,
  };
};

export const fetchInfiniteData = createAsyncThunk(
  'infiniteData/fetch',
  async (args, thunkAPI) => {
    const { getItems, entityKey } = args;

    if (!isFunction(getItems)) throw new Error(ERRORS.getItems);

    const queryParams = thunkAPI.getState().infiniteData?.[entityKey]?.query;

    if (!queryParams) throw new Error(ERRORS.queryParams);

    const result = await getItems(queryParams);

    return parseResult(result);
  }
);

export const inifiniteDataSlice = createSlice({
  name: 'infiniteData',
  initialState: initState,

  reducers: {
    // action used to override whole state
    setState: (state, action) => {
      const { entityKey, entityState } = action.payload;
      state[entityKey] = entityState;
    },

    // action used to remove existing state
    resetState: (state, action) => {
      delete state[action.payload.entityKey];
    },

    // action used to override data for specific entity key
    updateData: (state, action) => {
      const { entityKey, data } = action.payload;
      if (state[entityKey]) {
        state[entityKey].data = data;
        state[entityKey].total = data.length;
      }
    },

    // action used to update existing query params
    updateQueryParams: (state, action) => {
      const { entityKey, queryParams } = action.payload;
      if (state[entityKey]) {
        state[entityKey].query = {
          ...state[entityKey].query,
          ...queryParams,
        };
      }
    },

    editItem: (state, action) => {
      const { data, entityKeys } = action.payload;

      for (const key of entityKeys) {
        if (state[key]) {
          const _idKey = state[key].idKey;
          state[key].data = state[key].data.map((item) => {
            const newItem = data.find(
              (newItem) => newItem[_idKey] === item[_idKey]
            );

            if (newItem) {
              return { ...item, ...newItem };
            }

            return item;
          });
        }
      }
    },

    deleteItem: (state, action) => {
      const { ids, entityKeys } = action.payload;

      for (const key of entityKeys) {
        if (state[key]) {
          state[key].data = state[key].data.filter(
            (item) => !ids.includes(item[state[key].idKey])
          );
          state[key].total -= ids.length;
        }
      }
    },

    addItem: (state, action) => {
      const { items, entityKey, addFirst } = action.payload;
      if (state[entityKey]) {
        const { itemsAdded } = mergeData(
          state[entityKey].data,
          items,
          addFirst
        );
        state[entityKey].total += itemsAdded;
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchInfiniteData.fulfilled, (state, action) => {
      const {
        data,
        last_page: lastPage,
        total,
        current_page: currentPage,
      } = action.payload;
      const { entityKey } = action.meta.arg;
      if (state[entityKey]) {
        state[entityKey].loading = false;
        state[entityKey].lastPage = lastPage;
        state[entityKey].total = total;

        if (currentPage === 1) {
          state[entityKey].data = data;
        } else {
          mergeData(state[entityKey].data, data, null, state[entityKey].idKey);
        }
      }
    });

    builder.addCase(fetchInfiniteData.pending, (state, action) => {
      const { entityKey } = action.meta.arg;
      state[entityKey].loading = true;
    });

    builder.addCase(fetchInfiniteData.rejected, (state, action) => {
      const { entityKey } = action.meta.arg;
      state[entityKey].loading = false;
    });
  },
});

export const {
  setState,
  updateQueryParams,
  updateData,
  resetState,
  deleteItem,
  addItem,
  editItem,
} = inifiniteDataSlice.actions;

export default inifiniteDataSlice.reducer;
