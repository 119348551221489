import dict from '../../../config/multilang';
import { issuesPriorityFilterKeys } from '../../../config/issueFilter';
import { getTranslations } from '../../../utils/translations.helper';

const TRANSLATIONS = {
  priority: ['priority'],
  low: ['priority.low'],
  normal: ['priority.normal'],
  high: ['priority.high'],
  urgent: ['priority.urgent'],
};

export const issuePriorityFilterOptions = (intl) => {
  const t = getTranslations(TRANSLATIONS, intl, dict);

  return [
    {
      key: issuesPriorityFilterKeys.PRIORITY_FILTER_OPTION_ALL,
      caption: t.priority,
      className: 'menuitem',
    },
    {
      key: issuesPriorityFilterKeys.PRIORITY_FILTER_OPTION_URGENT,
      caption: t.urgent,
      className: 'menuitem',
    },
    {
      key: issuesPriorityFilterKeys.PRIORITY_FILTER_OPTION_HIGH,
      caption: t.high,
      className: 'menuitem',
    },
    {
      key: issuesPriorityFilterKeys.PRIORITY_FILTER_OPTION_NORMAL,
      caption: t.normal,
      className: 'menuitem',
    },
    {
      key: issuesPriorityFilterKeys.PRIORITY_FILTER_OPTION_LOW,
      caption: t.low,
      className: 'menuitem',
    },
  ];
};
