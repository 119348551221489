import { useState, useRef, useEffect, forwardRef, useMemo } from 'react';
import { useOnClickOutside } from '../../hooks/useClickOutside';

import SelectedPills from './SelectedPills';
import { DropdownMultipleWithSearch } from '../Dropdown';
import { Wrapper } from './styles';
import { formatItem, flatMapSelectedItems } from './utils';

const Multiselect = forwardRef(
  (
    {
      items = [],
      selectHeaderLabel,
      onChange,
      disabled = false,
      handleLoadMore,
      handleSearch,
      hasMore,
      isLoading,
      handleGet,
      selected,
      hiddenItemsIds = [],
      menuHeight = null,
      menuWidth = '100%',
      onMenuToggle = () => {},
    },
    forwardedRef
  ) => {
    const formattedItems = useMemo(
      () => items.map((item) => formatItem(item, selected)),
      [items, selected]
    );

    const [selectedItems, setSelectedItems] = useState([]);

    const [isOpen, setIsOpen] = useState(false);
    const toggleIsOpen = () => {
      setIsOpen(!isOpen);
      onMenuToggle(!isOpen);
    };

    const dropdownRef = useRef(null);

    useEffect(() => {
      if (isOpen && handleGet) handleGet();
    }, [isOpen, handleGet]);

    const onRemove = (item) => () => {
      if (disabled || !onChange) {
        return;
      }

      onChange(item);
    };

    useOnClickOutside(dropdownRef, toggleIsOpen, true);

    const onSelect = (item) => {
      if (disabled || !onChange) {
        return;
      }

      onChange(item);
    };

    useEffect(() => {
      setSelectedItems((prevItems) => [
        ...prevItems.filter((item) => selected?.includes(String(item.id))),
        ...flatMapSelectedItems(formattedItems).filter((item) =>
          prevItems.every((selectedItem) => selectedItem.id !== item.id)
        ),
      ]);
    }, [formattedItems, selected]);

    return (
      <Wrapper>
        <DropdownMultipleWithSearch
          options={formattedItems.filter(
            (item) => !hiddenItemsIds?.includes?.(`${item.id}`)
          )}
          onMenuToggle={onMenuToggle}
          handleLoadMore={handleLoadMore}
          hasMore={hasMore}
          handleSearch={handleSearch}
          handleChange={onSelect}
          isLoading={isLoading}
          selected={selected}
          handleGet={handleGet}
          customButton={
            <SelectedPills
              onRemove={onRemove}
              selectedItems={selectedItems}
              selectHeaderLabel={selectHeaderLabel}
            />
          }
          menuWidth={menuWidth}
          {...(menuHeight !== null ? { menuHeight: menuHeight } : {})}
          // TODO: Uncomment this for testing purposes
          // dropdownToggleButtonTestId
          // optionsTestId
        />
      </Wrapper>
    );
  }
);

export default Multiselect;
