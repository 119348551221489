import qs from 'query-string';
import { assetsApi } from '../service';

const assetSystemsApi = assetsApi.injectEndpoints({
  endpoints: (builder) => ({
    getSystems: builder.query({
      query: (params) => {
        const url = qs.stringifyUrl(
          { url: 'asset/system', query: params },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
        );
        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'AssetsSystems', id })),
              { type: 'AssetsSystems', id: 'LIST' },
            ]
          : [{ type: 'AssetsSystems', id: 'LIST' }],
    }),
    addSystem: builder.mutation({
      query(body) {
        return {
          url: 'asset/system',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'AssetsSystems', id: 'LIST' }],
    }),
    editSystem: builder.mutation({
      query(payload) {
        const { id, ...restPayload } = payload;
        return {
          url: `asset/system/${id}`,
          method: 'PUT',
          body: restPayload,
        };
      },
      invalidatesTags: [{ type: 'AssetsSystems', id: 'LIST' }],
    }),
    deleteSystem: builder.mutation({
      query(id) {
        return {
          url: `asset/system/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: 'AssetsSystems', id: 'LIST' }],
    }),
    archiveMultipleSystems: builder.mutation({
      query(payload) {
        return {
          url: `asset/system/multi_archive`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: [{ type: 'AssetsSystems', id: 'LIST' }],
    }),
    deleteMultipleSystems: builder.mutation({
      query(payload) {
        return {
          url: `asset/system/multi_delete`,
          method: 'DELETE',
          body: payload,
        };
      },
      invalidatesTags: [{ type: 'AssetsSystems', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSystemsQuery,
  useLazyGetSystemsQuery,
  useAddSystemMutation,
  useEditSystemMutation,
  useDeleteSystemMutation,
  useArchiveMultipleSystemsMutation,
  useDeleteMultipleSystemsMutation,
} = assetSystemsApi;
