import styled from 'styled-components/macro';
import { borderGray, darkerGray } from '../../../../config/colors';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 36px;
  position: relative;
  border: 1px solid ${borderGray};
  border-radius: 6px;
  ${({ backgroundColor }) =>
    backgroundColor ? `background-color: ${backgroundColor}` : null};
`;

export const ClickableWrapper = styled.div`
  display: flex;
  color: ${darkerGray};
  font-size: 14px;
  padding: 7px 10px;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 5px;
`;

export const PillsWrapper = styled.div`
  padding: 0 10px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;
