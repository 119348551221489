import { entities as entitiesConfig } from './entitiesConfig';

export const viewModes = {
  BLOCKS: 'BLOCKS',
  ROWS: 'ROWS',
};

/**
 * $desktop:         1200px;
 * $tablet:          768px;
 */
export const breakPoints = {
  desktopWidth: 1200,
  tabletWidth: 768,
};

export const entities = entitiesConfig;

export const appConfig = {
  localStorageItem: 'wowflow',
};

export const externalRoutePaths = {
  ISSUE: '/public/issue?issueId=:id',
  LOGIN: '/login',
  SIGNUP: '/signup',
};

export const internalRoutePaths = {
  WORKSPACES: '/dashboard/workspaces',
  WORKSPACES_ADD: '/dashboard/workspaces/add',
  WORKSPACES_EDIT: '/dashboard/workspaces/edit/:id',
  WORKSPACES_ID: '/dashboard/workspaces/detail/:id',
  WORKSPACE_CHILD: '/dashboard/workspaces/detail/:parentId/:childId',
  WORKSPACE_LEASE_INFO: '/dashboard/workspaces/lease-info/:id/',
  WORKSPACE_MAINTENANCE_INFO: '/dashboard/workspaces/maintenance-info/:id/',

  ISSUES: '/dashboard/issues',
  ISSUES_ADD: '/dashboard/issues/add',
  ISSUES_EDIT: '/dashboard/issues/edit/:id',
  ISSUES_ID: '/dashboard/issues/detail/:id',
  SUBTASK_ID: '/dashboard/issues/detail/:id/:subtaskId',

  SCHEDULING: '/dashboard/scheduling',
  SCHEDULING_ADD: '/dashboard/scheduling/add',
  SCHEDULING_ID: '/dashboard/scheduling/detail/:id',

  EQUIPMENT: '/dashboard/equipment',
  EQUIPMENT_ADD: '/dashboard/equipment/add',
  EQUIPMENT_ID: '/dashboard/equipment/detail/:id',
  EQUIPMENT_GROUPS: '/dashboard/equipment/groups',
  EQUIPMENT_TYPES: '/dashboard/equipment/types',
  EQUIPMENT_EXTERNAL_HOLDERS: '/dashboard/equipment/external-holders',

  ASSETS: '/dashboard/assets',
  ASSETS_ADD: '/dashboard/assets/add',
  ASSETS_ID: '/dashboard/assets/detail/:id',
  ASSETS_GROUPS: '/dashboard/assets/groups',
  ASSETS_SYSTEMS: '/dashboard/assets/systems',

  USERS: '/dashboard/profiles',
  USER: '/dashboard/profile/detail',
  USER_EDIT: '/dashboard/profile/edit',
  USER_REGISTER: '/dashboard/profiles/new',
  USER_DETAIL: '/dashboard/profile/detail/:id',

  CATEGORIES: '/dashboard/categories',

  COLLABORATORS: '/dashboard/collaborators',
  COLLABORATORS_ID: '/dashboard/collaborators/detail/:id',

  CALENDAR: '/dashboard/calendar',

  TEMPLATES: '/dashboard/templates',
  TEMPLATES_CHECKLISTS: '/dashboard/templates/checklists',
  TEMPLATES_CHECKLISTS_ADD: '/dashboard/templates/checklists/add',
  TEMPLATES_CHECKLISTS_ID: '/dashboard/templates/checklists/detail/:id',
  TEMPLATES_AUTOCOMPLETE: '/dashboard/templates/autocomplete',

  SETTINGS: '/dashboard/settings',
  GENERAL: '/dashboard/settings/general',
  PROFILE: '/dashboard/settings/profile',
  PDF_REPORTS: '/dashboard/settings/pdf-reports',
  PUBLIC_FORMS: '/dashboard/settings/public-forms',
  ONBOARDING: '/dashboard/onboarding',
};

export const surveyWhitelistedRoutes = {
  '/dashboard/workspaces': '/dashboard/workspaces',
  '/dashboard/issues': '/dashboard/issues',
  '/dashboard/scheduling': '/dashboard/scheduling',
  '/dashboard/equipment': '/dashboard/equipment',
  '/dashboard/equipment/groups': '/dashboard/equipment/groups',
  '/dashboard/equipment/type': '/dashboard/equipment/types',
  '/dashboard/assets': '/dashboard/assets',
  '/dashboard/assets/groups': '/dashboard/assets/groups',
  '/dashboard/assets/systems': '/dashboard/assets/systems',
  '/dashboard/profiles': '/dashboard/profiles',
  '/dashboard/categories': '/dashboard/categories',
};

export const MOBILE_APP_REDIRECT_WITELIST = {
  '/change-password': '/change-password',
  '/signup': '/signup',
  '/signup/': '/signup/',
  '/dashboard/onboarding': '/dashboard/onboarding',
};

export const userStatuses = {
  ACTIVE: 'ACTIVE',
  PAUSED: 'PAUSED',
  PENDING: 'PENDING',
};

export const dateFormats = {
  DATE_AND_MONTH: 'DD. MMM',
  DATE_AND_MONTH_YEAR: 'DD. MMMM YYYY',
  DATE_MONTH_YEAR_TIME: 'DD.MM.YYYY HH:mm',
  TIME_AND_DATE: 'HH:mm DD.MM.YYYY',
  NAMED_MONTH_DAY_YEAR: 'MMMM DD.YYYY',
  STANDARD_DATE: 'DD.MM.YYYY',
  STANDARD_DATE_ONLY_DATE_DATEPICKER: 'dd.MM.yyyy',
  STANDARD_DATE_DATEPICKER: 'dd.MM.yyyy HH:mm',
  STANDARD_TIME_DATEPICKER: 'HH:mm',
  STANDARD_DATE_FILTERS: 'DD/MM/YYYY',
  NAMED_MONTH_DAY_YEAR_TIME: 'MMMM DD.YYYY HH:mm',
  MONTH: 'MMM',
  WORKSPACE_DYNAMIC_FORM_DAY_MONTH_YEAR: 'dd.MM.yyyy',
  HOURS: 'HH:mm',
  TIMESTAMP: 'x',
};

export const sortOrder = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const sortFields = {
  NAME: 'name',
  UPDATED_AT: 'updated_at',
  CREATED_AT: 'created_at',
};

export const filterParameters = {
  SEARCH: 'searchTerm',
  STATUS_FILTER: 'statusFilter',
  PRIORITY_FILTER: 'priority',
  SORT_FILTER: 'sortField',
  RATING_FILTER: 'rating',
  ISSUE_TYPE: 'issueType',
};

export const MIN_PASSWORD_LENGTH = 8;
export const ESC_KEY = 27;
export const ENTER_EVENT_KEY = 'Enter';
export const ENTER_KEY = 13;
export const LEFT_ARROW_KEY = 37;
export const UP_ARROW_KEY = 38;
export const RIGHT_ARROW_KEY = 39;
export const DOWN_ARROW_KEY = 40;
export const TAB_KEY = 9;

export const DEFAULT_LANG = 'en';
export const MIN_DATE = new Date(-8640000000000000);

export const DEFAULT_PAGE = 1;
export const PAGE_SIZE = 20;

export const QUERY_PARAM_CONST = {
  FAVORITE: 'favourite',
  NON_FAVORITE: 'non-favourite',
};

export const FILE_TYPE = {
  PDF: 'pdf',
};

export const DENSITY = {
  comfortable: 'comfortable',
  compact: 'compact',
  table: 'table',
};

export const MOBILE_OS = {
  WINDOWS_PHONE: 'Windows Phone',
  ANDROID: 'Android',
  IOS: 'iOS',
  UNKNOWN: 'Unknown',
};

export const MOBILE_APP_LINKS = {
  IOS: 'https://apps.apple.com/app/wowflow/id1449412899',
  ANDROID: 'https://play.google.com/store/apps/details?id=org.wowflow',
};

export const WEEKDAYS = {
  0: 'week_day_0',
  1: 'week_day_1',
  2: 'week_day_2',
  3: 'week_day_3',
  4: 'week_day_4',
  5: 'week_day_5',
  6: 'week_day_6',
};

export const MONTH_KEYS = {
  1: '-01--',
  2: '-02--',
  3: '-03--',
  4: '-04--',
  5: '-05--',
  6: '-06--',
  7: '-07--',
  8: '-08--',
  9: '-09--',
  10: '-10--',
  11: '-11--',
  12: '-12--',
};
