import styled from 'styled-components/macro';
import { filtersAdvancedGray, newBorder } from '../../../../../config/colors';

export const MainView = styled.div``;

export const Wrapper = styled.div`
  width: ${({ width }) => width ?? 'auto'};
  position: absolute;
  inset: 0px;
`;

export const MainViewWrapper = styled.div`
  display: flex;
  padding-top: 160px;
`;

export const HeaderWrapper = styled.div`
  position: sticky;
  top: 200px;
  left: 0px;
  z-index: 100;
  display: flex;
  align-items: flex-end;
`;

export const HeaderLabelWrapper = styled.div`
  border-left: 1px solid ${newBorder};
  border-right: 1px solid ${newBorder};
  flex: 1;
`;

export const MainViewLeft = styled.div`
  width: 150px;
`;

export const MainViewRight = styled.div`
  width: 100%;
  flex: 1;
  border-left: 1px solid ${newBorder};
  border-right: 1px solid ${newBorder};
  overflow: scroll hidden;
  position: relative;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TopTimelineLabel = styled.div`
  color: ${filtersAdvancedGray};
  font-size: 12px;
  margin-left: 10px;
  margin-bottom: 5px;
`;

export const HoursLabel = styled.div`
  color: ${filtersAdvancedGray};
  font-size: 13px;
  font-weight: 600;
  width: ${({ width }) => width + 'px' ?? 'auto'};
`;

export const HoursBorder = styled.div`
  width: ${({ width }) => width + 'px' ?? 'auto'};
  border-left: ${({ noBorder }) =>
    noBorder ? 'none' : `1px solid ${newBorder}`};
  height: 100%;
`;

export const TimelineItemsWrapper = styled.div``;

export const TimelineBordersWrapper = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
`;

export const TimlineItemsCardsWrapper = styled.div`
  position: relative;
  ${({ showBottomBorder }) =>
    showBottomBorder ? `border-bottom: 1px solid ${newBorder};` : ''}
  ${({ top }) => (top ? `top: ${top}px;` : '')}
`;

export const TimelinePresenceCardsWrapper = styled.div`
  border-top: 1px solid ${newBorder};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

export const ViewMoreCardsWrapper = styled.div`
  position: absolute;
  ${({ bottom }) => (bottom ? `bottom: ${bottom}px;` : '')}
  left: 0;
  width: 100%;
`;
