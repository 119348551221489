import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Modal, { SIZE } from '../../../../../components_new/Modal/index.jsx';
import Button from '../../../../../components_new/Button/index.js';
import Space from '../../../../../components_new/Space';
import {
  FormatWrapper,
  ExportOptionsWrapper,
  ExportOption,
  InfoWrapper,
  IconImportant,
  Footer,
  MainContent,
  Title,
  Message,
} from './styles.js';

import { LoadingSpinner } from '../../../../../components/loading-spinner/loading-spinner.component.js';

const ModalExportData = ({
  handleToggle,
  isOpen,
  fileType,
  activeFormat,
  handleFormatToggle,
  handleExport,
  isProcessingExport,
  title,
  message,
}) => {
  const intl = useIntl();
  const isSummary = activeFormat === 'summary';

  const handleExportFormat = () => {
    handleExport(fileType, isSummary);
  };

  return (
    <Modal
      title={`${intl.formatMessage({
        id: 'export',
      })} ${fileType.toUpperCase()}`}
      isOpen={isOpen || isProcessingExport}
      onClose={handleToggle}
      size={SIZE.medium}
    >
      {isProcessingExport ? (
        <MainContent data-testid="loadingModal">
          <LoadingSpinner isLocal />
          <Title>{title}</Title>
          <Message>{message}</Message>
        </MainContent>
      ) : (
        <>
          <FormatWrapper>
            <FormattedMessage id="format" />
          </FormatWrapper>
          <ExportOptionsWrapper>
            <ExportOption
              active={activeFormat === 'summary'}
              onClick={handleFormatToggle}
              name="summary"
            >
              <FormattedMessage id="summary" />
            </ExportOption>
            <Space width="30" />
            <ExportOption
              active={activeFormat === 'all'}
              onClick={handleFormatToggle}
              name="all"
            >
              <FormattedMessage id="all_fields" />
            </ExportOption>
          </ExportOptionsWrapper>
          <InfoWrapper>
            <IconImportant />
            <FormattedMessage
              id={
                activeFormat === 'summary'
                  ? 'includes_most_important_fields'
                  : 'includes_all_fields'
              }
            />
          </InfoWrapper>
          <Footer>
            <Button
              color="green"
              appearance="contained"
              type="button"
              onClick={handleExportFormat}
              name="export"
              size="small"
            >
              <FormattedMessage id="export" />
            </Button>
            <Space height="5" />
            <Button
              color="red"
              appearance="text"
              type="button"
              onClick={handleToggle}
              name="cancel"
              size="small"
            >
              <FormattedMessage id="cancel" />
            </Button>
          </Footer>
        </>
      )}
    </Modal>
  );
};

export default ModalExportData;
