import styled from 'styled-components/macro';
import { filtersAdvancedGray } from '../../../config/colors';

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
  color: ${filtersAdvancedGray};
  font-size: 12px;
  flex-shrink: 0;
`;

export const IconWrapper = styled.i`
  color: ${filtersAdvancedGray};
  font-size: 16px;
  cursor: pointer;
`;
