import PropTypes from 'prop-types';
import { WowIcon } from '../../../WowBasicElements';
import { ICON_BACK_CONFIG } from '../../constants';
import { SubMenuSearchInput, SubMenuSearchWrapper } from '../../styles';
import { useState } from 'react';
import { useDebounce } from '../../../../hooks/useDebounce';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

const Search = (props) => {
  const { iconCallback, showIcon = true, handleSearch } = props;

  const intl = useIntl();
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 700);

  useEffect(() => {
    handleSearch(debouncedSearch);
  }, [debouncedSearch, handleSearch]);

  return (
    <SubMenuSearchWrapper>
      {showIcon ? (
        <WowIcon {...ICON_BACK_CONFIG} onClick={iconCallback} />
      ) : null}
      <SubMenuSearchInput
        placeholder={intl.formatMessage({ id: 'search' })}
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        autoFocus
      />
    </SubMenuSearchWrapper>
  );
};

Search.propTypes = {
  iconCallback: PropTypes.func,
  showIcon: PropTypes.bool,
  handleSearch: PropTypes.func.isRequired,
};

export default Search;
