import styled from 'styled-components/macro';
import { darkGray, white } from '../../../../../config/colors';

export const Wrapper = styled.div`
  display: flex;
  min-width: 30px;
  color: ${white};
  background-color: ${darkGray};
  border-radius: 20px;
  font-size: 14px;
  margin-bottom: 10px;
  align-items: center;
  padding: ${({ hasImage }) =>
    hasImage ? '3px 10px 3px 3px' : '5px 10px 5px 12px'};
  line-height: 1;
  flex: 0 0 auto;
  max-width: 100%;

  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
  }
`;

export const InfoWrapper = styled.div`
  color: ${white};
  width: 100%;
  display: flex;
  line-height: 1;
`;

export const Label = styled.span`
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;
