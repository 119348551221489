import React from 'react';
import PropTypes from 'prop-types';
import { StyledHeader, IconWrapper } from './styles';

const Header = ({ children, onClose }) => {
  return (
    <StyledHeader data-testid="header">
      {children}
      <IconWrapper
        className="icon icon-close"
        onClick={onClose}
        data-testid="iconClose"
      />
    </StyledHeader>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default Header;
