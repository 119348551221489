import { useEffect } from 'react';

import { useGetSchedulesQuery } from '../services';
import {
  RTK_HOOK_CONFIG,
  INFINITE_SCROLL_INITIAL_STATE,
} from '../../../../config/constants';
import useInfiniteScrollData from '../../Equipment/hooks/useInfiniteScrollData'; //TO DO - lift

const useSchedules = ({ skipQuery, params } = {}) => {
  const {
    scrollState,
    handleLoadMore,
    handleSearch,
    handleStateUpdate,
    mergeStateItems,
    deleteManuallyFromListItems,
    handleCustomQuery,
  } = useInfiniteScrollData({
    ...INFINITE_SCROLL_INITIAL_STATE,
    query: {
      ...INFINITE_SCROLL_INITIAL_STATE.query,
      include_sub_ws: 0,
      sort_by: 'title',
      sort_direction: 'asc',
      status: 'all',
    },
    id: 'schedules',
  });

  const { data: fetchedData, isFetching } = useGetSchedulesQuery(
    { ...scrollState.query, ...params },
    {
      ...RTK_HOOK_CONFIG,
      skip: skipQuery || false,
    }
  );

  useEffect(() => {
    handleStateUpdate(fetchedData);
  }, [fetchedData, handleStateUpdate]);

  return {
    scrollState,
    handleLoadMore,
    handleSearch,
    isFetching,
    handleStateUpdate,
    mergeStateItems,
    deleteManuallyFromListItems,
    handleCustomQuery,
  };
};

export default useSchedules;
