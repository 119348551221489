import keys from 'lodash.keys';

export const getTranslations = (translations, { formatMessage }, dict) =>
  keys(translations).reduce(
    (acc, string) => ({
      ...acc,
      [string]: formatMessage({
        id: translations[string][0],
        defaultMessage: translations[string][1]
          ? dict[translations[string][1]]
          : dict[translations[string][0]],
      }),
    }),
    {}
  );
