export const pageStatus = {
  initial: 'initial',
  loading: 'loading',
  idle: 'idle',
  error: 'error',
};

export const statuses = [
  { value: 'all', label: 'status_all' },
  { value: 'active', label: 'active' },
  { value: 'paused', label: 'paused' },
  { value: 'archived', label: 'archived' },
];

export const sortOptions = [
  {
    value: 'title',
    label: 'title',
  },
  {
    value: 'created',
    label: 'creation',
  },
  {
    value: 'updated',
    label: 'updated',
  },
  {
    value: 'upcoming',
    label: 'upcoming',
  },
];

export const ADVANCED_FILTERS = {
  assignees: [],
  assignees_companies: [],
  assignees_externals: [],
  watchers: [],
  watchers_companies: [],
  watchers_externals: [],
  replacements: [],
  categories: [],
  workspaces: [],
  assets: [],
  asset_system_ids: [],
  asset_group_ids: [],
  equipment: [],
};

export const INIT_FILTERS = {
  status: 'all',
  priority_id: '',
  sort_direction: 'desc',
  sort_by: 'updated',
  include_sub_ws: 0,
  ...ADVANCED_FILTERS,
};

export const SUPPORTED_PARAMS = {
  schedule_ids: {
    defaultValue: null,
    allowedValues: null,
  },
};

export const CUSTOMER_SUPPORT_CONFIG = {
  entity: 'schedules',
  videoId: 'DY8SG9Zzuz8',
};
