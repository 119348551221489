import { CALENDAR_VIEW_TYPES } from '../../constants';
import Filters from '../../../IssuesV2/components/Filters/Filters';
import Filter from '../../../scheduling/components/Filter/Filter';

export const getHiddenFilters = (activeView) => {
  return {
    dateRange: true,
    ...(activeView === CALENDAR_VIEW_TYPES.dailyWorkspaces ||
    activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces
      ? { workspaces: true }
      : {}),
    ...(activeView === CALENDAR_VIEW_TYPES.dailyUsers ||
    activeView === CALENDAR_VIEW_TYPES.weeklyUsers
      ? { assignees: true }
      : {}),
  };
};

// filter should be different for schedules

export const getFilterView = (activeView) => {
  switch (activeView) {
    case CALENDAR_VIEW_TYPES.yearly:
      return (props) => <Filter {...props} />;
    default:
      return (props) => <Filters {...props} />;
  }
};
