import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash-es';
import { useIntl } from 'react-intl';
import { getConfigPersistedFilters } from '../../../../../../redux/config/selectors';
import { QUERY_KEYS } from '../constants';
import { updatePersistedFilters } from '../../../../../../redux/config/actions';

const useSelected = ({
  setMultiselectFilter,
  queryParams,
  persistKey,
} = {}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const persistedFilters = useSelector(
    (state) => getConfigPersistedFilters(state, persistKey),
    isEqual
  );

  const getSelected = useCallback(
    (key) => {
      const selected = persistedFilters?.parameters[`${key}_params`] ?? [];
      return selected.map((item) => ({ ...item, type: key }));
    },
    [persistedFilters]
  );

  const concatenateSelected = useCallback(
    (keys) => {
      return keys.reduce((result, key) => {
        return result.concat(getSelected(key));
      }, []);
    },
    [getSelected]
  );

  const notAssignedValue = intl.formatMessage({ id: 'not_assigned' });

  const selectedAssignees = useMemo(() => {
    const shouldAddNotAssigned =
      (Array.isArray(queryParams?.assignees) &&
        queryParams?.assignees?.includes('0')) ||
      queryParams?.assignees === '0';
    return [
      ...(shouldAddNotAssigned
        ? [{ id: 0, value: notAssignedValue, label: notAssignedValue }]
        : []),
      ...concatenateSelected(QUERY_KEYS.assignees),
    ];
  }, [concatenateSelected, notAssignedValue, queryParams?.assignees]);

  const selectedWorkedOnBy = useMemo(
    () => concatenateSelected(QUERY_KEYS.workedOnBy),
    [concatenateSelected]
  );

  const selectedReporters = useMemo(
    () => concatenateSelected(QUERY_KEYS.reporters),
    [concatenateSelected]
  );
  const selectedWatchers = useMemo(
    () => concatenateSelected(QUERY_KEYS.watchers),
    [concatenateSelected]
  );

  const handleMultiselectChange = (item, key) => {
    //NOTE: do not use persist if id === 0 to match BE
    if (item.id === 0) {
      setMultiselectFilter(item, key);
      return;
    }

    const persistedFiltersKey = `${key}_params`;

    const mutatedArray = [
      ...(persistedFilters.parameters?.[persistedFiltersKey] || []),
    ];

    const index = mutatedArray.findIndex((_item) => +_item.id === +item.id);

    if (index !== -1) {
      mutatedArray.splice(index, 1);
    } else {
      mutatedArray.push({ ...item, type: key });
    }
    setMultiselectFilter(item, key);

    dispatch(
      updatePersistedFilters(
        { ...persistedFilters.parameters, [persistedFiltersKey]: mutatedArray },
        persistKey
      )
    );
  };

  return {
    selectedAssignees,
    selectedWorkedOnBy,
    selectedReporters,
    selectedWatchers,
    getSelected,
    handleMultiselectChange,
  };
};

export default useSelected;
